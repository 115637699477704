import React from 'react'
import localization from '../../config/localization';
import {TextValidator} from "react-material-ui-form-validator";
import {
  Card, CardActions, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, Paper, Tab, Tabs, TextField, Tooltip,
} from "@material-ui/core";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import {request} from "../../api-client";
import AsyncSelect from "react-select/lib/Async";
import AutoCompleteSelect from "react-select";
import SocialLinks from "../../components/Letflow/SocialLinks";
import {
  Checkbox,
  FormControlLabel,
  IconButton, Radio, RadioGroup,
  Typography, 
} from "@material-ui/core/index";
import {
  Add,
  Clear,
  Close as Cancel,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore, GetApp,
  Help,
  Menu,
  Reorder,
  Style,
} from "@material-ui/icons";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import RichTextEditor from "../../components/Letflow/RichTextEditor";
import {ImagePicker, VideoPicker} from "../../components/Letflow/Form";
import HomeElementCreateDialog from "../HomeEditor/HomeElementCreateDialog";
import Button from "../../components/CustomButtons/Button";
import Pdf from "../../assets/img/Letflow/FileExtentions/pdf.png";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {addHttpsToUrl, userIsSupervisor, userIsSysAdmin} from "../../api-client/core/authentication/utils";
import ConfigCategoryDialog from "./ConfigCategoryDialog";
import ConfigTagGroupDialog from "./ConfigTagGroupDialog";
import EditorialEditor from "../../components/Letflow/RichTextEditor/EditorialEditor";
import InputAdornment from "@material-ui/core/InputAdornment";
import availableLanguages from "../../config/localization/availableLanguages.json"
import AvailableLanguages from "../../config/localization/availableLanguages.json";
import HomeElementMenuDialog from './ChannelFormAdditions/HomeElementMenuDialog';
import {channelPurposes} from './utils';

const hideDivs = (divId, ArrowId) => {
  let x = document.getElementById(divId);
  let up = document.getElementById(`${ArrowId}-up`);
  let down = document.getElementById(`${ArrowId}-down`);

  if (x.style.display === "none") {
    x.style.display = "block";
    down.style.display = "none";
    up.style.display = "block";
  } else {
    x.style.display = "none";
    down.style.display = "block";
    up.style.display = "none";
  }
}

const FormContent = ({
   id,
   channel,
   name,
   email,
   image,
   onImageChange,
   visibility,
   type,
   client,
   onClientChange,
   googleAnalyticsId,
   googleTagManagerId,
   channelContentCreatorTypes,
   customUrl,
   socialLinks,
   onChange,
   onCatalogsChange,
   catalogs,
   onSocialLinksChange,
   disableForm,
   updateDisableForm,
   onSetPreview,
   onContentChange,
   onChangeVisibility,
   onChangeType,
   clientIsCompleted,
   loginImage,
   onLoginImageChange,
   footerImage,
   onFooterImageChange,
   sidebarImage,
   onSidebarImageChange,
   favicon,
   onFaviconChange,
   showHelper,
   updateHelper,
   showAdvancedSearch,
   showSupport,
   hasFooterLogo,
   showAbout,
   openNavbarDialog,
   redirectButton,
   openCloseNavbarDialog,
   contentCreatorTypes,
   addElement,
   navbarElements,
   removeNavbarElement,
   binary,
   onBinaryChange,
   hasBinary,
   policy,
   onPolicyChange,
   hasPolicy,
   setNavbarElements,
   bloomcloudChannel,
   loginType,
   languages,
   canDownloadTracks,
   openCloseEditDialog,
   openCloseEditDropDownDialog,
   openDropDownDialog,
   dropDownElement,
   supportNegativeSearch,
   availableTagGroups,
   tagGroups,
   openTagGroupDialog,
   openCloseTagGroupDialog,
   setTagGroup,
   addTagGroup,
   removeFromTagGroup,
   downloadLink,
   showLoginButton,
   showDate,
   availableChannelPlaylists,
   openTrackingGroupsDialog,
   openCloseEditTrackingGroupsDialog,
   preRollVideo,
   onPreRollVideoChange,
   hasExtraInformation,
   hasExtraTermsOfUse,
   extraRegistration,
   dashboardLoginType,
   loginClientId,
   loginClientSecret,
   dashboardLoginClientId,
   dashboardLoginClientSecret,
   userCanSeeAuth,
   showMercadoPagoForm,
   mercadoPagoClientId,
   mercadoPagoClientSecret,
   showPaypalForm,
   paypalClientId,
   paypalClientSecret,
   showGoogleAdsenseForm,
   googleAdsensePublisherId,
   showHomeAds,
   showContentAds,
   adUnitHomeDesktop,
   adUnitHomeMobile,
   adUnitContentDesktop,
   adUnitContentMobile,
   sections,
   selectedCCTTG,
   onSelectedCCTTGChange,
   notifyEndpoint,
   notifySecret,
   radioEnabled,
   radioLink,
   onRemovePreRollVideo,
   hasStoredPreRollVideo=false,
   shipmentMethod,
   onShipmentMethodChange,
   addresses,
   channelShipments,
   handleAddressDialog,
   handleShipmentDialog,
   commercialMail,
   hasCommunity,
   communityCatalog,
   onCommunityCatalogChange,
   communityCCTypes, 
   onCommunityCCTypesChange,
   removeCommunityCCType,
   customShare,
   openEditCategoryDialog,
   editingCategory,
   onEditCategory,
   useTools,
   useCustomRedirects,
   channelRedirects,
   onChangeChannelRedirects,
   noIndex,
   translations,
   onChangeTranslation,
   communityTranslations,
   onChangeCommunityTranslations,
   customScripts,
   isDemo,
   skipTimePreRoll,
   addSubNavbar,
   subNavbarElement,
   subNavbarElements,
   parentElement,
   openSubNavbarCreateDialog,
   setSubNavbarElements,
   onAddFooter,
   footerElements,
   openFooterDialog,
   openCloseFooterDialog,
   saveFooterElement,
   footerElementTranslations,
   dropDownList,
   footerType,
   footerLogoSide,
   footerTranslations,
   footerSocialPosition,
   footerSocialWithLogo,
   footerMargin,
   communityPreferences,
   communityRequiresApproval,
   communityCanDeleteMaterials,
   communityValidations,
   communityEmail,
   useLicenses,
   usePlaylists,
   tools,
   hasExtendRegistration,
   extendRegistration,
   useBrandButton,
   editableFor,
   purpose,
   navbarContentCreatorType,
   mobileNavbarType,
   navbarSearchType,
   useSidebarDesktop,
   useFloatingContactButton,
   floatingButtonImage,
   onFloatingButtonImageChange,
  }) => {

   return (
    <div>
       <Dialog open={openFooterDialog} onEscapeKeyDown={openCloseFooterDialog} onBackdropClick={openCloseFooterDialog}>
        <DialogContent>
          <TranslatableInputs
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = footerElementTranslations.find(tr => tr.language === language);
                return {
                  language,
                  content: [
                    <TextValidator
                      id={`footerElementTitle${language}`}
                      style={{width: "100%"}}
                      label={localization.get('title')}
                      name={`footerElementTitle${language}`}
                      value={currentLangTranslation.title}
                      onChange={e => onChangeTranslation(e.target.value, "title", language, "footerElementTranslations")}
                      disableForm={disableForm}
                    />
                  ]
                }
              })}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={openCloseFooterDialog}>{localization.get("cancel")}</Button>
          <Button onClick={saveFooterElement}>{localization.get("send")}</Button>
        </DialogActions>
      </Dialog>
      <ConfigCategoryDialog
        open={openEditCategoryDialog}
        openCloseDialog={() => onEditCategory(null)}
        editingCategory={editingCategory}
        clientId={client && client.id}
        updateCategory={category => {
          channelContentCreatorTypes = channelContentCreatorTypes
            .map(cat => cat.content_creator_type_id === category.content_creator_type_id? category: cat);
          onChange({target: {id: 'channelContentCreatorTypes', value: channelContentCreatorTypes}});
        }}
      />
      <ConfigTagGroupDialog
        open={openTagGroupDialog}
        openCloseDialog={() => openCloseTagGroupDialog(null)}
        tagGroups={availableTagGroups.length ? availableTagGroups.filter(tg => tg.types.some(t => t.contentCreatorType && t.contentCreatorType.id == selectedCCTTG)) : []}
        channelTagGroups={tagGroups.filter(tagGroup => tagGroup.types.some(t => t.contentCreatorType && t.contentCreatorType.id == selectedCCTTG))}
        removeFromTagGroup={removeFromTagGroup}
        addTagGroup={addTagGroup}
        setTagGroups={(tagGroups) => setTagGroup(tagGroups, selectedCCTTG)}
        editingCategory={contentCreatorTypes.find(t => t.id == selectedCCTTG) ? contentCreatorTypes.find(t => t.id == selectedCCTTG).name: ""}
      />
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-general-information', 'arrow-general')}>
        {localization.get('channel.general_config')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-general-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-general-down"}/>
      </h3>
      <br/>

      <Dialog open={showHelper} onBackdropClick={updateHelper} onEscapeKeyDown={updateHelper}>
        <DialogTitle>{localization.get('channel_visibility')}</DialogTitle>
        <DialogContent>
          <p><span style={{fontWeight: "bold"}}>{localization.get('channel.open')}:</span> {localization.get('channel.open.help')}</p>
          <p><span style={{fontWeight: "bold"}}>{localization.get('channel.register')}:</span> {localization.get('channel.register.help')}</p>
          <p><span style={{fontWeight: "bold"}}>{localization.get('channel.private')}:</span> {localization.get('channel.private.help')}</p>
          <p><span style={{fontWeight: "bold"}}>{localization.get('channel.closed')}:</span> {localization.get('channel.closed.help')}</p>
        </DialogContent>
      </Dialog>

      <Dialog open={openNavbarDialog} onBackdropClick={() => openCloseNavbarDialog()} onEscapeKeyDown={() => openCloseNavbarDialog()}>
        <HomeElementCreateDialog
          clientId={client.id}
          permissions={client.permissions}
          contentCreatorTypes={channelContentCreatorTypes.map(ccType => {
            return {
              value: ccType.contentCreatorType.slug,
              label: ccType.contentCreatorType.label,
              isTalent: ccType.contentCreatorType.isTalent,
              slug: ccType.contentCreatorType.slug,
              allowVideo: ccType.contentCreatorType.allowVideo,
              allowAudio: ccType.contentCreatorType.allowAudio,
              type: ccType.contentCreatorType.type,
            }
          })}
          isCreating={true}
          channelId={id || false}
          channel={id ? 
            {...channel, navbar_tabs: mobileNavbarType == "tabs", creatingElements: true, redirectButton: redirectButton } :
            {id: false, navbar_tabs: mobileNavbarType == "tabs", creatingElements: true, redirectButton: redirectButton}
          }
          type={(!parentElement || parentElement.type == "navbar") ? 'navbar': 'footer'}
          addElement={addElement}
          onClose={() => openCloseNavbarDialog()}
          parent={parentElement}
          availableChannelPlaylists={availableChannelPlaylists}
          sections={sections}
        />
      </Dialog>
      <HomeElementMenuDialog
        open={openDropDownDialog}
        onClose={openCloseEditDropDownDialog}
        onEdit={openCloseEditDialog}
        elements={dropDownList}
        setElements={setNavbarElements}
        parentElement={dropDownElement}
        removeElement={removeNavbarElement}
        onAddElement={openCloseNavbarDialog}
      />
      <Dialog
        open={openTrackingGroupsDialog}
        onBackdropClick={openCloseEditTrackingGroupsDialog}
        onEscapeKeyDown={openCloseEditTrackingGroupsDialog}
      >
        <DialogTitle>{localization.get("form.tracking_tag_group")}</DialogTitle>
        <div style={{width: "100%", minWidth: 600}}>
          <table style={{width: "95%", margin: "2.5%"}}>
            {channelContentCreatorTypes && channelContentCreatorTypes.map(channelCCType => (
              <tr>
                <td>
                  <Typography>{channelCCType.name}</Typography>
                </td>
                <td>
                <FormControl fullWidth>
                  <br/>
                  <span>{localization.get('form.tracking_tag_group')}</span>
                  <AsyncSelect
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 10
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0
                    })}
                    placeholder={localization.get('form.tracking_tag_group')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={tagGroup => {
                      channelCCType.tagGroup = tagGroup;
                      channelCCType.tracking_tag_group_id = tagGroup.value;
                      const e = {
                        target: {
                          id: "channelContentCreatorTypes",
                          value: channelContentCreatorTypes,
                        },
                      };
                      onChange(e);
                    }}
                    value={channelCCType.tracking_tag_group_id ? channelCCType.tagGroup : null}
                    loadOptions={(inputValue, callback) => {
                      callback(availableTagGroups
                        .filter(tg => !!tg.types.find(type => type.contentCreatorType.id===channelCCType.content_creator_type_id))
                        .map(tg => ({...tg, value: tg.id, label: tg.name}))
                        .filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                    }}
                    defaultOptions
                  />
                  {!!channelCCType.tracking_tag_group_id &&
                  <IconButton
                    onClick={() => downloadLink(channelCCType.tracking_tag_group_id)}
                    style={{position: "absolute", top: 0, right: -5, color: 'inherit'}}
                  >
                    <Tooltip
                      title={localization.get('download_csv')}
                      placement='top'>
                      <GetApp/>
                    </Tooltip>
                  </IconButton>
                  }
                </FormControl>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </Dialog>

      <div style={{display: !id ? "flex": "none", flexDirection: "column", justifyContent: "center",}} id={'channel-general-information'} >
        {!id ?
          <>
          <FormControl fullWidth>
            <span>{localization.get('form.client')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                }),
                control: (base, state) => ({
                  ...base,
                  // state.isFocused can display different borderColor if you need it
                  borderColor: state.isFocused ?
                    '#ddd' : clientIsCompleted ?
                      '#ddd' : 'red',
                  // overwrittes hover style
                  '&:hover': {
                    borderColor: state.isFocused ?
                      '#ddd' : clientIsCompleted ?
                        '#ddd' : 'red'
                  }
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('form.client')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onClientChange}
              validators={['required']}
              value={client}
              loadOptions={inputValue => {
                let filterBy = [{column: "name", filter: inputValue}];
                return request.client
                  .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                  .then(res => res.map(client => ({...client, value: client.id, label: `${client.name}`})))
              }}
              defaultOptions
            />
          </FormControl>
          <br />
          <br />
          </>
          :
          <>
            <TextValidator
              style={{ width: "100%", marginBottom: "20px" }}
              label={localization.get('form.client')}
              name="clientName"
              value={client.name}
              disabled={true}
            />
          </>
        }
        <TextValidator
          style={{ width: "100%", marginBottom: "20px" }}
          id="name"
          label={localization.get('channel.name')}
          name="name"
          value={name}
          onChange={onChange}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        />
        {userIsSupervisor() &&
          <FormControl style={{width: "100%", marginBottom: 15}}>
            <span>{localization.get('channel.type')}</span>
            <RadioGroup
              name="isDemo"
              value={isDemo}
              id="isDemo"
              onChange={e => onChange({
                target: {
                  id: "isDemo",
                  value: e.target.value
                }
              })}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="0" control={<Radio color="default"/>}
                                label={localization.get('creation.active')}/>
              <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('demo')}/>
            </RadioGroup>
          </FormControl>
        }
        <FormControl fullWidth>
          <span>{localization.get('table.purpose')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('table.purpose')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={purpose => {
              const e = {
                target: {
                  id: "purpose",
                  value: purpose,
                },
              };
              onChange(e);
            }}
            value={purpose}
            loadOptions={(inputValue, callback) => {
              callback(channelPurposes.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
            }}
            defaultOptions
          />
          <br/>
        </FormControl>

        <FormControl style={{ width: "100%", marginBottom: 15 }}>
          <span>{localization.get('form.login_type_channel')}</span>
          <RadioGroup
            name="loginType"
            value={loginType}
            id="loginType"
            onChange={e => onChange({target: {
                id: "loginType",
                value: e.target.value
            }})}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="default" control={<Radio color="default"/>} label="Feater"/>
            <FormControlLabel value="MiBA" control={<Radio color="default"/>} label="MiBA"/>
            <FormControlLabel value="gcba" control={<Radio color="default"/>} label="GCBA"/>
          </RadioGroup>
        </FormControl>
        {userCanSeeAuth && loginType !== "default" &&
          <>
            <TextValidator
              style={{ width: "100%", marginBottom: 15 }}
              id="loginClientId"
              label={"Client ID"}
              name="loginClientId"
              value={loginClientId}
              onChange={onChange}
            />
            <TextValidator
              style={{ width: "100%", marginBottom: "20px" }}
              id="loginClientSecret"
              label={"Client Secret"}
              name="loginClientSecret"
              value={loginClientSecret}
              onChange={onChange}
            />
          </>
        }


        <FormControl style={{ width: "100%" }}>
          <span>{localization.get('form.login_type_dashboard')}</span>
          <RadioGroup
            name="dashboardLoginType"
            value={dashboardLoginType}
            id="dashboardLoginType"
            onChange={e => onChange({target: {
                id: "dashboardLoginType",
                value: e.target.value
              }})}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}

          >
            <FormControlLabel disabled={!userCanSeeAuth} value="default" control={<Radio color="default"/>} label="Feater"/>
            <FormControlLabel disabled={!userCanSeeAuth} value="MiBA" control={<Radio color="default"/>} label="MiBA"/>
            <FormControlLabel disabled={!userCanSeeAuth} value="gcba" control={<Radio color="default"/>} label="GCBA"/>
          </RadioGroup>
            </FormControl>
        {userCanSeeAuth && dashboardLoginType !== "default" &&
          <>
            <TextValidator
              style={{ width: "100%", marginBottom: "20px" }}
              id="dashboardLoginClientId"
              label={"Client ID"}
              name="dashboardLoginClientId"
              value={dashboardLoginClientId}
              onChange={onChange}
            />
            <TextValidator
              style={{ width: "100%", marginBottom: "20px" }}
              id="dashboardLoginClientSecret"
              label={"Client Secret"}
              name="dashboardLoginClientSecret"
              value={dashboardLoginClientSecret}
              onChange={onChange}
            />
          </>
        }

        <br/>
        <br/>
        <FormControl style={{position: "relative", float: "left", width: "100%"}}>
          <span>{localization.get('language.available')}</span>
          <div style={{ position: "relative", float: "left", width: "100%" }}>
            <table style={{width: "100%",position: "relative", float: "left"}}>
              {availableLanguages.map(lang => {
                const language = {
                  active: !!languages.find(l => l.lang === lang),
                  isMain: languages.find(l => l.lang === lang) && !!languages.find(l => l.lang === lang).isMain,
                  lang: lang,
                };

                return (
                  <tr>
                    <td style={{width: 30}}>
                      <Checkbox
                        style={{float: "left"}}
                        disabled={language.isMain}
                        checked={language.active}
                        onClick={() => {
                          language.active ? languages = languages.filter(x => x.lang !== lang) :
                            languages.push({lang: lang, isMain: false})

                          const e = {
                            target: {
                              id: "languages",
                              value: languages,
                            },
                          };
                          onChange(e);
                        }}
                      />
                    </td>
                    <td style={{width: 200, overflow: "hidden"}}>
                      <Typography>{localization.get(`language.${lang}`)}</Typography>
                    </td>
                    <td style={{width: 200, overflow: "hidden"}}>
                      {language.active &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{color: "#4cc2d5"}}
                              disabled={language.isMain}
                              checked={language.isMain}
                              onChange={() => {
                                languages = languages.map(l => ({...l, isMain: false}))
                                languages.find(l => l.lang === lang).isMain = true

                                const e = {
                                  target: {
                                    id: "languages",
                                    value: languages,
                                  },
                                };

                                onChange(e);
                              }}
                            />
                          }
                          label={localization.get("language.isMain")}
                        />
                      }
                    </td>
                  </tr>
                )}
            )}
            </table>
          </div>
        </FormControl>
        <br/>
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <span>{localization.get('form.use_tools_title', name)}</span>
          <RadioGroup
            id="tools"
            name="tools"
            value={tools}
            onChange={e => {
              switch (e.target.value) {
                case "none":
                  onChange({ target: {id: "useTools", value: false}})
                  onChange({ target: {id: "usePlaylists", value: false}})
                  onChange({ target: {id: "useLicenses", value: false}})
                  break;
                case "tools":
                  onChange({ target: {id: "useTools", value: true}})
                  onChange({ target: {id: "usePlaylists", value: false}})
                  onChange({ target: {id: "useLicenses", value: false}})
                  break;
                case "musicTools":
                  onChange({ target: {id: "useTools", value: false}})
                  onChange({ target: {id: "usePlaylists", value: true}})
                  onChange({ target: {id: "useLicenses", value: true}})
                  break;
              }

              onChange({ target: {id: "tools", value: e.target.value}})
            }}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="none" control={<Radio color="default" />}
              label={localization.get('form.use_tools_none')}
            />
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="tools"
              control={<Radio color="default" />}
              label={localization.get('form.use_tools')}
            />
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="musicTools"
              control={<Radio color="default" />}
              label={localization.get('form.use_music_tools')}
            />
          </RadioGroup>
        </FormControl>
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <span>{localization.get('form.editable_for')}</span>
          <RadioGroup
            id="editableFor"
            name="editableFor"
            value={editableFor}
            onChange={e => onChange({ target: {id: "editableFor", value: e.target.value}})}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            disabled={visibility==="public"}
          >
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="none" control={<Radio color="default" />}
              label={localization.get('form.editable_for_no_one')}
            />
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="everyone"
              control={<Radio color="default" />}
              label={localization.get('form.editable_for_everyone')}
            />
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="supervisors"
              control={<Radio color="default" />}
              label={localization.get('form.editable_for_supervisors')}
            />
          </RadioGroup>
        </FormControl>
        
        <br/>
        <p>.</p>
      </div>
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-monetization', 'arrow-monetization')}>
        {localization.get('channel.monetization')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-monetization-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-monetization-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-monetization'} >
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={showGoogleAdsenseForm}
              onChange={() => {
                const e = {
                  target: {
                    id: "showGoogleAdsenseForm",
                    value: !showGoogleAdsenseForm,
                  },
                };
                if(showGoogleAdsenseForm) {
                  onChange({ target: {id: "googleAdsensePublisherId", value: ""}})
                }
                onChange(e);
              }}
            />
          }
          label={localization.get("form.has_google_adsense")}
        />
        {!!showGoogleAdsenseForm &&
          <>
            <Card style={{overflow: 'visible'}}>
              <CardContent>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <h4>Google AdSense</h4>
                  <TextValidator
                    style={{width: "100%"}}
                    id="googleAdsensePublisherId"
                    label={"Google AdSense Publisher ID"}
                    placeholder={"pub-0000000000000000"}
                    name="googleAdsensePublisherId"
                    value={googleAdsensePublisherId}
                    onChange={onChange}
                  />
                  {googleAdsensePublisherId && 
                    <>
                      <br/>
                      <b>{localization.get("form.monetized_sections")}</b>
                      <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{color: "#4cc2d5"}}
                              checked={showHomeAds}
                              onChange={() => {
                                const e = {
                                  target: {
                                    id: "showHomeAds",
                                    value: !showHomeAds,
                                  },
                                };
                                if(showHomeAds) {
                                  onChange({ target: {id: "adUnitHomeDesktop", value: ""}})
                                  onChange({ target: {id: "adUnitHomeMobile", value: ""}})
                                }
                                onChange(e);
                              }}
                            />
                          }
                          label={localization.get("form.show_home_ads")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{color: "#4cc2d5"}}
                              checked={showContentAds}
                              onChange={() => {
                                const e = {
                                  target: {
                                    id: "showContentAds",
                                    value: !showContentAds,
                                  },
                                };
                                if(showHomeAds) {
                                  onChange({ target: {id: "adUnitContentDesktop", value: ""}})
                                  onChange({ target: {id: "adUnitContentMobile", value: ""}})
                                }
                                onChange(e);
                              }}
                            />
                          }
                          label={localization.get("form.show_content_ads")}
                        />
                      </div>
                      {showHomeAds && 
                        <>
                          <br/>
                          <b>{localization.get("form.ad_units.home")}</b>
                          <TextValidator
                            style={{width: "100%"}}
                            id="adUnitHomeDesktop"
                            label={localization.get("form.ad_units.home.desktop")}
                            name="adUnitHomeDesktop"
                            value={adUnitHomeDesktop}
                            onChange={onChange}
                          />
                          <TextValidator
                            style={{width: "100%"}}
                            id="adUnitHomeMobile"
                            label={localization.get("form.ad_units.home.mobile")}
                            name="adUnitHomeMobile"
                            value={adUnitHomeMobile}
                            onChange={onChange}
                          />
                        </>
                      }
                      {showContentAds && 
                        <>
                          <br/>
                          <b>{localization.get("form.ad_units.content")}</b>
                          <TextValidator
                            style={{width: "100%"}}
                            id="adUnitContentDesktop"
                            label={localization.get("form.ad_units.content.desktop")}
                            name="adUnitContentDesktop"
                            value={adUnitContentDesktop}
                            onChange={onChange}
                          />
                          <TextValidator
                            style={{width: "100%"}}
                            id="adUnitContentMobile"
                            label={localization.get("form.ad_units.content.mobile")}
                            name="adUnitContentMobile"
                            value={adUnitContentMobile}
                            onChange={onChange}
                          />
                        </>
                      }
                    </>
                  }
                </div>
              </CardContent>
            </Card>
            <br/>
          </>
        }
        <br/>
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={showMercadoPagoForm}
              onChange={() => {
                const e = {
                  target: {
                    id: "showMercadoPagoForm",
                    value: !showMercadoPagoForm,
                  },
                };
                if(showMercadoPagoForm) {
                  onChange({ target: {id: "mercadoPagoClientId", value: ""}})
                  onChange({ target: {id: "mercadoPagoClientSecret", value: ""}})
                }
                onChange(e);
              }}
            />
          }
          label={localization.get("form.has_mercadopago")}
        />
        <br/>
        {!!showMercadoPagoForm &&
          <>
            <TextValidator
              style={{width: "100%"}}
              id="mercadoPagoClientId"
              label={"Mercadopago Client ID"}
              name="mercadoPagoClientId"
              value={mercadoPagoClientId}
              onChange={onChange}
            />
            <TextValidator
              style={{width: "100%"}}
              id="mercadoPagoClientSecret"
              label={"Mercadopago Access Token"}
              name="mercadoPagoClientSecret"
              value={mercadoPagoClientSecret}
              onChange={onChange}
            />
          </>
        }
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={showPaypalForm}
              onChange={() => {
                const e = {
                  target: {
                    id: "showPaypalForm",
                    value: !showPaypalForm,
                  },
                };
                if(showPaypalForm) {
                  onChange({ target: {id: "paypalClientId", value: ""}})
                  onChange({ target: {id: "paypalClientSecret", value: ""}})
                }
                onChange(e);
              }}
            />
          }
          label={localization.get("form.has_paypal")}
        />
        <br/>
        {!!showPaypalForm &&
          <>
            <TextValidator
              style={{width: "100%"}}
              id="paypalClientId"
              label={"Paypal Client ID"}
              name="paypalClientId"
              value={paypalClientId}
              onChange={onChange}
            />
            <TextValidator
              style={{width: "100%"}}
              id="paypalClientSecret"
              label={"Paypal Client Secret"}
              name="paypalClientSecret"
              value={paypalClientSecret}
              onChange={onChange}
            />
          </>
        }
        {(!!showPaypalForm || !!showMercadoPagoForm) &&
          <>
            <br/>
            <br/>
            <TextValidator
              style={{width: "100%"}}
              id="commercialMail"
              label={localization.get("channel.commercial_mail")}
              name="commercialMail"
              type="email"
              value={commercialMail}
              onChange={onChange}
              validators={['required']}
            />

            <TranslatableInputs
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = translations.find(tr => tr.language === language);

                return {
                  language,
                  content: [
                    <TextValidator
                      id={`boughtCompleteText${language}`}
                      style={{width: "100%"}}
                      label={localization.get('form.bought_complete_text')}
                      name={`boughtCompleteText${language}`}
                      value={currentLangTranslation.boughtCompleteText}
                      onChange={e => onChangeTranslation(e.target.value, "boughtCompleteText", language)}
                      disableForm={disableForm}
                    />
                  ]
                }
              })}
            />
            <br/>
            <br/>
            <span>{localization.get('form.shipment.configuration')}</span>
            <br/>
            <Card style={{overflow: 'visible', marginTop: 10}}>
              <Paper square style={{width: '100%', maxWidth: "100%"}}>
                <Tabs
                  value={shipmentMethod}
                  onChange={onShipmentMethodChange}
                  indicatorColor="secondary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab wrapped value="addresses" label={localization.get("channel.shipment.addresses")} />
                  <Tab wrapped value="shipments" label={localization.get("channel.shipment.location")} />
                </Tabs>
              </Paper>
              {shipmentMethod === "addresses" ?
                <div style={{position: "relative", float: "left", width: "100%", padding: "0 15px", border: "1px solid #ccc"}}>
                  <table style={{width: "100%",position: "relative", float: "left"}}>
                    {addresses.map(address =>
                      <tr>
                        <td style={{width: 200, overflow: "hidden"}}>
                          <Typography>{address.name}</Typography>
                        </td>
                        <td style={{width: 200, overflow: "hidden"}}>
                          <Typography>{address.address}</Typography>
                        </td>
                        <td>
                          <IconButton
                            style={{float: "right"}}
                            onClick={() => handleAddressDialog(address)}
                          >
                            <Edit/>
                          </IconButton>
                          <IconButton
                            style={{float: "right"}}
                            onClick={e => {
                              e.target.id = "addresses";
                              e.target.value = address.filter(x => x.id !== address.id);
                              onChange(e);
                            }}
                          >
                            <Cancel/>
                          </IconButton>
                        </td>
                    </tr>
                    )}
                  </table>
                  <IconButton
                    style={{float: "right"}}
                    onClick={() => handleAddressDialog()}
                  >
                    <Add/>
                  </IconButton>
                </div>
                :
                  <div style={{position: "relative", float: "left", width: "100%", padding: "0 15px", border: "1px solid #ccc"}}>
                    <table style={{width: "100%",position: "relative", float: "left" }}>
                      {channelShipments.map(channelShipment =>
                        <tr>
                          <td style={{width: 200, overflow: "hidden"}}>
                            <Typography>{channelShipment.location}</Typography>
                          </td>
                          {channelShipment.prices.map(price =>
                            <td>
                              <Typography>{price.price} {price.currency}</Typography>
                            </td>
                          )}
                          <td>
                            <IconButton
                              style={{float: "right"}}
                              onClick={() => handleShipmentDialog(channelShipment)}
                            >
                              <Edit/>
                            </IconButton>
                            <IconButton
                              style={{float: "right"}}
                              onClick={e => {
                                e.target.id = "channelShipments";
                                e.target.value = channelShipments.filter(x => x.id !== channelShipment.id);
                                onChange(e);
                              }}
                            >
                              <Cancel/>
                            </IconButton>
                          </td>
                        </tr>
                      )}
                    </table>
                    <IconButton
                      style={{float: "right"}}
                      onClick={() => handleShipmentDialog()}
                    >
                      <Add/>
                    </IconButton>
                  </div>
              }
            </Card>

            <TextValidator
            style={{width: "100%"}}
            id="notifyEndpoint"
            label={localization.get('form.notifyEndpoint')}
            name="notifyEndpoint"
            value={notifyEndpoint}
            onChange={onChange}
            />
            <TextValidator
            style={{width: "100%"}}
            id="notifySecret"
            label={localization.get('form.notifySecret')}
            name="notifySecret"
            value={notifySecret}
            onChange={onChange}
            />
          </>
        }
      </div>
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-access', 'arrow-access')}>
        {localization.get('channel_access')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-access-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-access-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-access'} >
          <FormControl>
            <span>{localization.get('channel_visibility')}</span>
            <br/>
            <div style={{top: 3, left: 140, position: "absolute", cursor: "pointer"}} onClick={updateHelper}>
              <Help style={{fontSize: 15, color: "rgba(4, 175, 196, 1) "}}/>
            </div>
            <RadioGroup
              name="blacklistTypeContentCreatorTypes"
              value={visibility}
              onChange={onChangeVisibility}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel disabled={(id && client.default_channel_id === id) || useTools} value="public" control={<Radio color="default"/>} label={localization.get('channel.open')}/>
              <FormControlLabel value="registered" control={<Radio color="default"/>} label={localization.get('channel.register')}/>
              <FormControlLabel value="private" control={<Radio color="default"/>} label={localization.get('channel.private')}/>
              <FormControlLabel value="closed" control={<Radio color="default"/>} label={localization.get('channel.closed')}/>
            </RadioGroup>
          </FormControl>
          <TextValidator
            style={{ width: "100%", marginBottom: "20px" }}
            id="customUrl"
            label={localization.get('form.custom_link')}
            name="customUrl"
            value={customUrl}
            onChange={onChange}
          />

          
          <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={hasExtendRegistration}
                  onChange={() => {
                    if(hasExtendRegistration) {
                      extendRegistration.birthdate = "none"; 
                      extendRegistration.identityNumber = "none";
                      extendRegistration.address = "none";
                      onChange({target: {id: "extendRegistration", value: extendRegistration}})
                    }

                    const e = {
                      target: {
                        id: "hasExtendRegistration",
                        value: !hasExtendRegistration,
                      },
                    };

                    onChange(e);
                  }}
                />
              }
              label={localization.get("form.has_extend_registration")}
            />
            {hasExtendRegistration && 
            <div style={{width: "100%"}}>
              <FormControl>
                <span>{localization.get('channel.birthdate')}</span>
                <RadioGroup
                    name="extendRegistration"
                    value={extendRegistration.birthdate}
                    onChange={e => {
                      extendRegistration.birthdate = e.target.value;

                      onChange({target: {id: "extendRegistration", value: extendRegistration}})
                    }}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="none" control={<Radio color="default"/>} label={localization.get('channel.none')}/>
                    <FormControlLabel value="show" control={<Radio color="default"/>} label={localization.get('channel.show')}/>
                    <FormControlLabel value="required" control={<Radio color="default"/>} label={localization.get('channel.required')}/>
                </RadioGroup>
                <span>{localization.get('channel.identity_number')}</span>
                <RadioGroup
                    name="extendRegistration"
                    value={extendRegistration.identityNumber}
                    onChange={e => {
                      extendRegistration.identityNumber = e.target.value;

                      onChange({target: {id: "extendRegistration", value: extendRegistration}})
                    }}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="none" control={<Radio color="default"/>} label={localization.get('channel.none')}/>
                    <FormControlLabel value="show" control={<Radio color="default"/>} label={localization.get('channel.show')}/>
                    <FormControlLabel value="required" control={<Radio color="default"/>} label={localization.get('channel.required')}/>
                </RadioGroup>
                <span>{localization.get('channel.address')}</span>
                <RadioGroup
                    name="extendRegistration"
                    value={extendRegistration.address}
                    onChange={e => {
                      extendRegistration.address = e.target.value;

                      onChange({target: {id: "extendRegistration", value: extendRegistration}})
                    }}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="none" control={<Radio color="default"/>} label={localization.get('channel.none')}/>
                    <FormControlLabel value="show" control={<Radio color="default"/>} label={localization.get('channel.show')}/>
                    <FormControlLabel value="required" control={<Radio color="default"/>} label={localization.get('channel.required')}/>
                </RadioGroup>
                </FormControl>
          </div>
        }
        {loginType == "default" &&
        <>
          {<h4>{localization.get('channel.login_background')}</h4>}
          {loginImage && <ImagePicker image={loginImage.src} previewOnly={true} id={loginImage.id} />}
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={loginImage}
            id={"loginImage"}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={onLoginImageChange}
            setPreview={onSetPreview}
            clientId={client && client.id}
          />
          <div style={{
            color: "#757575",
            textAlign: 'center',
            marginTop: 5
          }}>{localization.get('helper_text.images_recommended', "1024 x 768")}</div>
        </>
        }
      </div>
      {!!customUrl.length &&
        <>
          <h3 style={{
            background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)",
            margin: "5px 0",
            position: "relative",
            borderRadius: 5,
            padding: 5,
            cursor: "pointer"
          }} onClick={() => hideDivs('channel-redirects', 'arrow-redirects')}>
            {localization.get('channel_redirects')}
            <ExpandLess style={{display: !id ? "block" : "none", position: "absolute", right: 15, top: 12}}
                        id={"arrow-redirects-up"}/>
            <ExpandMore style={{display: id ? "block" : "none", position: "absolute", right: 15, top: 12}}
                        id={"arrow-redirects-down"}/>
          </h3>
          <br/>
          <div style={{display: !id ? "block": "none"}} id={'channel-redirects'} >
            <h4>{localization.get('channel.custom_redirects')}</h4>
            <table style={{ width: "100%" }}>
              {channelRedirects.map(channelRedirect => (
                <tr>
                  <td>
                  <Card>
                    <CardContent>
                      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <TextField
                        error={channelRedirect.error}
                        fullWidth
                        label={localization.get('channel.redirect_from')}
                        id="standard-start-adornment"
                        value={channelRedirect.targetPath}
                        disabled={channelRedirect.type == "disabled"}
                        onChange={event => {
                          channelRedirect.targetPath = encodeURI(event.target.value);
                          const e = {
                            target: {
                              id: "channelRedirects",
                              value: channelRedirects,
                            },
                          };

                          onChange(e);
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start" color={"rgba(0, 0, 0, 0.87)"}>{addHttpsToUrl(customUrl)}/</InputAdornment>,
                        }}
                      />
                        <br/>
                        {id && !channelRedirect.home_element_id &&
                          <FormControl>
                            <span>{localization.get('channel.redirect_to')}</span>
                            <RadioGroup
                              name="blacklistTypeContentCreatorTypes"
                              value={channelRedirect.type}
                              onChange={event => {
                                channelRedirect.type = event.target.value;
                                const e = {
                                  target: {
                                    id: "channelRedirects",
                                    value: channelRedirects,
                                  },
                                };

                                onChange(e);
                              }}
                              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                            >
                              <FormControlLabel value="link" control={<Radio color="default"/>}
                                                label={localization.get('link')}/>
                              <FormControlLabel value="search" control={<Radio color="default"/>}
                                                label={localization.get('search_content')}/>
                              <FormControlLabel value="permanent" disabled={channelRedirect.targetPath} control={<Radio color="default"/>}
                                                label={localization.get('Permanent')}/>
                            </RadioGroup>
                          </FormControl>
                        }
                        {["permanent", 'link'].includes(channelRedirect.type) ?
                          <TextField
                            fullWidth
                            label={localization.get('channel.redirect_to_url')}
                            id="standard-start-adornment"
                            value={channelRedirect.redirectTo}
                            onChange={event => {
                              channelRedirect.redirectTo = event.target.value;
                              const e = {
                                target: {
                                  id: "channelRedirects",
                                  value: channelRedirects,
                                },
                              };

                              onChange(e);
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start" color={"rgba(0, 0, 0, 0.54)"}>https://</InputAdornment>,
                            }}
                          />
                        :
                          <div style={{ width: "100%",display: "flex"}}>
                            {channelRedirect.home_element_id  ?
                              <div style={{ width: "100%",display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <TextField
                                  fullWidth
                                  label={localization.get('channel.redirect_to_url')}
                                  id="standard-start-adornment"
                                  disabled={!!channelRedirect.home_element_id}
                                  value={channelRedirect.redirectTo}
                                  onChange={event => {
                                    channelRedirect.redirectTo = event.target.value;
                                    const e = {
                                      target: {
                                        id: "channelRedirects",
                                        value: channelRedirects,
                                      },
                                    };
                                    onChange(e);
                                  }}
                                />
                                <IconButton
                                  onClick={() => openCloseEditDialog(navbarElements.find(element => element.id === channelRedirect.home_element_id), channelRedirect.id)}
                                >
                                  <Edit />
                                </IconButton>
                              </div>
                              :
                              <Button style={{padding: 8, textTransform: "capitalize"}} color="dark" onClick={() => openCloseNavbarDialog(channelRedirect.id)}>
                                {localization.get('config')}
                              </Button>
                            }
                          </div>
                        }
                      </div>
                    </CardContent>
                  </Card>
                </td>
                  <td>
                    <IconButton
                      onClick={e => {
                        e.target.id = "channelRedirects";
                        e.target.value = channelRedirects.filter(x => x.id !== channelRedirect.id);
                        if (Array.isArray(e.target.value)) onChange(e);
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </table>
            <Button
              style={{
                padding: 8,
                textTransform: "capitalize"
              }}
              color={"dark"}
              onClick={() => {
                channelRedirects.push({ id: Date.now(), targetPath: "",redirectTo: "", type: "link" })
                return onChangeChannelRedirects(channelRedirects)
              }}
            >
              {localization.get('channel.add_custom_redirect')}
            </Button>
            <br />
          </div>
        </>
      }
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-content', 'arrow-content')}>
        {localization.get('channel_content')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-content-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-content-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-content'} >
        <FormControl>
          <span>{localization.get('channel_content')}</span>
          <RadioGroup
            name="blacklistTypeContentCreatorTypes"
            value={type}
            onChange={onChangeType}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="music" control={<Radio
              color="default"/>} label={localization.get('music')}/>
            <FormControlLabel value="content" control={<Radio
              color="default"/>} label={localization.get('searchbar.content')}/>
            <FormControlLabel value="mixed" control={<Radio
              color="default"/>} label={localization.get('searchbar.mixed')}/>
          </RadioGroup>
        </FormControl>
        {(typeof client.value !== 'undefined' || (client && client.id) ) &&
          <>
            {type !== "" &&
              <FormControl fullWidth>
                <h4>
                  {localization.get('form.select_catalogs')}
                </h4>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('form.catalogs')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  isMulti
                  onChange={onCatalogsChange}
                  value={catalogs}
                  loadOptions={inputValue => {

                    let filterBy = [
                      {column: "name", filter: inputValue},
                      {column: "client_id", filter: client.value || client.id},
                      {column: "confirmed", filter: "true"}
                    ];

                    if (type !== "mixed") {
                      filterBy = filterBy.concat([{column: 'content', filter: type}])
                    }
                    return request.catalog
                      .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                      .then(res => res.map(catalog => ({
                        value: catalog.id,
                        label: `${catalog.name} (${localization.get(catalog.visibility)})`
                      })))
                  }}
                  defaultOptions
                />
              </FormControl>
            }
            <br/>
            <br/>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={canDownloadTracks}
                  onChange={() => {
                    const e = {
                      target: {
                        id: "canDownloadTracks",
                        value: !canDownloadTracks,
                      },
                    };
                    onChange(e);
                  }}
                />
              }
              label={localization.get("form.can_download_tracks")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={customShare}
                  onChange={() => {
                    const e = {
                      target: {
                        id: "customShare",
                        value: !customShare,
                      },
                    };
                    onChange(e);
                  }}
                />
              }
              label={localization.get("form.simple_share")}
            />
            {type !== "music" &&
              <div>
                <h4>
                  {localization.get('channel.content_creator')}
                </h4>
                {(() => {
                  const allowedCCTypes = contentCreatorTypes.filter(type =>
                    channelContentCreatorTypes.every(channelContentCreatorType =>
                      type.id !== channelContentCreatorType.content_creator_type_id))
                  return (
                    <div style={{marginTop: "10px"}}>
                      <AutoCompleteSelect
                        styles={{
                          menu: base => ({
                            ...base,
                            zIndex: 10
                          })
                        }}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0
                        })}
                        placeholder={localization.get('client.content_creator_types_whitelist')}
                        value={''}
                        noOptionsMessage={() => localization.get('no_options')}
                        onChange={contentCreatorTypes => {
                          channelContentCreatorTypes.value = channelContentCreatorTypes.concat([
                            {
                              content_creator_type_id: contentCreatorTypes.value,
                              name: contentCreatorTypes.label,
                              show_on_global_search: true,
                              show_on_smart_search: true,
                              contentCreatorType: contentCreatorTypes,
                              viewType: "grid6",
                              view_type: "grid6",
                              creation_view_type: "grid4",
                              contact_email: "",
                              show_tag_selection: true,
                              show_filter_on_smart_search: true,
                              showFilterOnSmartSearch: "1",
                              showTagSelection: "1",
                              support_negative_search: false,
                              support_publish_search: false,
                              has_multiple_tags_allowed: false,
                              allow_content_search: true,
                              allow_creation_search: false,
                              allow_event_search: false,
                              searchType: "single",
                              creator_view_type: 'carousel',
                              creator_view_type_mobile: 'carousel',
                              restricted_content_search: "0",
                              restricted_material_search: "1",
                              translations: []
                            },
                          ]);
                          onChange({target: {id: 'channelContentCreatorTypes', value: channelContentCreatorTypes.value}});
                        }}
                        options={allowedCCTypes.map(ccType => ({
                          value: ccType.id,
                          label: ccType.name,
                          slug: ccType.slug,
                          type: ccType.type,
                          isTalent: ccType.is_talent,
                          allowVideo: ccType.allow_video,
                          allowAudio: ccType.allow_audio})
                        )}
                      />
                    </div>
                  );
                })()}
                <table style={{width: "100%"}}>
                  {channelContentCreatorTypes && channelContentCreatorTypes.map(channelCCType => (
                    <tr>
                      <td>
                        <Typography>{channelCCType.name}</Typography>
                      </td>
                      <td style={{ width: 150 }}>
                        <Tooltip title={localization.get('delete')} placement='top'>
                          {channelCCType.content_creator_type_id ?
                            <IconButton
                              style={{float: "right"}}
                              onClick={e => {
                                e.target.id = "channelContentCreatorTypes";
                                e.target.value = channelContentCreatorTypes.filter(x => x.content_creator_type_id !== channelCCType.content_creator_type_id);
                                onChange(e);
                                communityCCTypes.value = communityCCTypes.filter(x => x.id !== channelCCType.content_creator_type_id)
                                onChange({target: {id: 'communityCCTypes', value: communityCCTypes.value}});
                              }}
                            >
                              <Cancel/>
                            </IconButton>
                            :
                            <IconButton
                              style={{float: "right"}}
                              onClick={e => {
                                e.target.id = "channelContentCreatorTypes";
                                e.target.value = channelContentCreatorTypes.filter(x => x.id !== channelCCType.id);
                                onChange(e);
                                communityCCTypes.value = communityCCTypes.filter(x => x.id !== channelCCType.content_creator_type_id);
                                onChange({target: {id: 'communityCCTypes', value: communityCCTypes.value}});
                              }}
                            >
                              <Cancel/>
                            </IconButton>
                          }
                        </Tooltip>
                          <Tooltip title={localization.get('edit')} placement='top'>
                            <IconButton
                              style={{float: "right"}}
                              onClick={() => onEditCategory(channelCCType)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={localization.get('edit.tag_groups')} placement='top'>
                            <IconButton
                              style={{float: "right"}}
                              onClick={(e) => {
                                openCloseTagGroupDialog();
                                onSelectedCCTTGChange(e, channelCCType.content_creator_type_id)
                              }}
                            >
                              <Style />
                            </IconButton>
                          </Tooltip>
                      </td>
                    </tr>
                  ))}
                </table>
                <br />
              </div>
            }
          </>
        }

        <h4>{localization.get('video.pre-roll')}</h4>
        {preRollVideo && preRollVideo.src &&
          <div style={{width: "100%", position: 'relative'}}>
            <VideoPicker video={preRollVideo.src} previewOnly={true}/>
            {hasStoredPreRollVideo && <Tooltip title={localization.get("video.pre-roll.remove")} placement="top">
              <IconButton
                style={{position: "absolute", top: 0, right: 0}}
                onClick={e => {
                  onRemovePreRollVideo && onRemovePreRollVideo();
                }}
              >
                <Cancel/>
              </IconButton>
            </Tooltip>}
            <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get(preRollVideo.status === "complete" ? 'helper_text.pre_roll_loaded' : preRollVideo.status === "submitted" ? 'helper_text.pre_roll_submitted' :'helper_text.pre_roll_not_requested')}</div>
          </div>
        }
        <FilePondUploader
          disableForm={disableForm}
          updateDisableForm={updateDisableForm}
          file={preRollVideo}
          id={'preRollVideo'}
          type={"video"}
          allowedTypes={["video/mp4", "video/x-m4v", "video/*", ".mov"]}
          onIdChange={onPreRollVideoChange}
          setPreview={onSetPreview}
          clientId={client && client.id}
        />
        <TextValidator
          style={{width: "100%"}}
          id="skipTimePreRoll"
          label={localization.get('form.skip_time_pre_roll')}
          name="skipTimePreRoll"
          type={"number"}
          value={skipTimePreRoll}
          onChange={onChange}
          disabled={!preRollVideo || !preRollVideo.src}
          helperText={localization.get('form.skip_time_pre_roll_helper')}
        />
        <br/>
      </div>
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-community', 'arrow-header')}>
        {localization.get('channel.community')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-community-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-community-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-community'} >
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={hasCommunity}
                onChange={() => {
                  const e = {
                    target: {
                      id: "hasCommunity",
                      value: !hasCommunity,
                    },
                  };
                  onChange(e);
                }}
              />
            }
            label={localization.get("channel.has_community")}
          />
          <br/>
          
          {hasCommunity && catalogs &&
            <>
              <span>{localization.get("channel.community.requires_approval")}</span>
              <br/>
              <RadioGroup
                id="communityRequiresApproval"
                name="communityRequiresApproval"
                value={communityRequiresApproval}
                onChange={e => onChange({target: {id: "communityRequiresApproval", value:  e.target.value}})}

                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="not_required" control={<Radio color="default"/>} label={localization.get('channel.community.not_required')}/>
                <FormControlLabel value="on_create" control={<Radio color="default"/>} label={localization.get('channel.community.on_create')}/>
                <FormControlLabel value="on_update" control={<Radio color="default"/>} label={localization.get('channel.community.on_update')}/>
              </RadioGroup>
              <br/>
              <span>{localization.get("channel.community.requires_approval_helper")}</span>
              
              {["on_create", "on_update"].includes(communityRequiresApproval) &&
                <>
                  <br/>
                  <br/>
                  <TextField
                    fullWidth
                    id={"communityEmail"}
                    name={"communityEmail"}
                    value={communityEmail}
                    onChange={onChange}
                    label={localization.get("channel.community.email")}
                  />
                </>
              }

              <br/>
              <br/>
              <span>{localization.get("channel.community.can_delete_materials")}</span>
              <br/>
              <RadioGroup
                id="communityCanDeleteMaterials"
                name="communityCanDeleteMaterials"
                value={communityCanDeleteMaterials}
                onChange={e => onChange({target: {id: "communityCanDeleteMaterials", value:  e.target.value}})}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('channel.community.can_delete')}/>
                <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('channel.community.cant_delete')}/>
              </RadioGroup>
              <br/>
              <br/>
              <FormControl fullWidth>
                <span>
                  {localization.get('form.select_catalog')}
                </span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('form.catalogs')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  isMulti={false}
                  onChange={onCommunityCatalogChange}
                  value={communityCatalog}
                  loadOptions={inputValue => {

                    let filterBy = [
                      {column: "name", filter: inputValue},
                      {column: "client_id", filter: client.value || client.id},
                      {column: "confirmed", filter: "true"}];

                    if (type !== "mixed") {
                      filterBy = filterBy.concat([{column: 'content', filter: type}])
                    }
                    return request.catalog
                      .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                      .then(res => res.filter(c => catalogs.find(cat => cat.value == c.id)).map(catalog => ({
                        value: catalog.id,
                        label: `${catalog.name} (${localization.get(catalog.visibility)})`
                      })))
                  }}
                  defaultOptions
                />
              </FormControl>
            </>
          }
          {hasCommunity && communityCatalog && 
            <>
              {(() => {
                const allowedCCTypes = channelContentCreatorTypes.filter(type =>
                  communityCCTypes.every(ccType => type.content_creator_type_id !== ccType.value))
                return (
                  <div style={{marginTop: "10px"}}>
                    <AutoCompleteSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0
                      })}
                      placeholder={localization.get('channel.community.categories')}
                      value={''}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={contentCreatorTypes => {
                        communityCCTypes.value = communityCCTypes.concat([
                          {
                            id: contentCreatorTypes.id,
                            value: contentCreatorTypes.id,
                            label: contentCreatorTypes.name,
                            content_creator_type_id: contentCreatorTypes.value,
                            name: contentCreatorTypes.label,
                            show_on_global_search: true,
                          },
                        ]);
                        onChange({target: {id: 'communityCCTypes', value: communityCCTypes.value}});
                      }}
                      options={allowedCCTypes.map(ccType => ({
                        id: ccType.content_creator_type_id,
                        name: ccType.name,
                        value: ccType.content_creator_type_id,
                        label: ccType.name,
                        slug: ccType.slug,
                        type: ccType.type,
                        isTalent: ccType.is_talent,
                        allowVideo: ccType.allow_video,
                        allowAudio: ccType.allow_audio})
                      )}
                    />
                  </div>
                );
              })()}
              {(!communityCCTypes || communityCCTypes.length == 0) && 
                <div style={{width: "100%", padding: 10}}>
                  {localization.get("channel.community.empty_categories")}
                </div>
              }
              {communityCCTypes && communityCCTypes.length > 0 && 
                <DragDropContext
                  onDragEnd={result => {
                    if (result.destination) {
                      const destination = result.destination.index;
                      const source = result.source.index;

                      if (destination !== source) {
                        const direction = destination < source ? -1 : 1;
                        const involved = direction === -1 ? communityCCTypes.slice(destination, source) : communityCCTypes.slice(source + 1, destination + 1);

                        communityCCTypes[source].order = communityCCTypes[destination].order;
                        involved.forEach(x => (x.order -= direction));
                        onCommunityCCTypesChange(communityCCTypes.sort((a, b) => a.order - b.order));
                      }
                    }
                  }}
                >
                  <Droppable
                    droppableId="navbar-elements"
                    direction="vertical"
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 10,
                          overflow: "auto",
                        }}
                        {...provided.droppableProps}
                      >
                        {communityCCTypes.sort((a,b) => a.order - b.order).map((ccType, index) =>
                          <Draggable key={ccType.value.toString()} draggableId={ccType.value.toString()} index={index}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} width={"100%"}>
                                <div style={{height: "45px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                  <Reorder/>
                                  <div style={{
                                    fontSize: 13,
                                    width: "90%",
                                    textAlign: "left",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}>{ccType.label}</div>
                                  <IconButton style={{float: "right"}} onClick={() => removeCommunityCCType(ccType)} >
                                    <Clear />
                                  </IconButton>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              }
              <h5>{localization.get('channel.community.validations')}</h5>
              <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={true}
                    disabled={true}
                  />
                }
                style={{width: "45%"}}
                label={localization.get("channel.community.validations.name")}
              />
              {communityValidations.map(value =>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={communityPreferences.validations[value] == "true"}
                      onChange={() => {
                        communityPreferences.validations[value] = communityPreferences.validations[value] == "true" ? "false" : "true";
                        const e = {
                          target: {
                            id: "communityPreferences",
                            value: communityPreferences,
                          },
                        };
                        onChange(e);
                      }}
                    />
                  }
                  style={{width: "45%"}}
                  label={localization.get(`channel.community.validations.${value}`)}
                />
              )}
              </div>
              <span>{localization.get("channel.community.redirect_to")}</span>
              <br/>
              <RadioGroup
                id="communityRedirectTo"
                name="communityRedirectTo"
                value={communityPreferences.redirectTo}
                onChange={(e) => {
                  communityPreferences.redirectTo = e.target.value;

                  const event = {
                    target: {
                      id: "communityPreferences",
                      value: communityPreferences,
                    },
                  };
                  onChange(event);
                }}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="home" control={<Radio color="default"/>} label={localization.get('channel.community.redirect_to_home')}/>
                <FormControlLabel value="profile" control={<Radio color="default"/>} label={localization.get('channel.community.redirect_to_profile')}/>
              </RadioGroup>

              <br />
              <span>{localization.get('form.extra_register_information')}</span>
              <br/>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={hasExtraTermsOfUse}
                    onChange={() => {
                      const e = {
                        target: {
                          id: "hasExtraTermsOfUse",
                          value: !hasExtraTermsOfUse,
                        },
                      };
                      if(hasExtraInformation) {
                        onChange({ target: {id: "hasExtraTermsOfUse", value: ""}})
                      }
                      onChange(e);
                    }}
                  />
                }
                label={localization.get("form.use_custom_terms_of_use")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={hasExtraInformation}
                    onChange={() => {
                      const e = {
                        target: {
                          id: "hasExtraInformation",
                          value: !hasExtraInformation,
                        },
                      };
                      if(hasExtraInformation) {
                        onChange({ target: {id: "extraRegistration", value: ""}})
                      }
                      onChange(e);
                    }}
                  />
                }
                label={localization.get("form.has_extra_register_information")}
              />
              {!!hasExtraInformation &&
                <TextValidator
                  style={{width: "100%"}}
                  id="extraRegistration"
                  label={localization.get('form.form_embedded_code')}
                  name="extraRegistration"
                  value={extraRegistration}
                  onChange={onChange}
                />
              }

              <h5>{localization.get('channel.community.translations')}</h5>
              <TranslatableInputs
                children={AvailableLanguages.map(language => {
                  const currentLangTranslation = communityTranslations.find(tr => tr.language === language);

                  return {
                    language: language,
                    content: [
                      <TextValidator
                        style={{width: "100%", marginBottom: 20}}
                        id={`communityTitle${language}`}
                        label={localization.get('form.community_title')}
                        name={`communityTitle${language}`}
                        value={currentLangTranslation.title}
                        onChange={e => onChangeCommunityTranslations(e.target.value, "title", language)}
                        disableForm={disableForm}
                        placeholder={`Creá tu perfil profesional y conectate con la comunidad de ${name}`}
                      />,
                      <TextValidator
                        style={{width: "100%", marginBottom: 20}}
                        id={`communityDescription${language}`}
                        label={localization.get('form.community_description')}
                        name={`communityDescription${language}`}
                        value={currentLangTranslation.description}
                        onChange={e => onChangeCommunityTranslations(e.target.value, "description", language)}
                        disableForm={disableForm}
                        placeholder={"¿Cómo se hace?"}
                      />,
                      <TextValidator
                        style={{width: "100%", marginBottom: 20}}
                        id={`communitySlogan${language}`}
                        label={localization.get('form.community_slogan')}
                        name={`communitySlogan${language}`}
                        value={currentLangTranslation.slogan}
                        onChange={e => onChangeCommunityTranslations(e.target.value, "slogan", language)}
                        disableForm={disableForm}
                        placeholder={name}
                      />,
                      <TextValidator
                        style={{width: "100%", marginBottom: 20}}
                        id={`communitySuccess${language}`}
                        label={localization.get('form.community_success_message')}
                        name={`communitySuccess${language}`}
                        value={currentLangTranslation.successMessage}
                        onChange={e => onChangeCommunityTranslations(e.target.value, "successMessage", language)}
                        disableForm={disableForm}
                        placeholder={"¡Listo! Ya formas parte de la comunidad."}
                      />,
                      <>
                        {communityRequiresApproval != "not_required" && 
                          <>
                            <TextValidator
                              style={{width: "100%", marginBottom: 20}}
                              id={`communityPending${language}`}
                              label={localization.get('form.community_pending_message')}
                              name={`communityPending${language}`}
                              value={currentLangTranslation.pendingMessage}
                              onChange={e => onChangeCommunityTranslations(e.target.value, "pendingMessage", language)}
                              disableForm={disableForm}
                              placeholder={"Tu perfil esta siendo revisado por adminisradores, te avisaremos cuando sea actualizado!"}
                            />
                            <TextValidator
                              style={{width: "100%", marginBottom: 20}}
                              id={`communityApproved${language}`}
                              label={localization.get('form.community_approved_message')}
                              name={`communityApproved${language}`}
                              value={currentLangTranslation.approvedMessage}
                              onChange={e => onChangeCommunityTranslations(e.target.value, "approvedMessage", language)}
                              disableForm={disableForm}
                              placeholder={"¡Listo! Ya formas parte de la comunidad y tu perfil fue aprobado."}
                            />
                            <TextValidator
                              style={{width: "100%"}}
                              id={`communityDeclined${language}`}
                              label={localization.get('form.community_declined_message')}
                              name={`communityDeclined${language}`}
                              value={currentLangTranslation.declinedMessage}
                              onChange={e => onChangeCommunityTranslations(e.target.value, "declinedMessage", language)}
                              disableForm={disableForm}
                              placeholder={"Tu perfil fue rechazado por qué no cumple requisitos de la plataforma"}
                            />
                        </>
                        }
                        <>
                        {hasExtraTermsOfUse && 
                          <>
                            <TextValidator
                              style={{display: "none"}}
                              id={`communityTerms${language}`}
                              label={localization.get('form.note')}
                              name={`communityTerms${language}`}
                              value={currentLangTranslation.termsOfUse}
                              onChange={e => onChangeCommunityTranslations(e.target.value, "termsOfUse", language)}
                            />
                            <div id="ignore" style={{margin: "15px 0px 25px"}}>
                              <div style={{marginBottom: -5}}>{localization.get('form.terms_of_use_text')}</div>
                              <br/>
                              <div style={{fontSize: 10, marginBottom: -20}}>{localization.get('form.terms_of_use_helper')}</div>
                              <EditorialEditor
                                editorState={currentLangTranslation.termsOfUse}
                                onChange={e => onChangeCommunityTranslations(e, "termsOfUse", language)}
                                toolbarOptions={['fontSize', 'link', 'emoji']}
                              />
                            </div>
                          </>
                        }
                        </>
                      </>
                    ]
                  }
                })}
              />
            </>
          }
      </div>
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-header', 'arrow-header')}>
        {localization.get('channel_header')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-header-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-header-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-header'} >
        <>
          {<h4 >{localization.get('logo')}</h4>}
          {image && <ImagePicker image={image.src} previewOnly={true} id={image.id} />}
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={image}
            id={"image"}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={onImageChange}
            setPreview={onSetPreview}
            clientId={client && client.id}
          />
          <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.logo_recommended', "300x50")}</div>
        </>
        <>
          <h4 >{localization.get('favicon')}</h4>
          {favicon && <ImagePicker image={favicon.src} previewOnly={true}/>}
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={favicon}
            id={"favicon"}
            type={"image"}
            allowedTypes={["image/png"]}
            onIdChange={onFaviconChange}
            setPreview={onSetPreview}
            clientId={client && client.id}
          />
          <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_recommended', "16x16 pixels")}</div>
        </>

        <h4>{localization.get('navbar_buttons')}</h4>

        {client && navbarElements.filter(element => !element.parent && element.visible).length < (useBrandButton ? 5 :6) &&
          <div>
            <Button
              style={{
                padding: 8,
                textTransform: "capitalize"
              }}
              color="dark"
              onClick={() => openCloseNavbarDialog()}
            >
              {localization.get('form.add_navbar_button')}
            </Button>
          </div>
        }
          <>
            {navbarElements.filter(element => !element.parent).length > 0 &&
              <DragDropContext
                onDragEnd={result => {
                  if (result.destination) {
                    const destination = result.destination.index;
                    const source = result.source.index;

                    if (destination !== source) {
                      const hiddenElements = navbarElements.filter(element => element.parent);
                      const currentNavbarElements = navbarElements.filter(element => !element.parent).map((element, index) => ({...element, order: index}));
                      const direction = destination < source ? -1 : 1;
                      const involved = direction === -1 ? currentNavbarElements.slice(destination, source) : currentNavbarElements.slice(source + 1, destination + 1);

                      setNavbarElements(currentNavbarElements.sort((a, b) => a.order - b.order).concat(hiddenElements))
                      currentNavbarElements[source].order = currentNavbarElements[destination].order;
                      involved.forEach(x => (x.order -= direction));
                    }
                  }
                }}
              >
                <Droppable
                  droppableId="navbar-elements"
                  direction="horizontal"
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10,
                        overflow: "auto",
                      }}
                      {...provided.droppableProps}
                    >
                      {navbarElements.filter(element => !element.parent && element.visible).sort((a,b) => a.order - b.order).map((element, index) =>
                        <Draggable key={element.id.toString()} draggableId={element.id.toString()} index={index}>
                          {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} width={"16.66%"}>
                            <Card style={{height: "170px", minWidth: 100, maxWidth: 130}}>
                              <h3 style={{
                                fontSize: 13,
                                textAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                height: 36
                              }}>{localization.get(element.data_source)}</h3>
                              <h3 style={{
                                fontSize: 13,
                                textAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis" ,
                                lineHeight: "1.5em",
                                height: "3em",
                              }}>{element.title}</h3>
                              <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                                <IconButton style={{margin: "-4px 0px"}} onClick={() => openCloseEditDialog(element)}><Edit/></IconButton>
                                {element.data_source === "drop_down" && <IconButton style={{margin: "-4px 0px"}} onClick={() => openCloseEditDropDownDialog(element, navbarElements)}><Menu/></IconButton>}
                                {!!id && <IconButton style={{margin: "-4px 0px"}} onClick={() => removeNavbarElement(element.id)}><Delete/></IconButton>}
                              </CardActions>
                            </Card>
                          </div>
                          )}
                        </Draggable>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            }
          </>

        {!!id &&
          <div>
            <h4>{localization.get("sub_navbar")}</h4>
            {!subNavbarElement &&
              <Button
                style={{
                  padding: 8,
                  textTransform: "capitalize"
                }}
                color="dark"
                onClick={() => addSubNavbar()}
              >
                {localization.get('form.use_sub_navbar')}
              </Button>
            }
          </div>
        }
        {subNavbarElement &&
          <DragDropContext
            onDragEnd={result => {
              if (result.destination) {
                const destination = result.destination.index;
                const source = result.source.index;

                if (destination !== source) {
                  const currentNavbarElements = subNavbarElements.map((element, index) => ({...element, order: index}));
                  const direction = destination < source ? -1 : 1;
                  const involved = direction === -1 ? currentNavbarElements.slice(destination, source) : currentNavbarElements.slice(source + 1, destination + 1);

                  setSubNavbarElements(currentNavbarElements.sort((a, b) => a.order - b.order))
                  currentNavbarElements[source].order = currentNavbarElements[destination].order;
                  involved.forEach(x => (x.order -= direction));
                }
              }
            }}
          >
            <Droppable
              droppableId="navbar-elements-menu"
              direction="horizontal"
            >
              {provided => (
                <div
                  ref={provided.innerRef}
                  style={{
                    display: "flex",
                    overflow: "auto",
                    padding: 1
                  }}
                  {...provided.droppableProps}
                >
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} width={"16.66%"}>
                    <Card style={{height: "170px", minWidth: 100, maxWidth: 130, display: "flex", alignItems: "center", width: "100%", flexDirection: "column", justifyContent: "space-around"}}>
                      <h3 style={{
                        fontSize: 13,
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                      >{localization.get('form.add_navbar_button')}</h3>
                      <IconButton onClick={openSubNavbarCreateDialog}>
                        <Add />
                      </IconButton>
                    </Card>
                  </div>
                  {subNavbarElements.sort((a, b) => a.order - b.order)
                    .map((element, index) =>
                      <Draggable key={element.id.toString()} draggableId={element.id.toString()} index={index}>
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} width={"16.66%"}>
                            <Card style={{height: "170px", minWidth: 100, maxWidth: 130}}>
                              <h3 style={{
                                fontSize: 13,
                                textAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                height: 36
                              }}>{localization.get(element.data_source)}</h3>
                              <h3 style={{
                                fontSize: 13,
                                textAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis" ,
                                lineHeight: "1.5em",
                                height: "3em",
                              }}>{element.title}</h3>
                              <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                                <IconButton style={{margin: "-4px 0px"}} onClick={() => openCloseEditDialog(element)}><Edit/></IconButton>
                                {!!id && <IconButton style={{margin: "-4px 0px"}} onClick={() => removeNavbarElement(element.id)}><Delete/></IconButton>}
                              </CardActions>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }
        
        <FormControl fullWidth>
          <h4>{localization.get('form.navbar_style_mobile')}</h4>
          <RadioGroup
            id="navbarMobileStyle"
            name="navbarMobileStyle"
            value={mobileNavbarType}
            onChange={e => {
              onChange({target: {id: "mobileNavbarType", value:  e.target.value}})
              e.target.value == "default" && useSidebarDesktop == "sidebar" && onChange({target: {id: "navbarContentCreatorType", value: null}})
            }}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="default" control={<Radio color="default"/>}
                              label={localization.get('form.navbar_classic')}/>
            <FormControlLabel value="tabs" control={<Radio color="default"/>}
                              label={localization.get('form.navbar_tabs')}/>
          </RadioGroup>
        </FormControl>

        <h4>{localization.get("button.additional")}</h4>
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={useBrandButton}
              onChange={() => {
                const e = {
                  target: {
                    id: "useBrandButton",
                    value: !useBrandButton,
                  },
                };
                
                onChange(e);
              }}
            />
          }
          label={localization.get("form.use_brand_button")}
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={radioEnabled}
              onChange={() => {
                const e = {
                  target: {
                    id: "radioEnabled",
                    value: !radioEnabled,
                  },
                };
                onChange(e);
              }}
            />
          }
          label={localization.get("radio.is_live_radio_active")}
        />
        {radioEnabled &&
          <TextValidator
            style={{ width: "100%", marginBottom: "20px" }}
            id="radioLink"
            label={localization.get("radio.stream_link")}
            name="radioLink"
            value={radioLink}
            onChange={onChange}
          />
        }
        <TranslatableInputs
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language,
              content: [
                <>
                {radioEnabled &&
                  <TextValidator
                  id={`radioText${language}`}
                  style={{width: "100%"}}
                  label={localization.get('form.radio_text')}
                  name={`radioText${language}`}
                  value={currentLangTranslation.radioText}
                  onChange={e => onChangeTranslation(e.target.value, "radioText", language)}
                  disableForm={disableForm}
                />}
                <TextValidator
                  id={`liveText${language}`}
                  style={{width: "100%"}}
                  label={localization.get('form.live_text')}
                  name={`liveText${language}`}
                  value={currentLangTranslation.liveText}
                  onChange={e => onChangeTranslation(e.target.value, "liveText", language)}
                  disableForm={disableForm}
                />
                </>
              ]
            }
          })}
        />
        {visibility === "public" &&
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={showLoginButton}
                onChange={() => {
                  const e = {
                    target: {
                      id: "showLoginButton",
                      value: !showLoginButton,
                    },
                  };
                  onChange(e);
                }}
              />
            }
            label={localization.get("form.show_login_button")}
          />

        }
        <br/>
        <br/>
        <h4>{localization.get("form.show_date_title")}</h4>
        <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={showDate}
                onChange={() => {
                  const e = {
                    target: {
                      id: "showDate",
                      value: !showDate,
                    },
                  };
                  onChange(e);
                }}
              />
            }
            label={localization.get("form.show_date")}
          />
        <br/>
        <br/>
      
        <FormControl>
          <span>{localization.get('form.show_advanced_search')}</span>
          <RadioGroup
            id="showAdvancedSearch"
            name="showAdvancedSearch"
            value={showAdvancedSearch}
            onChange={e => onChange({target: {id: "showAdvancedSearch", value:  e.target.value}})}

            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="none" control={<Radio color="default"/>}
                              label={localization.get('form.not_show')}/>
            <FormControlLabel value="search" control={<Radio color="default"/>}
                              label={localization.get('form.show_on_search')}/>
          </RadioGroup>
        </FormControl>
      
        <TranslatableInputs
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);
            return {
              language: language,
              content: [
                !!showLoginButton && <TextValidator
                  id={`loginText${language}`}
                  style={{width: "100%"}}
                  label={localization.get('form.login_text')}
                  name={`loginText${language}`}
                  value={currentLangTranslation.loginText}
                  onChange={e => onChangeTranslation(e.target.value, "loginText", language)}
                  disableForm={disableForm}
                />,
                <TextValidator
                  id={`globalSearchText${language}`}
                  style={{width: "100%"}}
                  label={localization.get('form.global_search_text')}
                  name={`globalSearchText${language}`}
                  value={currentLangTranslation.globalSearchText}
                  onChange={e => onChangeTranslation(e.target.value, "globalSearchText", language)}
                  disableForm={disableForm}
                />
              ]
            }
          })}
        />  
      </div>

      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-sidebar', 'arrow-sidebar')}>
        {localization.get('channel.sidebar')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-sidebar-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-sidebar-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-sidebar'} >
        {tools == "none" && 
          <FormControl fullWidth>
            <h4>{localization.get('channel.sidebar_desktop')}</h4>
            <RadioGroup
              id="useSidebarDesktop"
              name="useSidebarDesktop"
              value={tools != "none" ? "sidebar" : useSidebarDesktop}
              onChange={e => {
                onChange({target: {id: "useSidebarDesktop", value:  e.target.value}})
                e.target.value == "none" && mobileNavbarType != "tabs" && onChange({target: {id: "navbarContentCreatorType", value:  null}})
              }}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="none" disabled={tools != "none"} control={<Radio color="default"/>}
                                label={localization.get('channel.sidebar_classic')}/>
              <FormControlLabel value="sidebar" disabled={tools != "none"} control={<Radio color="default"/>}
                                label={localization.get('channel.sidebar_show')}/>
            </RadioGroup>
          </FormControl>
        }

        {((mobileNavbarType == "tabs" || useSidebarDesktop == "sidebar") && tools == "none" && !hasCommunity)  &&
          <>
            <FormControl fullWidth>
              <span>{localization.get('form.navbar_content_creator_type')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('audit_log.contentcreatortype')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={navbarContentCreatorType => {
                  const e = {
                    target: {
                      id: "navbarContentCreatorType",
                      value: navbarContentCreatorType,
                    },
                  };

                  onChange(e);
                }}
                value={navbarContentCreatorType}
                loadOptions={(inputValue, callback) => {
                  callback(channelContentCreatorTypes
                    .map(tg => ({...tg, value: tg.content_creator_type_id, label: tg.name}))
                    .filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                }}
                defaultOptions
              />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <span>{localization.get('form.navbar_content_search')}</span>
              <RadioGroup
                id="navbarSearchType"
                name="navbarSearchType"
                value={navbarSearchType}
                onChange={e => onChange({target: {id: "navbarSearchType", value:  e.target.value}})}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="content_creators" control={<Radio color="default"/>}
                                  label={localization.get('form.content')}/>
                <FormControlLabel value="material" control={<Radio color="default"/>}
                                  label={localization.get('content_creations.materials')}/>
                <FormControlLabel value="events" control={<Radio color="default"/>}
                                  label={localization.get('events')}/>
              </RadioGroup>
            </FormControl>
            {<h4>{localization.get('channel.sidebar_image')}</h4>}
            {sidebarImage && <ImagePicker image={sidebarImage.src} previewOnly={true} id={sidebarImage.id} />}
            <FilePondUploader
              disableForm={disableForm}
              updateDisableForm={updateDisableForm}
              file={sidebarImage}
              id={"sidebarImage"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={onSidebarImageChange}
              setPreview={onSetPreview}
              clientId={client && client.id}
            />
          </>
        }
      </div>

      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-footer', 'arrow-footer')}>
        {localization.get('footer')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-footer-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-footer-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-footer'} >
        <SocialLinks socialLinks={socialLinks} onSocialLinksChange={onSocialLinksChange}/>
      
        <br/>
        {!!id && 
          <FormControl>
            <h4>{localization.get('form.footer.type')}</h4>
            <RadioGroup
              name="footerType"
              value={footerType}
              onChange={e => {
                e.target.id = "footerType";
                onChange(e)
              }}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="simple" control={<Radio color="default"/>} label={localization.get('form.footer.simple')}/>
              <FormControlLabel value="free" control={<Radio color="default"/>} label={localization.get('form.footer.free')}/>
              <FormControlLabel value="advanced" control={<Radio color="default"/>} label={localization.get('form.footer.advanced')}/>
            </RadioGroup>
          </FormControl>
        }
        {footerType === "advanced" ?
          <Card>
            <FormControl style={{margin: 10}}>
              <span>{localization.get('form.footer.logo_side')}</span>
              <RadioGroup
                name="footerLogoSide"
                value={footerLogoSide}
                onChange={e => {
                  e.target.id = "footerLogoSide";
                  onChange(e)
                }}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="left" control={<Radio color="default"/>} label={localization.get('form.left')}/>
                <FormControlLabel value="right" control={<Radio color="default"/>} label={localization.get('form.right')}/>
              </RadioGroup>
            </FormControl>

            <FormControl>
              <span>{localization.get('form.footer.social_with_logo')}</span>
              <RadioGroup
                name="footerSocialWithLogo"
                value={footerSocialWithLogo}
                onChange={e => {
                  e.target.id = "footerSocialWithLogo";
                  onChange(e)
                }}
              >
                <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('form.footer.social_with_logo.with')}/>
                <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('form.footer.social_with_logo.without')}/>
              </RadioGroup>
            </FormControl>


            <FormControl style={{margin: 10}}>
              <span>{localization.get('form.footer.social_position')}</span>
              <RadioGroup
                name="footerSocialPosition"
                value={footerSocialPosition}
                onChange={e => {
                  e.target.id = "footerSocialPosition";
                  onChange(e)
                }}
                disabled={footerSocialWithLogo === "0"}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="left" control={<Radio color="default"/>} label={localization.get('form.left')}/>
                <FormControlLabel value="center" control={<Radio color="default"/>} label={localization.get('form.center')}/>
                <FormControlLabel value="right" control={<Radio color="default"/>} label={localization.get('form.right')}/>
              </RadioGroup>
            </FormControl>
           
            <FormControl style={{margin: 10}}>
              <span>{localization.get('form.footer_margin')}</span>
              <RadioGroup
                name="footerMargin"
                value={footerMargin}
                onChange={e => {
                  e.target.id = "footerMargin";
                  onChange(e)
                }}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="default" control={<Radio color="default"/>} label={localization.get('form.margin_default')}/>
                <FormControlLabel value="none" control={<Radio color="default"/>} label={localization.get('form.margin_none')}/>
              </RadioGroup>
            </FormControl>
            
            <br/>
            <br/>
              
            <div style={{width: "100%", display:"flex", flexDirection: "row"}}>
              {footerLogoSide === "left" &&
                <Card style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", alignItems: "center", flexDirection: "column"}}>
                  <p>{localization.get('form.footer.logo')}</p>
                  {footerSocialWithLogo === "1" && <p>{localization.get('form.footer.socials')}</p>}
                </Card>
              }
              {footerLogoSide === "right" && footerSocialWithLogo === "0" &&
               <Card style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", alignItems: "center", flexDirection: "column"}}>
                  <p>{localization.get('form.footer.socials')}</p>
                </Card>
              } 
              {footerElements.filter(element => !element.parent).length < 3 &&
                <Card style={{height: "170px", display: "flex", alignItems: "center", width: "100%", flexDirection: "column", justifyContent: "space-around"}}>
                  <h3 style={{
                    fontSize: 13,
                    textAlign: "center",
                    overflow: "hidden",
                  }}
                  >{localization.get('form.add_footer_button')}</h3>
                  <IconButton onClick={() => onAddFooter()}>
                    <Add />
                  </IconButton>
                </Card>
              }
              {footerElements.filter(element => !element.parent).map(element =>
                  <Card style={{height: "170px", display: "flex", alignItems: "center", width: "100%", flexDirection: "column", justifyContent: "space-around"}}>
                  <h3 style={{
                    fontSize: 13,
                    textAlign: "center",
                    overflow: "hidden",
                  }}
                  >
                  {element.title}
                  </h3>
                  <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                    <IconButton style={{margin: "-4px 0px"}} onClick={() => openCloseEditDialog(element)}><Edit/></IconButton>
                    <IconButton style={{margin: "-4px 0px"}} onClick={() => openCloseEditDropDownDialog(element, footerElements)}><Menu/></IconButton>
                    <IconButton style={{margin: "-4px 0px"}} onClick={() => removeNavbarElement(element.id)}><Delete/></IconButton>
                  </CardActions>
                </Card>
              )}
              {footerLogoSide === "right" &&
                <Card style={{height: "auto", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", alignItems: "center", flexDirection: "column"}}>
                  <p>{localization.get('form.footer.logo')}</p>
                  {footerSocialWithLogo === "1" && <p>{localization.get('form.footer.socials')}</p>}
                </Card>
              }
              {footerLogoSide === "left" && footerSocialWithLogo === "0" &&
               <Card style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", alignItems: "center", flexDirection: "column"}}>
                  <p>{localization.get('form.footer.socials')}</p>
                </Card>
              } 
            </div>
          </Card>
          :
          footerType === "free" &&
            <TranslatableInputs
              color="dark"
              isEditorialContent
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = footerTranslations.find(tr => tr.language === language);

                return {
                  language: language,
                  content: [
                    <TextValidator
                      style={{display: "none"}}
                      id={`text${language}`}
                      label={localization.get('form.note')}
                      name={`text${language}`}
                      value={currentLangTranslation.title}
                      onChange={e => onChangeTranslation(e.target.value, "title", language, "footerTranslations")}
                    />,
                    <div id="ignore">
                      <EditorialEditor
                        editorState={currentLangTranslation.title}
                        onChange={e => onChangeTranslation(e, "title", language, "footerTranslations")}
                        noColors
                      />
                    </div>
                  ]
                }
              })}
            />
          }

        {footerType === "simple" &&
          <>
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  id="showSupport"
                  style={{ color: "#4cc2d5" }}
                  checked={showSupport}
                  onChange={onChange}
                  disabled={disableForm}
                />
              }
              label={localization.get('form.show_support')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="hasFooterLogo"
                  style={{ color: "#4cc2d5" }}
                  checked={hasFooterLogo}
                  onChange={onChange}
                  disabled={disableForm}
                />
              }
              label={localization.get('form.footer.logo')}
            />
            {userIsSysAdmin() &&
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={bloomcloudChannel}
                    onChange={() => {
                      const e = {
                        target: {
                          id: "bloomcloudChannel",
                          value: !bloomcloudChannel,
                        },
                      };
                      onChange(e);
                    }}
                  />
                }
                label={localization.get("bloomcloud_channel")}
              />
            }
          </>
        }
        <TextValidator
          style={{width: "100%", marginBottom: "20px"}}
          id="email"
          label={localization.get('contact_email')}
          name="email"
          value={email}
          onChange={onChange}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        />

        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={useFloatingContactButton}
              disabled={!email}
              onChange={() => {
                const e = {
                  target: {
                    id: "useFloatingContactButton",
                    value: !useFloatingContactButton,
                  },
                };
                onChange(e);
              }}
            />
          }
          label={localization.get("form.use_floating_contact_button")}
        />
        {useFloatingContactButton &&
          <>
            {<h4>{localization.get('channel.floating_button_image')}</h4>}
            {floatingButtonImage && <ImagePicker image={floatingButtonImage.src} previewOnly={true} id={floatingButtonImage.id} />}
            <FilePondUploader
              disableForm={disableForm}
              updateDisableForm={updateDisableForm}
              file={floatingButtonImage}
              id={"floatingButtonImage"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={onFloatingButtonImageChange}
              setPreview={onSetPreview}
              clientId={client && client.id}
            />
          </>
        }

        <br/>
        {userCanSeeAuth &&
          <TextField
            fullWidth
            id={"customScripts"}
            name={"customScripts"}
            placeholder={"BOTi (URL del js)"}
            value={customScripts}
            onChange={onChange}
            label={"BOTi"}
          />
        }
        
        {<h4>{localization.get('channel.footer_image')}</h4>}
        {footerImage && <ImagePicker image={footerImage.src} previewOnly={true} id={footerImage.id} />}
        <FilePondUploader
          disableForm={disableForm}
          updateDisableForm={updateDisableForm}
          file={footerImage}
          id={"footerImage"}
          type={"image"}
          allowedTypes={["image/*"]}
          onIdChange={onFooterImageChange}
          setPreview={onSetPreview}
          clientId={client && client.id}
        />
        <h4 >{localization.get('terms_of_use')}</h4>
          {hasBinary &&
            <img
              style={{ width: '100%', borderRadius: 6, maxWidth: '100px', marginBottom: 10, marginLeft: "calc(50% - 50px)" }}
              src={Pdf}
              alt="..."
            />
          }
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={binary}
            id={"binary"}
            allowedTypes={["application/pdf"]}
            onIdChange={onBinaryChange}
            clientId={client && client.id}
          />
          <h4 >{localization.get('privacy_policy')}</h4>
          {hasPolicy &&
            <img
              style={{ width: '100%', borderRadius: 6, maxWidth: '100px', marginBottom: 10, marginLeft: "calc(50% - 50px)" }}
              src={Pdf}
              alt="..."
            />
          }
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={policy}
            id={"policy"}
            allowedTypes={["application/pdf"]}
            onIdChange={onPolicyChange}
            clientId={client && client.id}
          />
      </div>

      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5}} onClick={() => hideDivs('channel-texts', 'arrow-texts')}>
        {localization.get('texts')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-texts-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-texts-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-texts'} >
        <FormControl>
          <span>{localization.get('form.show_about', name)}</span>
          <RadioGroup
            name="showAbout"
            value={showAbout}
            onChange={e => {
              e.target.id = "showAbout";
              onChange(e)
            }}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="none" control={<Radio color="default"/>} label={localization.get('form.not_show')}/>
            {/*<FormControlLabel value="navbar" control={<Radio color="default"/>} label={localization.get('form.show_on_navbar')}/>*/}
            <FormControlLabel value="body" control={<Radio color="default"/>} label={localization.get('form.show_on_body')}/>
           </RadioGroup>
        </FormControl>

        {showAbout == 'body' &&
          <>
            <TranslatableInputs
              color="dark"
              isEditorialContent
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = translations.find(tr => tr.language === language);

                return {
                  language: language,
                  content: [
                    <TextValidator
                      style={{display: "none"}}
                      id={`about${language}`}
                      label={localization.get('form.note')}
                      name={`about${language}`}
                      value={currentLangTranslation.about}
                      onChange={e => onChangeTranslation(e.target.value, "about", language)}
                    />,
                    <div id="ignore">
                      <div style={{marginBottom: "-2rem"}}>{localization.get('form.note')}</div>
                      <EditorialEditor
                        editorState={currentLangTranslation.about}
                        onChange={e => onChangeTranslation(e, "about", language)}
                        noColors
                      />
                    </div>
                  ]
                }
              })}
            />
          </>
        }
        <TranslatableInputs
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <>
                  <>
                    <div style={{marginBottom: "-2rem"}}>{localization.get('form.welcome')}</div>
                    <RichTextEditor
                      editorState={currentLangTranslation.welcome}
                      onChange={e => onChangeTranslation(e, "welcome", language)}
                    />
                  </>
                  <>
                    <TextValidator
                      style={{display: "none"}}
                      id={`registerConfirmed${language}`}
                      label={localization.get('form.email_register_confirm')}
                      name={`registerConfirmed${language}`}
                      value={currentLangTranslation.registerConfirmed.getCurrentContent()}
                      onChange={e => onChangeTranslation(e.target.value, "registerConfirmed", language)}
                      registerConfirmed
                      disableForm={disableForm}
                      isHtml={true}
                    />
                    <div style={{marginBottom: "-2rem"}}>{localization.get('form.email_register_confirm')}</div>
                    <RichTextEditor
                      editorState={currentLangTranslation.registerConfirmed}
                      onChange={e => onChangeTranslation(e, "registerConfirmed", language)}
                      registerConfirmed
                    />
                  </>

                  <>
                    <TextValidator
                      style={{display: "none"}}
                      id={`registerEvaluating${language}`}
                      label={localization.get('form.email_register_evaluating')}
                      name={`registerEvaluating${language}`}
                      value={currentLangTranslation.registerEvaluating.getCurrentContent()}
                      onChange={e => onChangeTranslation(e.target.value, "registerEvaluating", language)}
                      disableForm={disableForm}
                      isHtml={true}
                    />
                    <div style={{marginBottom: "-2rem"}}>{localization.get('form.email_register_evaluating')}</div>
                    <RichTextEditor
                      editorState={currentLangTranslation.registerEvaluating}
                      onChange={e => onChangeTranslation(e, "registerEvaluating", language)}
                    />
                  </>

                  <>
                    <TextValidator
                      style={{display: "none"}}
                      id={`evaluateApproved${language}`}
                      label={localization.get('form.email_evaluate_approved')}
                      name={`evaluateApproved${language}`}
                      value={currentLangTranslation.evaluateApproved.getCurrentContent()}
                      onChange={e => onChangeTranslation(e.target.value, "evaluateApproved", language)}
                      disableForm={disableForm}
                      isHtml={true}
                    />
                    <div style={{marginBottom: "-2rem"}}>{localization.get('form.email_evaluate_approved')}</div>
                    <RichTextEditor
                      editorState={currentLangTranslation.evaluateApproved}
                      onChange={e => onChangeTranslation(e, "evaluateApproved", language)}
                    />
                  </>

                  <>
                    <TextValidator
                      style={{display: "none"}}
                      id={`regionLimit${language}`}
                      label={localization.get('form.region_limit')}
                      name={`regionLimit${language}`}
                      value={currentLangTranslation.regionLimit.getCurrentContent()}
                      onChange={e => onChangeTranslation(e.target.value, "regionLimit", language)}
                      disableForm={disableForm}
                      isHtml={true}
                    />
                    <div style={{marginBottom: "-2rem"}}>{localization.get('form.region_limit')}</div>
                    <RichTextEditor
                      editorState={currentLangTranslation.regionLimit}
                      onChange={e => onChangeTranslation(e, "regionLimit", language)}
                    />
                  </>
                  <>
                    <TextValidator
                      style={{width: "100%"}}
                      id={`loginRedirectText${language}`}
                      label={localization.get('form.login_redirect')}
                      name={`loginRedirectText${language}`}
                      value={currentLangTranslation.loginRedirectText}
                      onChange={e => onChangeTranslation(e.target.value, "loginRedirectText", language)}
                      disableForm={disableForm}
                      isHtml={true}
                    />
                    <TextValidator
                      style={{width: "100%"}}
                      id={`loginRedirectText${language}`}
                      label={localization.get('form.text_view_limit')}
                      name={`loginRedirectText${language}`}
                      value={currentLangTranslation.viewsLimit}
                      onChange={e => onChangeTranslation(e.target.value, "viewsLimit", language)}
                      disableForm={disableForm}
                    />
                    <TextValidator
                      style={{width: "100%"}}
                      id={`restrictedContentText${language}`}
                      label={localization.get('form.restricted_content_text')}
                      name={`restrictedContentText${language}`}
                      value={currentLangTranslation.restrictedContentText}
                      onChange={e => onChangeTranslation(e.target.value, "restrictedContentText", language)}
                      disableForm={disableForm}
                    />
                    <TextValidator
                      style={{width: "100%"}}
                      id={`fpsCertificateLimit${language}`}
                      label={localization.get('form.text_fps_certificate')}
                      name={`fpsCertificateLimit${language}`}
                      value={currentLangTranslation.fpsCertificateLimit}
                      onChange={e => onChangeTranslation(e.target.value, "fpsCertificateLimit", language)}
                      disableForm={disableForm}
                    />
                    <TextValidator
                      style={{width: "100%"}}
                      id={`requireDrmText${language}`}
                      label={localization.get('form.text_require_drm')}
                      name={`requireDrmText${language}`}
                      value={currentLangTranslation.requireDrmText}
                      onChange={e => onChangeTranslation(e.target.value, "requireDrmText", language)}
                      disableForm={disableForm}
                    />
                    <TextValidator
                      style={{width: "100%"}}
                      id={`supportText${language}`}
                      label={localization.get('form.text_support')}
                      name={`supportText${language}`}
                      value={currentLangTranslation.supportText}
                      onChange={e => onChangeTranslation(e.target.value, "supportText", language)}
                      disableForm={disableForm}
                    />
                    <TextValidator
                      style={{width: "100%"}}
                      id={`suggestedText${language}`}
                      label={localization.get('form.suggested_text')}
                      name={`suggestedText${language}`}
                      value={currentLangTranslation.suggestedText}
                      onChange={e => onChangeTranslation(e.target.value, "suggestedText", language)}
                      disableForm={disableForm}
                    />
                  </>
                </>
              ]
            }
          })}
        />
      </div>

      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)", margin: "5px 0",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('channel-analytics', 'arrow-analytics')}>
        {localization.get('channel_tracking')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-analytics-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-analytics-down"}/>
      </h3>
      <br/>
      <div style={{display: !id ? "block": "none"}} id={'channel-analytics'} >
        <FormControlLabel
          control={
            <Checkbox
              checked={noIndex}
              style={{color: "#4cc2d5"}}
              onChange={() => {
                const e = {
                  target: {
                    id: "noIndex",
                    value: !noIndex,
                  },
                };
                onChange(e);
              }}
            />
          } label={localization.get('form.no_index')}
        />
            <TextValidator
              style={{ width: "100%", marginBottom: "20px" }}
              id="googleAnalyticsId"
              label={"GA4 Measurement ID"}
              name="googleAnalyticsId"
              value={googleAnalyticsId}
              onChange={onChange}
            />
            <TextValidator
              style={{ width: "100%", marginBottom: "20px" }}
              id="googleTagManagerId"
              label={localization.get('form.tag_manager')}
              name="googleTagManagerId"
              value={googleTagManagerId}
              onChange={onChange}
            />

        {!noIndex &&
          <>
            <TranslatableInputs
              color="dark"
              isEditorialContent
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = translations.find(tr => tr.language === language);

                return {
                  language: language,
                  content: [
                    <TextValidator
                      style={{ width: "100%" }}
                      id={`keywordsSEO${language}`}
                      label={localization.get("form.seo_keywords")}
                      name={`keywordsSEO${language}`}
                      value={currentLangTranslation.keywordsSEO}
                      onChange={e => onChangeTranslation(e.target.value, "keywordsSEO", language)}
                    />,
                    <TextValidator
                      style={{ width: "100%" }}
                      id={`descriptionSEO${language}`}
                      label={localization.get("form.seo_description")}
                      name={`descriptionSEO${language}`}
                      value={currentLangTranslation.descriptionSEO}
                      onChange={e => onChangeTranslation(e.target.value, "descriptionSEO", language)}
                    />
                  ]
                }
              })}
            />
           
          </>
        }
      </div>
    </div>
  )
};

export default FormContent
