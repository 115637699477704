import React from 'react'
import {ImagePicker} from '../../components/Letflow/Form';
import localization from '../../config/localization';
import AvailableLanguages from '../../config/localization/availableLanguages.json';
import {TextValidator} from "react-material-ui-form-validator";
import {
  Card,
  CardContent,
  Checkbox, Dialog, DialogContent, DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  TextField,
} from "@material-ui/core";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import Button from "@material-ui/core/Button";
import browserHistory from "../../utils/browserHistory";
import {
  Add,
  Close,
  Done,
  Event,
  ExpandLess,
  ExpandMore,
  Help,
  ShortText,
  Restore, Delete, Cached, Style,
  ExposurePlus1, Info, ImportExport,
} from "@material-ui/icons";
import ClientSidePaginationTable from "../../components/Letflow/Table/ClientSidePaginationTable";
import {TableButtonsBuilder, ServerSidePaginationTable} from "../../components/Letflow/Table";
import {request} from "../../api-client";
import arrayMove from "array-move";
import AsyncSelect from "react-select/lib/Async";
import {getStoredUser, userIsContentSupervisor} from "../../api-client/core/authentication/utils";
import SocialLinks from "../../components/Letflow/SocialLinks";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import Unsplash from "../../components/Letflow/Unsplash";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import InfiniteScroll from "react-infinite-scroll-component";
import {makeImageUrlWithSize, onImageError} from "../../layouts/Home/HomeElements/utils";
import {ErrorMessage} from "formik";
import EditorialEditor from "../../components/Letflow/RichTextEditor/EditorialEditor";
import TagSelection from '../../components/Letflow/TagSelection';
import ColorPicker from "../../components/Letflow/ColorPicker";
import Select from "react-select";
import RecurrentEvents from '../../components/Letflow/RecurrentEvents';
import RegionSelector from "../../components/Letflow/RegionSelector";
import DriveFileSelector from "../../components/Letflow/DriveFileSelector";
import { get } from "lodash"
import TableButton from "../../components/Letflow/Table/Buttons/TableButton";
import GlobalConfirmationDialog from "../../components/Letflow/Dialog/GlobalConfirmationDialog";
import LoadingSpinner from "../../assets/img/loading_spinner.gif";

const hideDivs = (divId, ArrowId) => {
  let x = document.getElementById(divId);
  let up = document.getElementById(`${ArrowId}-up`);
  let down = document.getElementById(`${ArrowId}-down`);

  if (x.style.display === "none") {
    x.style.display = "inline-block";
    down.style.display = "none";
    up.style.display = "block";
  } else {
    x.style.display = "none";
    down.style.display = "block";
    up.style.display = "none";
  }
}

const getViews = creation => creation.view_limit === 0 ? "-" : creation.view_limit < creation.views ? creation.vies_limit : creation.views;

const FormContent = ({
  id,
  email,
  phone,
  image,
  onChange,
  onImageChange,
  disabled,
  tagGroups,
  onSelectedTagsChanged,
  country,
  onAsyncCountriesChange,
  observation,
  onActiveChange,
  active,
  contentCreations,
  onContentCreationsOrderChanged,
  handleContentCreationRemoved,
  onCatalogChange,
  selectedCatalogs,
  isDemo,
  onSelectedContentCreatorTypeChange,
  selectedContentCreatorType,
  socialLinks,
  onSocialLinksChange,
  availableContentCreatorTypes,
  coverImage,
  onCoverImageChange,
  featuredImage,
  onFeaturedImageChange,
  mobileCoverImage,
  onMobileCoverImageChange,
  disableForm,
  updateDisableForm,
  onSetPreview,
  onSetCoverPreview,
  onSetFeaturedPreview,
  onSetMobileCoverPreview,
  weight,
  onSetCoverImageFromUnsplash,
  onSetFeaturedImageFromUnsplash,
  onSetProfileImageFromUnsplash,
  onSetMobileCoverImageFromUnsplash,
  coverImageRadio,
  featuredImageRadio,
  profileImageRadio,
  mobileCoverImageRadio,
  onImageRadiosChange,
  startActivity,
  handleStartActivityChange,
  countryIsCompleted,
  isTalent,
  isEditorial,
  isEvent,
  onEventDatesChange,
  eventDates,
  onContentChange,
  onBatchProfileLinkChange,
  selectedBatchProfileLink,
  onBatchProfileFileChange,
  selectedBatchProfileImageFile,
  onBatchCoverLinkChange,
  selectedBatchCoverLink,
  onBatchMobileCoverLinkChange,
  onBatchCoverFileChange,
  onBatchMobileCoverFileChange,
  selectedBatchCoverImageFile,
  onBatchFeaturedLinkChange,
  selectedBatchFeaturedLink,
  selectedBatchMobileCoverLink,
  selectedBatchMobileCoverImageFile,
  onBatchFeaturedFileChange,
  selectedBatchFeaturedImageFile,
  onDownloadableMaterialChange,
  setDownloadableMaterialFileType,
  downloadableMaterialText,
  downloadableMaterialFilename,
  onDownloadableMaterialDelete,
  buyButtonProduct,
  selectedBuyButtonProduct,
  buyButtonText,
  buyButtonShowInSocials,
  channel,
  onContentSupervisorChange,
  contentSupervisor,
  onlyArgentina,
  releaseDate,
  expireDate,
  hasExpiredDate,
  onHasExpiredDateChange,
  isPermanentContent,
  onIsPermanentContentChange,
  downloadableMaterialRadio,
  onBatchDMaterialLinkChange,
  selectedBatchDMaterialLink,
  onBatchDMaterialFileChange,
  selectedBatchDMaterialFile,
  requireLogin,
  onRequireLoginChange,
  downloadableMaterialLink,
  showHelper,
  updateHelper,
  hasSuggestedCarousel,
  onSuggestedCarouselChange,
  suggestedCarouselRadio,
  onSuggestedCarouselPlaylistChange,
  suggestedCarouselPlaylist,
  showDateOnHome,
  showTags,
  suggestedTagGroup,
  suggestedView,
  onShowTagsChange,
  suggestedTagGroupChange,
  onSuggestedCarouselRadioChange,
  hasDownloadableMaterial,
  onHasDMaterialChange,
  hasBuyButton,
  onHasBuyButtonChange,
  getSynopsis,
  clouds,
  selectedCloud,
  onSelectedCloudsChange,
  access,
  onAccessChange,
  openDialog,
  useBackgroundColor,
  backgroundColor,
  useBackgroundGradient,
  titleColor,
  descriptionColor,
  creationColor,
  subtitleFontSize,
  subtitleMargin,
  cardColor,
  cardShape,
  hasPreferences,
  onHasPreferencesChange,
  onUseBackgroundColorChange,
  onUseBackgroundGradientChange,
  setDefaultStyles,
  creationInverted,
  showBox,
  eventsPosition,
  buyButtonPosition,
  buyButtonBackgroundColor,
  buyButtonFontColor,
  products,
  hasRegionBlock,
  onHasRegionBlockChange,
  allowedRegions,
  onChangeAllowedRegions,
  hasEditorialPreferences,
  onHasEditorialPreferencesChange,
  editorialBgColor,
  editorialTitleColor,
  editorialHideCoverImage,
  contentCreationsToDelete,
  onChangeContentCreationsToDelete,
  onDeleteManyMaterials,
  showCreationBorder,
  creationCarouselBackgroundColor,
  viewType,
  onChangeViewType,
  viewTypeMobile,
  onChangeViewTypeMobile,
  openRenameDialog,
  suggestedViewMultipleRow,
  openCloseReTagMaterialModal,
  onDriveImageChange,
  translations,
  onChangeTranslation,
  canonicalChannel,
  domainChannels,
  getDomainChannels,
  canonicalIsCompleted,
  suggestedContentCreators,
  suggestedContentCreatorsChange,
  logs=null,
  contentCreationsOrders=[],
  reorderingCreations,
  requestingMateriaials,
  requestContentCreations,
  page,
  lastPage,
  uploadingMaterials,
  accessLevel,
  additionInfoSize,
  eventButtonText,
  eventButtonColor,
  eventButtonBackgroundColor,
  editorialUseSynopsis
}) => {
  let getProductString = (product) => {
    let contentType = (product.content_type === "App\\ContentCreator") ? localization.get('contentCreators') : (product.content_type === "App\\ContentCreation") ? localization.get('table.share_material') :  localization.get('table.content_packs');
    return `${product.content && (product.content.name || product.content.title)} (${contentType})`;
  } 

  let reOrderColumn = {
    id: 'reorder',
    accessor: 'order',
    Header: () => <IconButton
     title={localization.get('content_creation.reorder_helper')}
     onClick={() => onChange({target: {id: "reorderingCreations", value: !reorderingCreations}})} ><ImportExport/></IconButton>,
    Cell: (cell) => {
      let editingCreationOrder = contentCreationsOrders.find(c => c.id === cell.original.id);

      return (
        <input
          type="number"
          value={editingCreationOrder.order}
          style={{width: 50}}
          onChange={(e) => {
            if (e.key !== "Enter") {  
              editingCreationOrder.order = e.target.value

              onChange({target: {value: "contentCreationsOrders", contentCreationsOrders}})
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {       
              e.preventDefault()
              e.stopPropagation()                       
              const reorderedSelected = arrayMove(contentCreations, cell.index, editingCreationOrder.order);
              onContentCreationsOrderChanged(reorderedSelected)
            }
          }}  
        />
      )},
    sortable: false,
    filterable: false,
    width: 60,
  }

  let orderColumn = {
    Header: () => <IconButton
     title={localization.get('content_creation.reorder_helper')}
     onClick={() => onChange({target: {id: "reorderingCreations", value: !reorderingCreations}})} ><ImportExport/></IconButton>,
    id: 'order',
    accessor: 'order',
    Cell: (cell) => <span>{cell.index+1}</span>,
    sortable: false,
    filterable: false,
    width: 60,
    style: {
      textAlign: "center"
    },
  }


  const columns = [
    {
      id: "image",
      accessor: contentCreation => (<img alt="Content creation" src={makeImageUrlWithSize(contentCreation, 'xs')} width={50} height={50} onError={onImageError}/>
      ),
      style: {
        float: "left"
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      id: "check",
      accessor: contentCreation =>
        <Checkbox
          style={{color: "#4cc2d5"}}
          checked={contentCreationsToDelete.includes(contentCreation.id)}
          onChange={() => onChangeContentCreationsToDelete(contentCreation.id)}
        />
      ,
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: () => <div style={{textAlign:"left"}}>{localization.get('table.title')}</div>,
      id: "title",
      filterColumn: "title",
      accessor: contentCreation => <Tooltip placement="top" title={contentCreation.title} ><span>{contentCreation.title}</span></Tooltip>,
      style: { float: "left" },
      filterable: !(navigator.platform.indexOf('Win') > -1),
      sortable: false,
    },
    {
      Header: localization.get('table.created_at'),
      id: 'created_at',
      accessor: contentCreation => localization.moment(contentCreation.created_at).fromNow(),
      style: {
        float: 'left',
      },
      resizeable: false,
      sortable: false,
      filterable: false,
    },
    {
      Header: localization.get('table.active'),
      id: 'active',
      accessor: contentCreation => (contentCreation.active ? <Done /> : <Close />),
      style: {
        float: 'left',
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get('table.views'),
      id: 'views',
      accessor: contentCreation => getViews(contentCreation),
      style: {
        textAlign: 'center',
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get('content_creation.material_type'),
      id: 'details',
      accessor: contentCreation => getContentCreationType(contentCreation),
      style: {
        textAlign: 'center',
      },
      resizeable: false,
      sortable: false,
      filterable: false,
    },
    {
      id: 'actions',
      Header: localization.get('table.actions'),
      sortable: false,
      filterable: false,
      accessor: (contentCreation) => buttons(contentCreation, handleContentCreationRemoved, isTalent, onChange),
      width: 145,
    }
  ];

  if(reorderingCreations) {
    columns.unshift(reOrderColumn)
  }else{
    columns.unshift(orderColumn)
  }

  return (
    <div>
      <Dialog open={logs} onBackdropClick={() => onChange({target: {id: "logs", value: null}})} onEscapeKeyDown={() => onChange({target: {id: "logs", value: null}})}>
        <DialogTitle>{localization.get('log')}</DialogTitle>
        <DialogContent>
          {logs && logs.map(log =>
            <>
              <p><span style={{fontWeight: "bold"}}>{localization.get('table.action')}: {log.action}</span></p>
              <p>
                <span style={{fontWeight: "bold"}}>User ID:</span> {log.user_id},
                <span style={{fontWeight: "bold"}}>{localization.get('client.name')}:</span> {log.user_name},
                <span style={{fontWeight: "bold"}}>{localization.get('Timestamp')}</span>{log.timestamp}
                {log.video_id && <> <span style={{fontWeight: "bold"}}>Video ID:</span> {log.video_id} </>}
              </p>
            </>
          )}
        </DialogContent>
      </Dialog>
      <br/>
      <Dialog open={showHelper} onBackdropClick={updateHelper} onEscapeKeyDown={updateHelper}>
        <DialogContent>
          <p>{localization.get('content_creator.helper')}</p>
        </DialogContent>
      </Dialog>
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-general-configuration', 'arrow-general')}>
        {localization.get('content_creator.general_config')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-general-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-general-down"}/>
      </h3>
      <br/>
      <div style={{width: "100%", display: !id ? "inline-block": "none"}} id={'content-creator-general-configuration'} >
        {!id ?

        <>
          {(userIsContentSupervisor() && getStoredUser().clouds.length > 0) ?
            <>
              <FormControl fullWidth>
                <span>{localization.get('form.select_clouds')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('form.select_clouds')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={val => {
                    onSelectedCloudsChange(val);

                    onChange({target: {value: [{value: val.catalog.id, label: val.catalog.name}], id: "selectedCatalogs"}})
                  }}
                  value={selectedCloud}
                  loadOptions={(inputValue, callback) => {
                    callback(clouds.filter(i =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    ))
                  }}
                  defaultOptions
                />
                <br/>
              </FormControl>
              {selectedCloud &&
                <FormControl fullWidth>
                  <span>{localization.get('form.select_content_creator_type')}</span>
                  <AsyncSelect
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 10
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0
                    })}
                    placeholder={localization.get('content_creator_types')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={val => onSelectedContentCreatorTypeChange(val, true)}
                    value={selectedContentCreatorType}
                    defaultOptions={clouds.find(c => c.value === selectedCloud.value).contentCreatorTypes}
                    loadOptions={(inputValue, callback) => {
                      callback(clouds.find(c => c.value === selectedCloud.value).contentCreatorTypes.filter(i =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase())
                      ))
                    }}
                  />
                </FormControl>
              }
            </>
          :
            <FormControl fullWidth>
              <span>{localization.get('form.select_content_creator_type')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('content_creator_types')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onSelectedContentCreatorTypeChange}
                value={selectedContentCreatorType}
                loadOptions={input => {
                  let  pagination = {orderBy: {column: "name", type: "ASC"},
                    filterBy: [
                      {column: "name", filter: input},
                      {column: "is_talent", filter: isTalent},
                      {column: "dashboard_combos", filter: 1},
                      //userisMultiClient() && {column: 'visibility', filter: 'private'}
                    ]
                  }
                  pagination = { ...pagination, filterBy: pagination.filterBy.concat([{ column: "type", filter: isEditorial ? "editorial" : "audiovisual" }, {column: "for_create", filter: true }])}
                  return request.contentCreatorType.getAll(pagination)
                    .then(contentCreatorTypes => contentCreatorTypes.map(type => ({value: type.id, label: type.name, slug: type.slug, type: type.type, client: type.client, client_id: type.client_id})))
                }}
                defaultOptions
              />
            </FormControl>
          }
          <br/>
          <br/>
        </>
        :
          <>
            {selectedCloud ?
              <FormControl fullWidth>
                <span>{localization.get('form.select_content_creator_type')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('content_creator_types')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={val => onSelectedContentCreatorTypeChange(val, true)}
                  value={selectedContentCreatorType}
                  defaultOptions={clouds.find(c => c.value === selectedCloud.value) && clouds.find(c => c.value === selectedCloud.value).contentCreatorTypes}
                  loadOptions={(inputValue, callback) => {
                    callback(clouds.find(c => c.value === selectedCloud.value).contentCreatorTypes.filter(i =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    ))
                  }}
                />
              </FormControl>
              :
              <FormControl fullWidth>
                <span>{localization.get('form.select_content_creator_type')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('content_creator_types')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onSelectedContentCreatorTypeChange}
                  value={selectedContentCreatorType}
                  loadOptions={(inputValue, callback) => {
                    callback(availableContentCreatorTypes.filter(i =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    ))
                  }}
                  defaultOptions={availableContentCreatorTypes}
                />
              </FormControl>
            }
            <br/>
            <br/>
          </>
        }

        {!isDemo && selectedContentCreatorType &&
          <>
            <FormControl fullWidth>
              <span>{localization.get('form.select_catalog')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('form.catalogs')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                isMulti
                isDisabled={userIsContentSupervisor() && getStoredUser().clouds.length > 0}
                onChange={onCatalogChange}
                value={selectedCatalogs}
                loadOptions={inputValue => {
                  let filterBy = [{column: "name", filter: inputValue}];
                  
                  if (!!selectedContentCreatorType.client) {
                    filterBy = filterBy.concat([{column: "created_by", filter: get(selectedContentCreatorType, "client.id")}])
                  }
                  return request.catalog
                    .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                    .then(res => res.map(catalog => ({value: catalog.id, label: `${catalog.name} (${localization.get(catalog.visibility)})` })))
                }}
                defaultOptions
              />
            </FormControl>
            <br/>
            <br/>
          </>
        }

        {!userIsContentSupervisor() && !!selectedCatalogs.length &&
          <>
            <h4>{localization.get('form.access')}</h4>
            <FormControl>
              <RadioGroup
                name="access"
                value={access}
                id="access"
                onChange={onAccessChange}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="sysadmin" control={<Radio color="default"/>}
                                  label={localization.get('form.access_sysadmin')}/>
                <FormControlLabel value="clouds" control={<Radio color="default"/>}
                                  label={localization.get('form.access_cloud')}/>
              </RadioGroup>
            </FormControl>
            <br/>
          </>
        }

        {(!userIsContentSupervisor() && access === "clouds") && !!selectedCatalogs.length &&
          <FormControl fullWidth>
            <span>{localization.get('form.select_clouds')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('form.select_clouds')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onSelectedCloudsChange}
              value={selectedCloud}
              loadOptions={(inputValue, callback) => {
                callback(clouds.filter(i =>
                  i.label.toLowerCase().includes(inputValue.toLowerCase())
                ))
              }}
              defaultOptions={clouds}
            />
          </FormControl>
        }
        <FormControl style={{ width: "100%", marginBottom: "20px" }}>
          <span>{localization.get('form.content_type')}</span>
          <RadioGroup
            name="isEvent"
            value={isEvent}
            id="isEvent"
            onChange={e => onChange({target: {
                id: "isEvent",
                value: e.target.value
              }})}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('form.content_is_classic')}/>
            <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('form.content_is_event')}/>
          </RadioGroup>
          {isEvent == "1" && <p>{localization.get('form.event_helper')}</p>}
        </FormControl>

        <TranslatableInputs
          color="dark"
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language ===language);

            return {
              language: language,
              content: [
                <TextValidator
                  style={{width: "100%", marginBotton: 15}}
                  id={`name${language}`}
                  label={localization.get(selectedContentCreatorType.type === 'editorial' ? 'form.title' : 'form.name')}
                  name={`name${language}`}
                  value={get(currentLangTranslation, "name", "")}
                  onChange={e => onChangeTranslation(e.target.value, "name", language)}
                  disabled={disabled}
                  validators={['required']}
                  errorMessages={[localization.get('validator.is_required')]}
                />,
                <TextValidator
                  style={{width: "100%", marginBotton: 15}}
                  id={`category${language}`}
                  label={localization.get(isEvent == "1" ? "form.tematic" :'form.section')}
                  name={`category${language}`}
                  value={get(currentLangTranslation, "category", "")}
                  onChange={e => onChangeTranslation(e.target.value, "category", language)}
                  disabled={disabled}
                />,
                <TextValidator
                  style={{width: "100%", marginBotton: 15}}
                  id={`author${language}`}
                  label={localization.get(selectedContentCreatorType.type !== 'editorial' ? 'form.extras' : isEvent == "1" ? 'form.where' : 'form.author')}
                  name={`author${language}`}
                  value={get(currentLangTranslation, "author", "")}
                  onChange={e => onChangeTranslation(e.target.value, "author", language)}
                  disabled={disabled}
                />,
                
                <>
                  {isEvent == "1" &&
                    <>
                    <TextValidator
                      style={{width: "100%", marginBotton: 15}}
                      id={`when${language}`}
                      label={localization.get('form.when')}
                      name={`when${language}`}
                      value={get(currentLangTranslation, "when", "")}
                      onChange={e => onChangeTranslation(e.target.value, "when", language)}
                      disabled={disabled}
                    />
                    <TextValidator
                      style={{width: "100%", marginBotton: 15}}
                      id={`entrance${language}`}
                      label={localization.get('form.entrance')}
                      name={`entrance${language}`}
                      value={get(currentLangTranslation, "entrance", "")}
                      onChange={e => onChangeTranslation(e.target.value, "entrance", language)}
                      disabled={disabled}
                    />
                    </>
                  }
                </>,
                <div style={{width: "100%", marginBotton: 15, display: "flex"}}>
                <textarea
                  style={{width: "100%", border: "none", marginTop: 20, borderBottom: "1px solid #ccc"}}
                  id={`synopsis${language}`}
                  name={`synopsis${language}`}
                  label={localization.get('form.synopsis')}
                  helperText={localization.get("form.synopsis_helper")}
                  value={get(currentLangTranslation, "synopsis", "")}
                  onChange={e => onChangeTranslation(e.target.value, "synopsis", language)}
                  disabled={disabled}
                  multiline
                  rows={4}
                />
                  <div style={{textAlign: 'center', top: 10}}>
                    <IconButton
                      title={localization.get('form.generate_synopsis')}
                      style={{top: 30}}
                      onClick={() => {
                        const e = {
                          target: {
                            id: "synopsis",
                            value: getSynopsis(get(currentLangTranslation, "description", "")),
                          },
                        };

                        onChangeTranslation(e.target.value, "synopsis", language)
                      }}
                    >
                      <ShortText style={{
                        border: "2px solid",
                        borderRadius: "50%",
                        width: 30,
                        height: 30,
                      }}/>
                    </IconButton>
                  </div>
                </div>
                ,
                (selectedContentCreatorType.type === 'audiovisual' &&
                  <>
                    <TextValidator
                      style={{display: "none"}}
                      id={`description${language}`}
                      label={localization.get('form.description')}
                      name={`description${language}`}
                      value={get(currentLangTranslation, "description", "")}
                      onChange={e => onChangeTranslation(e.target.value, "description", language)}
                      disabled={disabled}
                      isHtml={true}
                    />
                    <div id="ignore">
                      <br/>
                      <div style={{marginBottom: "-2rem"}}>{localization.get('form.description')}</div>
                      <EditorialEditor
                        toolbarOptions={['inline', 'blockType', 'list', 'link', 'remove', 'history']}
                        editorState={get(currentLangTranslation, "description", "")}
                        onChange={e => onChangeTranslation(e, "description", language)}
                        noColors
                      />
                    </div>
                  </>
                )
              ]
            }
          })}
        />

        {selectedContentCreatorType.type === 'editorial' &&
          <>
            <div style={{marginBottom: 40}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={hasEditorialPreferences}
                      onChange={() => onHasEditorialPreferencesChange(!hasEditorialPreferences)}
                    />
                  }
                  label={localization.get('content_creator.has_preferences')}
                />
              </div>
              <br/>
              {hasEditorialPreferences &&
                <div style={{width: "100%", float: "left", marginBottom: 40}}>
                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get("content_creator.cover_image_preferences")}</h4>
                    <div style={{display: "flex", flexDirection: "row"}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{color: "#4cc2d5"}}
                            checked={editorialHideCoverImage}
                            onChange={() => onChange({target: {value: !editorialHideCoverImage, id: "editorialHideCoverImage"}})}
                          />
                        }
                        label={localization.get('content_creator.show_cover_image')}
                      />
                    </div>
                  </div>
                  <FormControl style={{ float:"left", width: "25%"}}>
                    <label style={{height: 40}}> {localization.get('content_creator.background_color')} </label>
                    <ColorPicker
                      id={'editorialBgColor'}
                      handleColorChange={color =>
                        onChange({target: {
                            id: "editorialBgColor",
                            value: color
                          }})
                      }
                      startColor={editorialBgColor}
                    />
                  </FormControl>
                  <FormControl style={{ float:"left", width: "25%"}}>
                    <label style={{height: 40}}> {localization.get('content_creator.title_color')} </label>
                    <ColorPicker
                      id={'editorialTitleColor'}
                      handleColorChange={color =>
                        onChange({target: {
                            id: "editorialTitleColor",
                            value: color
                          }})
                      }
                      startColor={editorialTitleColor}
                    />
                  </FormControl>
                  <br/>
                  <h4>{localization.get("content_creator.use_synopsis")}</h4>
                  <div style={{display: "flex", flexDirection: "row"}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{color: "#4cc2d5"}}
                          checked={editorialUseSynopsis}
                          onChange={() => onChange({target: {value: !editorialUseSynopsis, id: "editorialUseSynopsis"}})}
                        />
                      }
                      label={localization.get('content_creator.use_synopsis_bis')}
                    />
                  </div>
                  <div style={{float: "left", width: "100%", marginTop: 15}}>
                    <h4>{localization.get('content_creator.event_button')}</h4>
                      <TextField
                        style={{ width: "33%", float: "left", }}
                        name="eventButtonText"
                        id="eventButtonText"
                        label={localization.get('content_creator.event_button.text')}
                        autoComplete={false}
                        value={eventButtonText}
                        onChange={onChange}
                      />
                      <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
                        <Tooltip title={localization.get('content_creator.buy_button.font_color')} placement='top'>
                          <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.font_color')} </label>
                        </Tooltip>
                        <ColorPicker
                          id={'eventButtonColor'}
                          handleColorChange={color =>
                            onChange({
                              target: {
                                id: "eventButtonColor",
                                value: color
                              }
                            })
                          }
                          startColor={eventButtonColor}
                          disableAlpha={true}
                        />
                      </FormControl>
                      <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
                        <Tooltip title={localization.get('content_creator.buy_button.font_color')} placement='top'>
                          <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.bg_color')} </label>
                        </Tooltip>
                        <ColorPicker
                          id={'eventButtonBackgroundColor'}
                          handleColorChange={color =>
                            onChange({
                              target: {
                                id: "eventButtonBackgroundColor",
                                value: color
                              }
                            })
                          }
                          startColor={eventButtonBackgroundColor}
                          disableAlpha={true}
                        />
                      </FormControl>
                    </div>
                </div>
              }
            </div>
            <TranslatableInputs
              color="dark"
              isEditorialContent
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = translations.find(tr => tr.language === language);

                return {
                  language,
                  content: [
                    <TextValidator
                      style={{display: "none"}}
                      id={`description${language}`}
                      label={localization.get('form.note')}
                      name={`description${language}`}
                      value={currentLangTranslation.description}
                      onChange={e => onChangeTranslation(e, "description", language)}
                      disabled={disabled}
                    />,
                    <div id="ignore">
                      <div style={{marginBottom: "-2rem"}}>{localization.get('form.note')}</div>
                      <EditorialEditor
                        editorState={currentLangTranslation.description}
                        scrollable
                        onChange={e => onChangeTranslation(e, "description", language)}
                        noColors
                        bgColor={hasEditorialPreferences && editorialBgColor}
                      />
                    </div>
                  ]
                }
              })}
            />
          </>
        }

        {isTalent &&
          <>
            <TextValidator
              style={{ width: "100%", marginBottom: 20 }}
              id="email"
              label={localization.get('form.email')}
              name="email"
              value={email}
              onChange={onChange}
              disabled={disabled}
            />
            <TextValidator
              style={{ width: "100%", marginBottom: 20 }}
              id="phone"
              label={localization.get('form.phone')}
              name="phone"
              value={phone}
              onChange={onChange}
              disabled={disabled}
            />
            <FormControl fullWidth style={{marginBottom: "10px"}}>
              <span>{localization.get('form.country')} *</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  }),
                  control: (base, state) => ({
                    ...base,
                    // state.isFocused can display different borderColor if you need it
                    borderColor: state.isFocused ?
                      '#ddd' : countryIsCompleted ?
                        '#ddd' : 'red',
                    // overwrittes hover style
                    '&:hover': {
                      borderColor: state.isFocused ?
                        '#ddd' : countryIsCompleted ?
                          '#ddd' : 'red'
                    }
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onAsyncCountriesChange}
                value={country}
                name={"country"}
                defaultOptions
                loadOptions={inputValue =>
                  request.country
                    .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                    .then(res => res.sort((a, b) => a.name.localeCompare(b.name)).map(country => ({ value: country.id, label: `${country.name}` })))
                }
                errorMessages={[localization.get("validator.is_required")]}
              />
            </FormControl>
          </>
        }

        
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {selectedContentCreatorType.type !== 'editorial' ?
                <DatePicker
                  style={{marginBottom: 15}}
                  fullWidth
                  autoOk
                  keyboard
                  clearable
                  onlyCalendar
                  disableFuture
                  defaultValue={startActivity}
                  format={"dd/MM/yyyy"}
                  label={localization.get(isTalent ? 'start_activity' : 'form.publish_date')}
                  value={startActivity}
                  onChange={handleStartActivityChange}
                /> :
                <DateTimePicker
                  style={{marginBottom: 15}}
                  autoOk
                  autoSubmit
                  disableFuture
                  ampm={false}
                  format={"dd/MM/yyyy HH:mm a"}
                  dateRangeIcon={<Event/>}
                  id={"releaseDate"}
                  defaultValue={startActivity}
                  label={localization.get('form.publish_date')}
                  value={startActivity}
                  onChange={e => {
                    onChange({
                      target: {
                        id: "startActivity",
                        value: e,
                      }
                    })
                  }}
                />
              }
            </MuiPickersUtilsProvider>
            {selectedContentCreatorType.type === 'editorial' &&
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={showDateOnHome}
                      onChange={() => onChange({target: {id: "showDateOnHome", value: !showDateOnHome}})}
                    />
                  }
                  label={localization.get('form.show_date_on_home')}
                />
              </div>
            }
          </>


        <h4>{localization.get('form.select_image_profile')}</h4>
        <FormControl>
          <RadioGroup
            name="profileImageRadio"
            value={profileImageRadio}
            onChange={e => onImageRadiosChange(e)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="filepond" control={<Radio color="default"/>}
                              label={localization.get('client_file.upload_from_computer')}/>
            <FormControlLabel value="unsplash" control={<Radio color="default"/>}
                              label={localization.get('form.upload_from_unsplash')}/>
            <FormControlLabel value="batch" control={<Radio color="default"/>}
                              label={localization.get('batch_upload.upload_from_platform')}/>
            {selectedContentCreatorType.client && <FormControlLabel value="drive" control={<Radio color="default"/>}
                              label={localization.get('batch_upload.upload_from_drive')}/>}
            {id && contentCreations.length > 0 && 
              <FormControlLabel value="random" control={<Radio color="default"/>} label={localization.get('form.use_random_material')}/>
            }
          </RadioGroup>
        </FormControl>
        <br/>
        {profileImageRadio === 'filepond' ?
          <>
            {image && <ImagePicker image={image.src} id={image.id} previewOnly={true} />}
            <FilePondUploader
              disableForm={disableForm}
              updateDisableForm={updateDisableForm}
              file={image}
              id={"image"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={onImageChange}
              setPreview={onSetPreview}
              clientId={get(selectedContentCreatorType, "client.id")}
            />
            <div style={{
              color: "#757575",
              textAlign: 'center',
              marginTop: 5
            }}>{localization.get('helper_text.images_width_2048')}</div>
          </>
          : profileImageRadio === 'unsplash' ?
            <Unsplash imageAmount={9} onSelectImage={onSetProfileImageFromUnsplash}/>
            : profileImageRadio === "batch" ?
            <>
              {selectedBatchProfileImageFile &&
              <ImagePicker image={selectedBatchProfileImageFile.src} previewOnly={true} />}
              <FormControl fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchProfileLinkChange}
                  value={selectedBatchProfileLink}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "name", filter: inputValue}, {
                      column: "is_talent",
                      filter: isTalent ? 1 : 0
                    }];

                    return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                      .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                  }}
                  defaultOptions
                />
              </FormControl>

              {selectedBatchProfileLink &&
              <FormControl style={{marginTop: 20}} fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchProfileFileChange}
                  value={selectedBatchProfileImageFile}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'image'}];

                    return request.batchUpload.getAllFiles({
                      orderBy: {column: "created_at", type: "DESC"},
                      filterBy
                    }, selectedBatchProfileLink.token)
                      .then(files => files.map(file => ({value: file.id, label: file.filename, src: file.image.url})))
                  }}
                  defaultOptions
                />
              </FormControl>
              }
            </>
          : profileImageRadio === 'drive' ?
              <DriveFileSelector
                clientId={get(selectedContentCreatorType, "client.id")}
                onSelectFile={id => onDriveImageChange('driveProfileImage', id)}
                fileType={"image"}
              />
              : null
        }
        <br/>
        <h4>{localization.get('form.select_image_cover')}</h4>
        <FormControl>
          <RadioGroup
            name="coverImageRadio"
            value={coverImageRadio}
            onChange={e => onImageRadiosChange(e)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
            <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
            <FormControlLabel value="batch" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_platform')}/>
            {selectedContentCreatorType.client &&
              <FormControlLabel value="drive" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_drive')}/>
            }
            {id && contentCreations.length > 0 && 
              <FormControlLabel value="random" control={<Radio color="default"/>} label={localization.get('form.use_random_material')}/>
            }
          </RadioGroup>
        </FormControl>
        {coverImageRadio === 'filepond' ?
          <>
            {coverImage && <ImagePicker image={coverImage.src} previewOnly={true} id={coverImage.id} />}
            <FilePondUploader
              disableForm={disableForm}
              updateDisableForm={updateDisableForm}
              file={coverImage}
              id={"cover"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={onCoverImageChange}
              setPreview={onSetCoverPreview}
              clientId={get(selectedContentCreatorType, "client.id")}
            />
            <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_width_2048')}</div>
          </>
          : coverImageRadio === 'unsplash' ?
          <Unsplash imageAmount={9} onSelectImage={onSetCoverImageFromUnsplash}/>
          : coverImageRadio === 'drive' ?
            <DriveFileSelector
              clientId={get(selectedContentCreatorType, "client.id")}
              onSelectFile={id => onDriveImageChange('driveCoverImage', id)}
              fileType={"image"}
            />
          : profileImageRadio === 'batch' ?
            <>
              {selectedBatchCoverImageFile && <ImagePicker image={selectedBatchCoverImageFile.src} previewOnly={true} />}
              <FormControl fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchCoverLinkChange}
                  value={selectedBatchCoverLink}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "name", filter: inputValue}, {column: "is_talent", filter: isTalent ? 1 : 0}];

                    return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                      .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                  }}
                  defaultOptions
                />
              </FormControl>

              {selectedBatchCoverLink &&
              <FormControl style={{marginTop: 20}} fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchCoverFileChange}
                  value={selectedBatchCoverImageFile}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'image'}];

                    return request.batchUpload.getAllFiles({orderBy: {column: "created_at", type: "DESC"}, filterBy}, selectedBatchCoverLink.token)
                      .then(files => files.map(file => ({value: file.id, label: file.filename, src: file.image.url})))
                  }}
                  defaultOptions
                />
              </FormControl>
              }
            </>
            : null
        }
        <br/>
        <h4>{localization.get('form.select_image_cover_mobile')}</h4>
        <FormControl>
          <RadioGroup
            name="mobileCoverImageRadio"
            value={mobileCoverImageRadio}
            onChange={e => onImageRadiosChange(e)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
            <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
            <FormControlLabel value="batch" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_platform')}/>
            {selectedContentCreatorType.client &&
              <FormControlLabel value="drive" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_drive')}/>
            }
          </RadioGroup>
        </FormControl>
        {mobileCoverImageRadio === 'filepond' ?
          <>
            {mobileCoverImage && <ImagePicker image={mobileCoverImage.src} previewOnly={true} id={mobileCoverImage.id} />}
            <FilePondUploader
              disableForm={disableForm}
              updateDisableForm={updateDisableForm}
              file={mobileCoverImage}
              id={"mobileCover"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={onMobileCoverImageChange}
              // setPreview={onSetMobileCoverPreview}
              clientId={get(selectedContentCreatorType, "client.id")}
            />
            <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.recommended_size', '450x200')}</div>
          </>
          : mobileCoverImageRadio === 'unsplash' ?
          <Unsplash imageAmount={9} onSelectImage={onSetMobileCoverImageFromUnsplash}/>
          : mobileCoverImageRadio === 'drive' ?
            <DriveFileSelector
              clientId={get(selectedContentCreatorType, "client.id")}
              onSelectFile={id => onDriveImageChange('driveMobileCoverImage', id)}
              fileType={"image"}
            />
          : mobileCoverImageRadio === 'batch' ?
            <>
              {selectedBatchMobileCoverImageFile && <ImagePicker image={selectedBatchMobileCoverImageFile.src} previewOnly={true} />}
              <FormControl fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchMobileCoverLinkChange}
                  value={selectedBatchMobileCoverLink}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "name", filter: inputValue}, {column: "is_talent", filter: isTalent ? 1 : 0}];

                    return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                      .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                  }}
                  defaultOptions
                />
              </FormControl>

              {selectedBatchMobileCoverLink &&
              <FormControl style={{marginTop: 20}} fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchMobileCoverFileChange}
                  value={selectedBatchMobileCoverImageFile}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'image'}];

                    return request.batchUpload.getAllFiles({orderBy: {column: "created_at", type: "DESC"}, filterBy}, selectedBatchCoverLink.token)
                      .then(files => files.map(file => ({value: file.id, label: file.filename, src: file.image.url})))
                  }}
                  defaultOptions
                />
              </FormControl>
              }
            </>
            : null
        }
        <br/>
        <h4>{localization.get('form.select_image_featured')}</h4>
        <FormControl>
          <RadioGroup
            name="featuredImageRadio"
            value={featuredImageRadio}
            onChange={e => onImageRadiosChange(e)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
            <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
            <FormControlLabel value="batch" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_platform')}/>
            {selectedContentCreatorType.client &&
              <FormControlLabel value="drive" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_drive')}/>
            }
          </RadioGroup>
        </FormControl>
        {featuredImageRadio === 'filepond' ?
          <>
            {featuredImage && <ImagePicker image={featuredImage.src} previewOnly={true} id={featuredImage.id} />}
            <FilePondUploader
              disableForm={disableForm}
              updateDisableForm={updateDisableForm}
              file={featuredImage}
              id={"featured"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={onFeaturedImageChange}
              setPreview={onSetFeaturedPreview}
              clientId={get(selectedContentCreatorType, "client.id")}
            />
            <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.featured_image')}</div>
          </>
          : featuredImageRadio === 'unsplash' ?
          <Unsplash imageAmount={9} onSelectImage={onSetFeaturedImageFromUnsplash}/>
            : featuredImageRadio === 'drive' ?
              <DriveFileSelector
                clientId={get(selectedContentCreatorType, "client.id")}
                onSelectFile={id => onDriveImageChange('driveFeaturedImage', id)}
                fileType={"image"}
              />
          :
            <>
              {selectedBatchFeaturedImageFile && <ImagePicker image={selectedBatchFeaturedImageFile.src} previewOnly={true} />}
              <FormControl fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchFeaturedLinkChange}
                  value={selectedBatchFeaturedLink}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "name", filter: inputValue}, {column: "is_talent", filter: isTalent ? 1 : 0}];

                    return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                      .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                  }}
                  defaultOptions
                />
              </FormControl>

              {selectedBatchFeaturedLink &&
              <FormControl style={{marginTop: 20}} fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchFeaturedFileChange}
                  value={selectedBatchFeaturedImageFile}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'image'}];

                    return request.batchUpload.getAllFiles({orderBy: {column: "created_at", type: "DESC"}, filterBy}, selectedBatchFeaturedLink.token)
                      .then(files => files.map(file => ({value: file.id, label: file.filename, src: file.image.url})))
                  }}
                  defaultOptions
                />
              </FormControl>
              }
            </>
        }
        <br/>
        <React.Fragment>
          <TextValidator
            style={{ width: "100%" }}
            id="weight"
            label={localization.get("form.weight")}
            helperText={localization.get("form.weight_helper")}
            InputProps={{ inputProps: { min: 0, max: 999 } }}
            type={"number"}
            onChange={onChange}
            name="weight"
            value={weight}
            onInput = {(e) =>{
              e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
            }}
          />
        </React.Fragment>
      </div>

      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-aditional-information', 'arrow-aditional-info')}>
        {localization.get('content_creator.aditional_info')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-aditional-info-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-aditional-info-down"}/>
      </h3>
      <br/>

      <div style={{width: "100%", display: !id ? "inline-block": "none"}} id={'content-creator-aditional-information'} >
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <SocialLinks buttonColor="dark" socialLinks={socialLinks} onSocialLinksChange={onSocialLinksChange}/>
            <br/>
          </CardContent>
        </Card>
        <br/>
        {/* <TextValidator
          style={{ width: "100%", marginBottom: 20 }}
          label={localization.get('form.observations')}
          name="observation"
          id="observation"
          value={observation}
          onChange={onChange}
          // multiline
        /> */}
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <h4>{localization.get('form.content_creator.content_supervisor')}</h4>
            <FormControl style={{marginTop: 10}} fullWidth>
            {/* <span>{localization.get('form.content_creator.select_content_supervisor')}</span> */}
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onContentSupervisorChange}
                value={contentSupervisor}
                loadOptions={inputValue => {
                  let filterBy = [{column: "name", filter: inputValue}, {column: "role", filter: 'content_supervisor'}];

                  return request.user.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                    .then(users => [{value: 0, label: localization.get('unsupervised')}].concat(users.map(user => ({value: user.id, label: user.name}))))
                }}
                defaultOptions
              />
            </FormControl>
          </CardContent>
        </Card>
        <br/>
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <h4>{localization.get('form.download_material')}</h4>
            <div style={{marginTop: 10, position:"relative", width: "100%" }}>
              <FormControl>
                <RadioGroup
                  name="hasDownloadableMaterial"
                  id="hasDownloadableMaterial"
                  value={hasDownloadableMaterial}
                  onChange={e => onHasDMaterialChange(e.target.value)}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>} label={localization.get('form.disabled')}/>
                  <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>} label={localization.get('form.active')}/>
                </RadioGroup>
              </FormControl>
            </div>
            
            {hasDownloadableMaterial == "1" && 
              <>
                <TextValidator
                  style={{ width: "100%", margin: "-5px 0 15px"}}
                  label={localization.get('form.title')}
                  name="downloadableMaterialText"
                  id="downloadableMaterialText"
                  value={downloadableMaterialText}
                  onChange={onChange}
                />
                <br/>
                <h4>{localization.get('form.download_material_radio')}</h4>

                <FormControl>
                  <RadioGroup
                    name="downloadableMaterialRadio"
                    value={downloadableMaterialRadio}
                    onChange={e => onImageRadiosChange(e)}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel style={{width: '200px'}} value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
                    <FormControlLabel style={{width: '200px'}} value="batch" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_platform')}/>
                    {selectedContentCreatorType.type === 'audiovisual' && <FormControlLabel value="link" control={<Radio color="default"/>} label={localization.get('creation.link')}/>}
                  </RadioGroup>
                </FormControl>


                {downloadableMaterialFilename &&
                  <div>
                    <span>{downloadableMaterialFilename}</span>
                    <IconButton onClick={onDownloadableMaterialDelete} style={{marginBottom: 5}}>
                      <Close/>
                    </IconButton>
                  </div>
                }
                {downloadableMaterialRadio === 'filepond' ?
                  <FilePondUploader
                    disableForm={disableForm}
                    updateDisableForm={updateDisableForm}
                    id={"downloadableMaterial"}
                    allowedTypes={["video/mp4", "video/x-m4v", "video/*", "image/*", "audio/*", "application/pdf", "application/zip", "application/rar"]}
                    onIdChange={onDownloadableMaterialChange}
                    setFileType={setDownloadableMaterialFileType}
                    clientId={get(selectedContentCreatorType, "client.id")}
                  />
                  : downloadableMaterialRadio === 'batch' ?
                    <>
                      <FormControl fullWidth>
                        <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                        <AsyncSelect
                          styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 10
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0
                          })}
                          placeholder={localization.get('conversation.filter_users')}
                          loadingMessage={() => localization.get('loading')}
                          noOptionsMessage={() => localization.get('no_options')}
                          onChange={onBatchDMaterialLinkChange}
                          value={selectedBatchDMaterialLink}
                          loadOptions={inputValue => {
                            let filterBy = [{column: "name", filter: inputValue}, {
                              column: "is_talent",
                              filter: isTalent ? 1 : 0
                            }];

                            return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                              .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                          }}
                          defaultOptions
                        />
                      </FormControl>

                      {selectedBatchDMaterialLink &&
                      <FormControl style={{marginTop: 20}} fullWidth>
                        <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                        <AsyncSelect
                          styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 10
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0
                          })}
                          placeholder={localization.get('conversation.filter_users')}
                          loadingMessage={() => localization.get('loading')}
                          noOptionsMessage={() => localization.get('no_options')}
                          onChange={onBatchDMaterialFileChange}
                          value={selectedBatchDMaterialFile}
                          loadOptions={inputValue => {
                            let filterBy = [{column: "filename", filter: inputValue}];

                            return request.batchUpload.getAllFiles({
                              orderBy: {column: "created_at", type: "DESC"},
                              filterBy
                            }, selectedBatchDMaterialLink.token)
                              .then(files => files.map(file => ({value: file.id, label: file.filename})))
                          }}
                          defaultOptions
                        />
                      </FormControl>
                      }
                    </>
                  :
                    <TextValidator
                      style={{width: "100%", marginBottom: "20px"}}
                      id="downloadableMaterialLink"
                      label={localization.get('form.link')}
                      name="downloadableMaterialLink"
                      value={downloadableMaterialLink}
                      onChange={onChange}
                      disabled={disabled}
                    />
                }
              </>
            }
          </CardContent>
        </Card>
        <br/>
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <h4>{localization.get("form.buy_button.title")}</h4>
            <div style={{marginTop: 10, position:"relative", width: "100%" }}>
              <FormControl>
                <RadioGroup
                  name="hasBuyButton"
                  id="hasBuyButton"
                  value={hasBuyButton}
                  onChange={e => onHasBuyButtonChange(e.target.value)}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="0" disabled={products.length} control={<Radio color="default"/>} label={localization.get('form.disabled')}/>
                  <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>} label={localization.get('form.personalized')}/>
                  <FormControlLabel style={{width: '200px'}} value="2" control={<Radio color="default"/>} label={localization.get('form.automatic')}/>
                </RadioGroup>
              </FormControl>
            </div>
            
            {hasBuyButton == "1" && 
              <>
                <TextValidator
                  style={{ width: "100%", margin: "-5px 0 15px"}}
                  label={localization.get('form.title')}
                  name="buyButtonText"
                  id="buyButtonText"
                  value={buyButtonText}
                  onChange={onChange}
                />
                <br/>
                <h4>{localization.get("form.buy_button.choose_product")}</h4>
                {buyButtonProduct ? 
                  <div style={{marginTop: 10, position:"relative", width: "100%", display: "flex", flexDirection: "row" ,justifyContent: "space-between", alignItems: "center"}}>
                    <div>{localization.get("form.buy_button.chosen_product")}: {getProductString(buyButtonProduct)}</div>
                    <IconButton onClick={() => onChange({target: {id: "buyButtonProduct", value: ''}})}>
                      <Close/>
                    </IconButton>
                  </div>
                :
                  <div style={{marginTop: 10, position:"relative", width: "100%", display: "flex", flexDirection: "row" ,justifyContent: "space-between"}}>
                    <div style={{width: "40%"}}>
                      <FormControl fullWidth>
                        <span>{localization.get('title.channels')}</span>
                        <AsyncSelect
                          styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 10
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0
                          })}
                          placeholder={localization.get('title.channels')}
                          loadingMessage={() => localization.get('loading')}
                          noOptionsMessage={() => localization.get('no_options')}
                          onChange={(value) => onChange({target: {id: "channel", value: value}})}
                          value={channel}
                          defaultOptions
                          loadOptions={async inputValue => {
                            let filterBy = [];
                            
                            if (!!selectedContentCreatorType.client) {
                              filterBy = filterBy.concat([{column: "client_id", filter: get(selectedContentCreatorType, "client.id")}])
                            }

                            if(inputValue) {
                              filterBy.push({column: "name", filter: inputValue})
                            }else {
                              filterBy.push({column: "per_page", filter: 15})
                            }

                            return request.channel.getAll({filterBy})
                              .then(res => {
                                let results = res['data'] ? res['data'] : res;
                                return results.map(content => ({
                                ...content,
                                value: content.id,
                                label: `${content.name}`
                              }))})
                          }}
                        />
                      </FormControl>
                    </div>
                    {channel &&
                      <div style={{width: "40%"}}>
                        <FormControl fullWidth>
                          <span>{localization.get('products')}</span>
                          <AsyncSelect
                            styles={{
                              menu: base => ({
                                ...base,
                                zIndex: 10
                              })
                            }}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0
                            })}
                            placeholder={localization.get('products')}
                            loadingMessage={() => localization.get('loading')}
                            noOptionsMessage={() => localization.get('no_options')}
                            onChange={(value) => onChange({target: {id: "selectedBuyButtonProduct", value: value}})}
                            value={selectedBuyButtonProduct}
                            defaultOptions
                            loadOptions={async inputValue => {
                              let filterBy = [];

                              if (inputValue) {
                                filterBy.push({column: "name", filter: inputValue})
                              } else {
                                filterBy.push({column: "per_page", filter: 15})
                              }

                              return request.products.getAll(channel.id, {filterBy})
                                .then(res => {
                                  let results = res['data'] ? res['data'] : res;
                                  return results.map(product => {
                                    
                                    return {
                                      ...product,
                                      value: product.id,
                                      label: `${getProductString(product)}`
                                    }
                                  });
                                })
                            }}
                          />
                        </FormControl>
                      </div>
                    }
                  </div>
                }
                {/* <div style={{marginTop: 10, position:"relative", width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={buyButtonShowInSocials}
                        onChange={() => onChange({target: {id: "buyButtonShowInSocials", value: !buyButtonShowInSocials}})}
                      />
                    }
                    label={localization.get('form.buy_button.show_in_socials')}
                  />
                </div> */}
              </>
            }
            {hasBuyButton == "2" &&
              <div >
                {products.map(product =>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 15}}>
                    <div>{localization.get('channel')}: {product.channel.name}</div>
                    <div>{localization.get('type')}: {(product.content_type === "App\\ContentCreator") ? localization.get('contentCreators') : (product.content_type === "App\\ContentCreation") ? localization.get('table.share_material') :  localization.get('table.content_packs')}</div>
                    {product.prices.map(price =>  <div>{price.price} {price.currency}</div>)}
                  </div>
                )}
              </div>
            }
          </CardContent>
        </Card>
        <br/>
        <Card style={{overflow: 'visible'}}>
          <CardContent>
          <h4>{localization.get("form.additional_info_size")}</h4>
            <div style={{marginTop: 10, position:"relative", width: "100%" }}>
              <FormControl>
                <RadioGroup
                  name="additionInfoSize"
                  id="additionInfoSize"
                  value={additionInfoSize}
                  onChange={e => onChange({target: {id: "additionInfoSize", value: e.target.value}})}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="sm" control={<Radio color="default"/>} label={localization.get('form.size.sm')}/>
                  <FormControlLabel style={{width: '200px'}} value="md" control={<Radio color="default"/>} label={localization.get('form.size.md')}/>
                  <FormControlLabel style={{width: '200px'}} value="lg" control={<Radio color="default"/>} label={localization.get('form.size.lg')}/>
                </RadioGroup>
              </FormControl>
            </div>
          </CardContent>
        </Card>
        <br/>
      </div>
      
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-availability', 'arrow-availability')}>
        {localization.get('content_creator.availability')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-availability-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-availability-down"}/>
      </h3>
      <br/>

      <div style={{width: "100%", display: !id ? "inline-block": "none"}} id={'content-creator-availability'} >
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <h4>{localization.get('form.availability')}</h4>
            <div style={{marginTop: 10}}>
              <FormControl>
                <RadioGroup
                  name="active"
                  id="active"
                  value={active}
                  onChange={e => onActiveChange(e.target.value)}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>} label={localization.get('form.allowed_content')}/>
                  <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>} label={localization.get('form.disallowed_content')}/>
                </RadioGroup>
              </FormControl>
            </div>
            {active === "1" && !isTalent &&
            <>
              <h4>{localization.get('form.region')} {allowedRegions.length > 0 && <span style={{fontSize: 12}}>{localization.get('continents.country.selected', allowedRegions.length)}</span>}</h4>
              <FormControl>
                <RadioGroup
                  name="hasRegionBlock"
                  value={hasRegionBlock}
                  onChange={e => onHasRegionBlockChange(e.target.value)}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="false" control={<Radio color="default"/>} label={localization.get('form.all_world')}/>
                  <FormControlLabel style={{width: '200px'}} value="true" control={<Radio color="default"/>} label={localization.get('form.has_region_block')}/>
                </RadioGroup>
              </FormControl>

              {hasRegionBlock === "true" &&
                <RegionSelector selectedCountries={allowedRegions} onChange={onChangeAllowedRegions} />
              }

              <h4>{localization.get('form.content_duration')}</h4>
              <FormControl>
                <RadioGroup
                  name="isPermanentContent"
                  value={isPermanentContent}
                  onChange={e => onIsPermanentContentChange(e.target.value)}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>} label={localization.get('form.permanent_content')}/>
                  <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>} label={localization.get('form.not_permanent_content')}/>
                </RadioGroup>
              </FormControl>
              {isPermanentContent === "0" &&
              <div style={{width: "50%", display: "flex", flexDirection: "column", marginTop: 10}}>
                <h4>{localization.get('form.range_disponibility')}</h4>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    style={{marginBottom: 15}}
                    autoOk
                    autoSubmit
                    disablePast
                    ampm={false}
                    format={"dd/MM/yyyy HH:mm a"}
                    dateRangeIcon={<Event/>}
                    id={"releaseDate"}
                    defaultValue={releaseDate}
                    helperText={localization.get("form.helper_datepicker_content_active")}
                    label={localization.get('form.release_date')}
                    value={releaseDate}
                    onChange={e => {
                      onChange({
                        target: {
                          id: "releaseDate",
                          value: e,
                        }
                      })
                    }}
                  />
                  <ErrorMessage name="startTime" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </MuiPickersUtilsProvider>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={!hasExpiredDate}
                      onChange={() => onHasExpiredDateChange(!hasExpiredDate)}
                    />
                  }
                  label={localization.get('has_expired_date')}
                />
                {hasExpiredDate &&
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    autoOk
                    keyboard
                    clearable
                    onlyCalendar
                    id={"expireDate"}
                    disablePast
                    defaultValue={expireDate}
                    format={"dd/MM/yyyy HH:mm a"}
                    disabled={!hasExpiredDate}
                    helperText={localization.get("form.helper_datepicker_content_disactive")}
                    label={localization.get('form.expire_date')}
                    value={expireDate}
                    onChange={e => {
                      onChange({
                        target: {
                          id: "expireDate",
                          value: e,
                        }
                      })
                    }}
                  />
                </MuiPickersUtilsProvider>
                }
              </div>
              }
            </>
            }
            {get(selectedContentCreatorType, 'client.has_access_level') &&
                  <FormControl>
                    <h4>{localization.get('form.access_level')}</h4>
                    <RadioGroup
                      name="accessLevel"
                      id="accessLevel"
                      value={accessLevel}
                      onChange={e => onChange({target: {id: "accessLevel", value: e.target.value}})}
                      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel
                          value={"0"}
                          control={<Radio color="default"/>}
                          label={localization.get('smart_search.none')}
                        />
                      {["1","2","3","4","5"].map(level =>
                        <FormControlLabel
                          value={level}
                          control={<Radio color="default"/>}
                          label={level}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                }
          </CardContent>
        </Card>
        
        {active === "1" &&
          <>
            <br/>
            <Card style={{overflow: 'visible'}}>
              <CardContent>
                <h4 style={{display: 'flex', alignItems: 'center'}}>
                  {localization.get('form.require_login')}
                  <div style={{cursor: "pointer", marginLeft: '10px'}} onClick={updateHelper}>
                    <Help style={{fontSize: 15, color: "rgba(4, 175, 196, 1) "}}/>
                  </div>
                </h4>
                <div style={{marginTop: 10, position:"relative", width: "100%" }}>
                  <FormControl>
                    <RadioGroup
                      name="requireLogin"
                      id="requireLogin"
                      value={requireLogin}
                      onChange={e => onRequireLoginChange(e.target.value)}
                      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>} label={localization.get('form.disabled')}/>
                      <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>} label={localization.get('form.active')}/>
                    </RadioGroup>
                  </FormControl>
                </div>
              </CardContent>
            </Card>
          </>
        }
      </div>
      
      {
        tagGroups && tagGroups.length > 0 &&
        (
          <>
            <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-tag-edition', 'arrow-tag-edition')}>
              {localization.get('content_creator.tags')}
              <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-tag-edition-up"}/>
              <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-tag-edition-down"}/>
            </h3>
            <br/>
            <div style={{width: '100%', display: !id ? "inline-block": "none"}} id={'content-creator-tag-edition'}>
              <h4>{localization.get("content")}</h4>
              <TagSelection cardView data={tagGroups} onSelectedTagsChanged={onSelectedTagsChanged} disabled={disabled} showFilter useParents />
            </div>
          </>
        )
      }

      {id &&
        <>
          {selectedContentCreatorType.type === 'audiovisual' &&
          <>
            <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-material', 'arrow-material')}>
              {localization.get( 'content_creator.material')}
              <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-material-up"}/>
              <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-material-down"}/>
            </h3>
            <br/>

            <div style={{width: "100%", display: !id ? "block": "none"}} id={'content-creator-material'} >
              {contentCreations && selectedContentCreatorType.type === 'audiovisual' &&
                <React.Fragment>
                  <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <div >
                      {contentCreationsToDelete.length > 1 && 
                        <>
                          <Tooltip title={localization.get('view.content_creation.delete_many')} placement='top'>
                            <Button
                              onClick={onDeleteManyMaterials}
                              style={{height: 40, width: 40, backgroundColor: "#1f1f1f", float: 'right'}}
                              variant="fab" color="primary" aria-label="Delete"
                            >
                              <Delete/>
                            </Button>
                          </Tooltip>
                          <Tooltip title={localization.get('form.rename')} placement='top'>
                            <Button
                              onClick={openRenameDialog}
                              style={{height: 40, width: 40, backgroundColor: "#1f1f1f", float: 'right'}}
                              variant="fab" color="primary" aria-label="Rename"
                            >
                              <Cached />
                            </Button>
                          </Tooltip>
                        </>
                      }
                    </div>
                    {uploadingMaterials &&
                      <div style={{display: "flex", alignItems: "center"}}>
                        <span>{localization.get('content_creation.uploading')}</span>
                        <img style={{width: 50, height: 50, marginLeft: 15}} src={LoadingSpinner} alt="Loading logo"/>
                      </div>
                    }
                    <div>
                    <Tooltip
                      title={localization.get('view.content_creation.create')}
                      placement='top'>
                      <Button
                        onClick={() => browserHistory.push(isTalent ? `/panel/talents/content_creations/create?contentCreator=${id}` : `/panel/audiovisual/content_creations/create?contentCreator=${id}`)}
                        style={{ height: 40, width: 40, backgroundColor: "#1f1f1f", float: 'right' }}
                        variant="fab" color="primary" aria-label="Add"
                      >
                        <Add />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={localization.get('content_creator.create_materials_by_link')}
                      placement='top'>
                      <Button
                        onClick={openDialog}
                        style={{ height: 40, width: 40, marginRight: 15, backgroundColor: "#1f1f1f", float: 'right' }}
                        variant="fab" color="primary" aria-label="Add"
                      >
                        <ExposurePlus1 />
                      </Button>
                    </Tooltip>
                      {contentCreations.length > 1 &&
                        <Tooltip
                          title={localization.get('form.retag')}
                          placement='top'>
                          <Button
                            onClick={openCloseReTagMaterialModal}
                            style={{height: 40, width: 40, marginRight: 15, backgroundColor: "#1f1f1f", float: 'right'}}
                            variant="fab" color="primary" aria-label="Add"
                          >
                            <Style/>
                          </Button>
                        </Tooltip>
                      }
                    </div>
                  </div>
                  {(navigator.platform.indexOf('Win') > -1) ? 
                  <ServerSidePaginationTable
                    columns={columns}
                    showPaginationBottom
                    readAndWriteUrl={false}
                    requestTableData={pagination => {
                      let paginationConfig = {...pagination, perPage: 35}

                      return request.contentCreation.getAllByCreator(id,  "include=video.thumbnail;image;audio;binary;embededContent&allCreations=true", paginationConfig);
                    }}
                  />:
                  <InfiniteScroll
                    style={{display: "flex", width: "100%", flexDirection: "column"}}
                    dataLength={contentCreations.length}
                    next={() => {
                      return requestContentCreations({page: page++, clearResults: false})
                    }}
                    hasMore={page < lastPage}
                    loader={<div
                      style={{height: "70px", paddingTop: 20, width: "100%", display: "flex", justifyContent: "center", alignContent: "center"}}
                    >
                      <img style={{width: 50, height: 50}} src={LoadingSpinner} alt="Loading logo"/>
                    </div>}
                  >
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                      <ClientSidePaginationTable
                        style={{width: "100%"}}
                        showPagination={false}
                        perPage={999}
                        onFilter={(filter, row) => {
                          if (row[filter.id]) {
                            return row[filter.id].props.title.toLowerCase().includes(filter.value.toLowerCase());
                          }
                          return false;
                        }}
                        columns={columns}
                        data={contentCreations}
                      />
                    </div>
                  </InfiniteScroll>       
                  }
                </React.Fragment>
              }
            </div>
          </>
          }
        </>
      }

      {(selectedContentCreatorType || id) &&
        <>
          <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-related', 'arrow-related')}>
            {localization.get(selectedContentCreatorType.type === 'editorial' ? 'content_creator.note_footer' : 'content_creator.related' )}
            <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-related-up"}/>
            <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-related-down"}/>
          </h3>
          <br/>

          <div style={{width: "100%", display: !id ? "block": "none"}} id={'content-creator-related'} >
            <div style={{marginBottom: 20}}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={hasSuggestedCarousel}
                      onChange={() => {
                          onSuggestedCarouselRadioChange(hasSuggestedCarousel ? null : "suggested")
                          onSuggestedCarouselChange(!hasSuggestedCarousel)
                      }}
                    />
                  }
                  label={localization.get(selectedContentCreatorType.type === 'editorial' ?
                    'form.has_suggested_carousel' : 'form.has_suggested_carousel_talent')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={showTags}
                      onChange={() => onShowTagsChange(!showTags)}
                    />
                  }
                  label={localization.get(selectedContentCreatorType.type === 'editorial' ?
                    'form.has_note_tags' : 'form.has_content_tags')}
                />
              </div>

              {hasSuggestedCarousel &&
                <>
                  <FormControl>
                    <RadioGroup
                      name="suggestedCarouselRadio"
                      value={suggestedCarouselRadio}
                      onChange={e => onSuggestedCarouselRadioChange(e.target.value)}
                      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel value="list" control={<Radio color="default"/>}
                                        label={localization.get('form.select_list')}/>
                      {selectedContentCreatorType.type === 'editorial' &&
                        <FormControlLabel value="suggested" control={<Radio color="default"/>}
                                          label={localization.get('form.suggested_by_platform')}/>
                      }
                      <FormControlLabel value="suggestedTag" control={<Radio color="default"/>}
                                        label={localization.get('form.suggested_by_tags')}/>
                      <FormControlLabel value="suggestedContentCreators" control={<Radio color="default"/>}
                                        label={localization.get('form.suggested_custom')}/>
                    </RadioGroup>
                  </FormControl>

                  {suggestedCarouselRadio === 'list' &&
                    <AsyncSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={onSuggestedCarouselPlaylistChange}
                      value={suggestedCarouselPlaylist}
                      loadOptions={inputValue => {
                        let catalogsIds = selectedCatalogs.map(catalog => catalog.value)

                        let filterBy = [{column: "title", filter: inputValue},
                          {column: 'channel_lists', filter: true},
                          {column: 'for_creators', filter: true},
                          {column: 'catalogs_ids', filter: catalogsIds}];

                        return request.playlist.getAll({orderBy: {column: "title", type: "ASC"}, filterBy})
                          .then(lists => lists.map(list => ({value: list.id, label: list.title})))
                      }}
                      defaultOptions
                    />
                  }

                  {suggestedCarouselRadio === 'suggestedTag' &&
                    <AsyncSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={suggestedTagGroupChange}
                      value={suggestedTagGroup}
                      loadOptions={(inputValue, callback) => {
                        callback(tagGroups
                          .map(tg => ({...tg, value: tg.id, label: tg.name}))
                          .filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                      }}
                      defaultOptions
                    />
                  }

                  {suggestedCarouselRadio === 'suggestedContentCreators' &&
                    <AsyncSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      value={suggestedContentCreators}
                      isMulti
                      onChange={suggestedContentCreatorsChange}
                      loadOptions={inputValue =>
                        request.contentCreator
                          .getAll({ filterBy: [{ column: "title", filter: inputValue }, { column: "client_id", filter: get(selectedContentCreatorType, "client.id") }] })
                          .then(res => res.map(contentCreator => ({ value: contentCreator.id, label: `${contentCreator.name} - (${contentCreator.type.name})` })))
                      }
                    />
                  }
                  <br/>
                  <FormControl >
                    <div>{localization.get('form.view_type')}</div>
                    <RadioGroup
                      id="suggestedView"
                      name="suggestedView"
                      value={suggestedView}
                      onChange={e => onImageRadiosChange(e)}
                      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel value="16:9" control={<Radio color="default"/>} label={"16:9"}/>
                      <FormControlLabel value="news_up" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.news_top')}/>
                      <FormControlLabel value="news_down" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.news_bottom')}/>
                      <FormControlLabel value="square" control={<Radio color="default"/>} label={localization.get('shape.square')}/>
                      <FormControlLabel value="round" control={<Radio color="default"/>} label={localization.get('shape.round')}/>
                      <FormControlLabel value="rectangle" control={<Radio color="default"/>} label={localization.get('shape.rectangle')}/>
                    </RadioGroup>
                  </FormControl>

                  <FormControlLabel
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={suggestedViewMultipleRow}
                        onChange={() => onChange({target: {value: !suggestedViewMultipleRow, id: 'suggestedViewMultipleRow'}})}
                      />
                    }
                    label={localization.get('form.suggested_in_two_rows')}
                  />
                </>
              }
            </div>
          </div>
        </>
      }
      {(selectedContentCreatorType && selectedContentCreatorType.type !== 'editorial') &&
        <>
          <h3 style={{
            background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",
            position: "relative",
            borderRadius: 5,
            padding: 5,
            cursor: "pointer"
          }} onClick={() => hideDivs('content-creator-preferences', 'arrow-preferences')}>
            {localization.get('content_creator.preferences')}
            <ExpandLess style={{display: !id ? "block" : "none", position: "absolute", right: 15, top: 12}}
                        id={"arrow-preferences-up"}/>
            <ExpandMore style={{display: id ? "block" : "none", position: "absolute", right: 15, top: 12}}
                        id={"arrow-preferences-down"}/>
          </h3>
          <br/>

          <div style={{width: "100%", display: !id ? "block" : "none"}} id={'content-creator-preferences'}>
            <div style={{marginBottom: 20, width: "100%", display: 'flex', flexDirection: 'column'}}>
              {id && selectedContentCreatorType.type === 'audiovisual' &&
                <>  
                  <Card style={{overflow: 'visible'}}>
                    <CardContent>
                      <span>{localization.get('form.content_creator.view_type')}</span>
                      <br/>
                      <RadioGroup
                        name="viewType"
                        value={viewType}
                        onChange={e => onChangeViewType(e)}
                        style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                      >
                        <FormControlLabel value={"inherit"} control={<Radio color="default"/>} label={localization.get('form.inherit_from_channel')}/>
                        <FormControlLabel value={"carousel"} control={<Radio color="default"/>} label={localization.get('carousel')}/>
                        <FormControlLabel value={"grid"} control={<Radio color="default"/>} label={localization.get('form.grid')}/>
                      </RadioGroup>
                      <br/>
                      <span>{localization.get('form.content_creator.view_type_mobile')}</span>
                      <br/>
                      <RadioGroup
                        name="viewTypeMobile"
                        value={viewTypeMobile}
                        onChange={e => onChangeViewTypeMobile(e)}
                        style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                      >
                        <FormControlLabel value={"inherit"} control={<Radio color="default"/>} label={localization.get('form.inherit_from_channel')}/>
                        <FormControlLabel value={"carousel"} control={<Radio color="default"/>} label={localization.get('carousel')}/>
                        <FormControlLabel value={"grid"} control={<Radio color="default"/>} label={localization.get('form.grid')}/>
                      </RadioGroup>
                    </CardContent>
                  </Card>
                  <br/>
                </>
              }
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={hasPreferences}
                      onChange={() => onHasPreferencesChange(!hasPreferences)}
                    />
                  }
                  label={localization.get('content_creator.has_preferences')}
                />

                <IconButton
                  title={localization.get('content_creator.restore_default_styles')}
                  onClick={setDefaultStyles}
                >
                  <Restore style={{
                    border: "2px solid",
                    borderRadius: "50%",
                    width: 30,
                    height: 30,
                  }}/>
                </IconButton>
              </div>
              <br/>
              {hasPreferences &&
                <>
                  <FormControl style={{float: "left", width: "16.6%"}}>
                    <Tooltip title={localization.get('content_creator.title_color')} placement='top'>
                      <label style={{marginBottom: 10}}> {localization.get('content_creator.title_color')} </label>
                    </Tooltip>
                    <ColorPicker
                      id={'titleColor'}
                      handleColorChange={color =>
                        onChange({
                          target: {
                            id: "titleColor",
                            value: color
                          }
                        })
                      }
                      startColor={titleColor}
                      disableAlpha={true}
                    />
                  </FormControl>
                  <FormControl style={{float: "left", width: "16.6%"}}>
                    <Tooltip title={localization.get('content_creator.description_color')} placement='top'>
                      <label
                        style={{marginBottom: 10}}> {localization.get('content_creator.description_color')} </label>
                    </Tooltip>
                    <ColorPicker
                      id={'descriptionColor'}
                      handleColorChange={color =>
                        onChange({
                          target: {
                            id: "descriptionColor",
                            value: color
                          }
                        })
                      }
                      startColor={descriptionColor}
                      disableAlpha={true}
                    />
                  </FormControl>
                  <FormControl style={{float: "left", width: "16.6%"}}>
                    <Tooltip title={localization.get('content_creator.creation_color')} placement='top'>
                      <label style={{marginBottom: 10}}> {localization.get('content_creator.creation_color')} </label>
                    </Tooltip>
                    <ColorPicker
                      id={'creationColor'}
                      handleColorChange={color =>
                        onChange({
                          target: {
                            id: "creationColor",
                            value: color
                          }
                        })
                      }
                      startColor={creationColor}
                      disableAlpha={true}
                    />
                  </FormControl>
                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get("content_creator.container_background")}</h4>
                    <div style={{display: "flex", flexDirection: "row"}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{color: "#4cc2d5"}}
                            checked={useBackgroundColor}
                            onChange={() => onUseBackgroundColorChange(!useBackgroundColor)}
                          />
                        }
                        label={localization.get('content_creator.use_background_color')}
                      />

                      {useBackgroundColor && 
                      <>
                        <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
                          <Tooltip title={localization.get('content_creator.background_color')} placement='top'>
                            <label style={{marginBottom: 10}}> {localization.get('content_creator.background_color')} </label>
                          </Tooltip>
                          <ColorPicker
                            id={'backgroundColor'}
                            handleColorChange={color =>
                              onChange({
                                target: {
                                  id: "backgroundColor",
                                  value: color
                                }
                              })
                            }
                            startColor={backgroundColor}
                            disableAlpha={true}
                          />
                        </FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{color: "#4cc2d5"}}
                              checked={useBackgroundGradient}
                              onChange={() => onUseBackgroundGradientChange(!useBackgroundGradient)}
                            />
                          }
                          label={localization.get('content_creator.use_background_gradient')}
                        />
                      </>
                      }
                    </div>
                  </div>
                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get('Caja traslucida materiales')}</h4>
                    <FormControl>
                      <RadioGroup
                        name="showBox"
                        value={showBox}
                        id="showBox"
                        onChange={(e) => {
                          if (e.target.value != showBox && e.target.value  ==="show") onChange({target: {id: 'cardColor', value: "rgba(255, 255, 255, 0.4)"}})
                          if (e.target.value != showBox && e.target.value  ==="hide") onChange({target: {id: 'cardColor', value: "rgba(255, 255, 255, 0)"}})
                          onChange({target: {id: 'showBox', value: e.target.value}})
                        }}
                        style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                      >
                        <FormControlLabel value="show" control={<Radio color="default"/>}
                                          label={localization.get('show')}/>
                        <FormControlLabel value="hide" control={<Radio color="default"/>}
                                          label={localization.get('hide')}/>
                      </RadioGroup>
                    </FormControl>
                    {showBox === 'show' &&
                      <div style={{float: "left", width: "100%"}}>
                        <FormControl style={{float: "left", width: "16.6%"}}>
                          <Tooltip title={localization.get('content_creator.card_color')} placement='top'>
                            <label style={{marginBottom: 10}}> {localization.get('content_creator.card_color')} </label>
                          </Tooltip>
                          <ColorPicker
                            id={'cardColor'}
                            handleColorChange={color =>
                              onChange({
                                target: {
                                  id: "cardColor",
                                  value: color
                                }
                              })
                            }
                            startColor={cardColor}
                          />
                        </FormControl>
                        <div style={{float: "left", width: "33%", paddingRight: 30}}>
                          <span style={{color: "#97877f"}}>{localization.get('content_creator.card_shape')}</span>
                          <Select
                            styles={{menu: base => ({...base, zIndex: 10})}}
                            value={cardShape}
                            onChange={value =>
                              onChange({
                                target: {
                                  id: "cardShape",
                                  value: value
                                }
                              })
                            }
                            options={[
                              {value: "rounded", label: localization.get('rounded')},
                              {value: "square", label: localization.get('square')},
                            ]}
                          />
                        </div>
                      </div>
                    }
                  </div>

                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get('content_creator.carousel_border')}</h4>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <FormControl>
                        <RadioGroup
                          name="showCreationBorder"
                          value={showCreationBorder}
                          id="showCreationBorder"
                          onChange={(e) => {
                            onChange({target: {id: 'showCreationBorder', value: e.target.value}})
                          }}
                          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                        >
                          <FormControlLabel value="hide" control={<Radio color="default"/>}
                                            label={localization.get('hide')}/>
                          <FormControlLabel value="show" control={<Radio color="default"/>}
                                            label={localization.get('show')}/>
                        </RadioGroup>
                      </FormControl>
                      {showCreationBorder == 'show' &&
                        <FormControl style={{marginLeft: 10, marginRight: 20}}>
                          <Tooltip title={localization.get('content_creator.carousel_bg_color')} placement='top'>
                            <label style={{marginBottom: 10}}> {localization.get('content_creator.carousel_bg_color')} </label>
                          </Tooltip>
                          <ColorPicker
                            id={'creationCarouselBackgroundColor'}
                            handleColorChange={color =>
                              onChange({
                                target: {
                                  id: "creationCarouselBackgroundColor",
                                  value: color
                                }
                              })
                            }
                            startColor={creationCarouselBackgroundColor}
                            disableAlpha={true}
                          />
                        </FormControl>
                      }
                    </div>
                  </div>

                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get('content_creator.creation_title_position')}</h4>
                    <FormControl>
                      <RadioGroup
                        name="creationInverted"
                        value={creationInverted}
                        id="creationInverted"
                        onChange={(e) => onChange({target: {id: 'creationInverted', value: e.target.value}})}
                        style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                      >
                        <FormControlLabel value="up" control={<Radio color="default"/>}
                                          label={localization.get('content_creator.material_up')}/>
                        <FormControlLabel value="down" control={<Radio color="default"/>}
                                          label={localization.get('content_creator.material_down')}/>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get('content_creator.event_modal_position')}</h4>
                    <FormControl>
                      <RadioGroup
                        name="contentCreatorEventsPosition"
                        value={eventsPosition}
                        id="eventsPosition"
                        onChange={(e) => onChange({target: {id: 'eventsPosition', value: e.target.value}})}
                        style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                        >
                        <FormControlLabel value="top" control={<Radio color="default"/>}
                                          label={localization.get('content_creator.top')}/>
                        <FormControlLabel value="bottom" control={<Radio color="default"/>}
                                          label={localization.get('content_creator.bottom')}/>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get('content_creator.format_subtitles')}</h4>
                    <div style={{float: "left", width: "100%"}}>
                      <TextValidator
                        style={{ marginBottom: "20px", marginTop: 10, float:"left" , width: "33%", paddingRight: 30}}
                        id="subtitleFontSize"
                        label={localization.get('content_creator.subtitle_font_size')+"(px)"}
                        name="subtitleFontSize"
                        value={subtitleFontSize}
                        type={"number"}
                        onChange={onChange}
                      />
                      <TextValidator
                        style={{ marginBottom: "20px", marginTop: 10, float:"left" , width: "33%", paddingRight: 30}}
                        id="subtitleMargin"
                        label={localization.get('content_creator.subtitle_margin')+"(px)"}
                        name="subtitleMargin"
                        value={subtitleMargin}
                        type={"number"}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div style={{float: "left", width: "100%"}}>
                    <h4>{localization.get('content_creator.buy_button')}</h4>
                    <div style={{float: "left", width: "100%"}}>
                      <div style={{float: "left", width: "33%", paddingRight: 30}}>
                        <span style={{color: "#97877f"}}>{localization.get('content_creator.buy_button.position')}</span>
                        <Select
                          styles={{menu: base => ({...base, zIndex: 10})}}
                          value={buyButtonPosition}
                          onChange={value =>
                            onChange({
                              target: {
                                id: "buyButtonPosition",
                                value: value
                              }
                            })
                          }
                          options={[
                            {value: "both", label: localization.get('content_creator.buy_button.both')},
                            {value: "description", label: localization.get('content_creator.buy_button.description')},
                            {value: "social", label: localization.get('content_creator.buy_button.social')}
                          ]}
                        />
                      </div>
                      <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
                        <Tooltip title={localization.get('content_creator.buy_button.bg_color')} placement='top'>
                          <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.bg_color')} </label>
                        </Tooltip>
                        <ColorPicker
                          id={'buyButtonBackgroundColor'}
                          handleColorChange={color =>
                            onChange({
                              target: {
                                id: "buyButtonBackgroundColor",
                                value: color
                              }
                            })
                          }
                          startColor={buyButtonBackgroundColor}
                          disableAlpha={true}
                        />
                      </FormControl>
                      <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
                        <Tooltip title={localization.get('content_creator.buy_button.font_color')} placement='top'>
                          <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.font_color')} </label>
                        </Tooltip>
                        <ColorPicker
                          id={'buyButtonFontColor'}
                          handleColorChange={color =>
                            onChange({
                              target: {
                                id: "buyButtonFontColor",
                                value: color
                              }
                            })
                          }
                          startColor={buyButtonFontColor}
                          disableAlpha={true}
                        />
                      </FormControl>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </>
      }

      {
        isEvent == "1" &&
        (
          <>
            <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-event-edition', 'arrow-event-edition')}>
              {localization.get('content_creator.event_edition')}
              <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-event-edition-up"}/>
              <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-event-edition-down"}/>
            </h3>
            <br/>
            <div style={{width: '100%', display: !id ? "inline-block": "none"}} id={'content-creator-event-edition'}>
              <RecurrentEvents events={eventDates} onEventsChange={onEventDatesChange}></RecurrentEvents>
            </div>
          </>
        )
      }
      {domainChannels.length > 0 &&
        <>
          <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-seo-configuration', 'arrow-seo')}>
            {localization.get('content_creator.seo_config')}
            <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-seo-up"}/>
            <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-seo-down"}/>
          </h3>
          <br/>

          <div style={{width: "100%", display: !id ? "inline-block": "none"}} id={'content-creator-seo-configuration'} >
            <p>{localization.get('canonical.helper')}</p>
            <br/>

            <FormControl fullWidth>
              <span>{localization.get('form.select_canonical')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  }),
                  control: (base, state) => ({
                    ...base,
                    // state.isFocused can display different borderColor if you need it
                    borderColor: state.isFocused ?
                      '#ddd' : canonicalIsCompleted ?
                        '#ddd' : 'red',
                    // overwrittes hover style
                    '&:hover': {
                      borderColor: state.isFocused ?
                        '#ddd' : canonicalIsCompleted ?
                          '#ddd' : 'red'
                    }
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                id={"canonicalChannel"}
                placeholder={localization.get('form.domains')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={val => onChange({target: {value: val, id: "canonicalChannel"}})}
                value={canonicalChannel}
                loadOptions={(inputValue, callback) =>
                  callback(domainChannels.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                }
                defaultOptions={domainChannels}
              />
              <br/>
            </FormControl>
          </div>
        </>
      }
    </div>
  )
};

const buttons = (contentCreation, handleContentCreationRemoved, isTalent, onChange) => {
  const {
    id,
    title,
    content_creator_id
  } = contentCreation;

  const buttonBuilder = new TableButtonsBuilder();

  if (contentCreation.custom && contentCreation.custom.length > 0 ){
    buttonBuilder.withOther(
      <TableButton title={localization.get('info')} onClick={() =>  onChange({target: {id: "logs", value: contentCreation.custom}})}>
        <Info />
      </TableButton>
    )
  }

  buttonBuilder.withEdit(isTalent ? `/panel/talents/content_creations/${id}/edit` : `/panel/audiovisual/content_creations/${id}/edit`);
  buttonBuilder.withOther(
    <TableButton
      title={localization.get('preview')}
      onClick={() =>
        GlobalConfirmationDialog.showDelete({
          dialogTitle: `${localization.get('tooltip.delete')} ${title}`,
          content: localization.get('confirmation_dialog.delete_content_message'),
          doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
          elementName: localization.get("delete"),
          deleteRequest: () => request.contentCreation.delete(id)
            .then(() => handleContentCreationRemoved(id)),
          doubleConfirmation: true,
          auxiliaryInformation: "",
        })
      }
    >
      <Delete />
    </TableButton>
  )

  return buttonBuilder.build()
};


const makeReorderButtonComponent = (selected, original, onReorder) => ({ tooltipKey, Icon, nextIndex }) => (
    <Tooltip title={localization.get(tooltipKey)}>
      <Icon style={{ cursor: 'pointer' }} onClick={() => {
        const index = selected.indexOf(original);
        const reorderedSelected = arrayMove(selected, index, nextIndex(index));
        onReorder(reorderedSelected)
      }} />
    </Tooltip>
  );

const getContentCreationType = contentCreation => {
  if(contentCreation.video) {
    if(contentCreation.video.job_status == "complete") {
      return localization.get(contentCreation.require_drm ? 'content_creation.video_drm' : 'content_creation.video_transcode')
    }
      return localization.get('content_creation.video_original')
  }else if (contentCreation.link) {
    return localization.get('content_creation.link')
  }else if (contentCreation.audio) {
    return localization.get('content_creation.audio')
  }else if (contentCreation.binary) {
    return localization.get('table.allow_binary')
  }else if (contentCreation.embededContent) {
    return localization.get('content_creation.embeded_content')
  }else {
    return localization.get('content_creation.image')
  }
}


export default FormContent