import {addPutMethodToForm, post} from "../utils";
import {makeJsonData} from "./utils";

export default host => (id, {
    name,
    visibility,
    email,
    detail,
    googleAnalyticsId,
    googleTagManagerId,
    type,
    customUrl,
    channelContentCreatorTypes,
    translations,
    catalogs,
    socialLinks,
    imageId,
    loginImageId,
    footerImageId,
    sidebarImageId,
    faviconId,
    showBanner,
    showAdvancedSearch,
    showTagsSearch,
    showSupport,
    links,
    showAbout,
    tags,
    navbarElements,
    navbarElementsToDelete,
    binaryId,
    policyId,
    bloomcloudChannel,
    loginType,
    languages,
    canDownloadTracks,
    tagGroups,
    supportNegativeSearch,
    trackingGroup,
    showLoginButton,
    preRollVideoId,
    extraRegistration,
    showTagSelection,
    loginClientId,
    loginClientSecret,
    dashboardLoginClientId,
    dashboardLoginClientSecret,
    dashboardLoginType,
    mercadoPagoClientId,
    mercadoPagoClientSecret,
    paypalClientId,
    paypalClientSecret,
    googleAdsensePublisherId,
    adUnitHomeDesktop,
    adUnitHomeMobile,
    adUnitContentDesktop,
    adUnitContentMobile,
    searchType,
    hasEvents,
    showFilterOnSmartSearch,
    notifyEndpoint,
    notifySecret,
    radioEnabled,
    radioLink,
    addresses,
    channelShipments,
    commercialMail,
    communityCatalog,
    communityCCTypes,
    communityTranslations,
    hasCommunity,
    customShare,
    useTools,
    channelRedirects,
    showDate,
    noIndex,
    customScripts,
    isDemo,
    skipTimePreRoll,
    footerType,
    footerLogoSide,
    footerTranslations,
    footerSocialPosition,
    footerMargin,
    communityRequiresApproval,
    communityPreferences,
    communityCanDeleteMaterials,
    communityEmail,
    useLicenses,
    usePlaylists,
    hasExtendRegistration,
    extendRegistration,
    useBrandButton,
    editableFor,
    purpose,
    navbarContentCreatorTypeId,
    navbarSearchType,
    mobileNavbarType,
    useSidebarDesktop,
    useFloatingContactButton,
    floatingButtonImageId,
    footerSocialWithLogo,
}) => {
  return post(`${host}/channels/${id}`,addPutMethodToForm(
    makeJsonData({
    name,
    visibility,
    email,
    detail,
    type,
    googleAnalyticsId,
    googleTagManagerId,
    customUrl,
    channelContentCreatorTypes,
    translations,
    catalogs,
    socialLinks,
    imageId,
    loginImageId,
    footerImageId,
    sidebarImageId,
    faviconId,
    showBanner,
    showAdvancedSearch,
    showTagsSearch,
    showSupport,
    links,
    showAbout,
    tags,
    navbarElements,
    navbarElementsToDelete,
    binaryId,
    policyId,
    bloomcloudChannel,
    loginType,
    languages,
    canDownloadTracks,
    tagGroups,
    supportNegativeSearch,
    trackingGroup,
    showLoginButton,
    preRollVideoId,
    extraRegistration,
    showTagSelection,
    loginClientId,
    loginClientSecret,
    dashboardLoginClientId,
    dashboardLoginClientSecret,
    dashboardLoginType,
    mercadoPagoClientId,
    mercadoPagoClientSecret,
    paypalClientId,
    paypalClientSecret,
    googleAdsensePublisherId,
    adUnitHomeDesktop,
    adUnitHomeMobile,
    adUnitContentDesktop,
    adUnitContentMobile,
    searchType,
    hasEvents,
    showFilterOnSmartSearch,
    notifyEndpoint,
    notifySecret,
    radioEnabled,
    radioLink,
    addresses,
    channelShipments,
    commercialMail,
    communityCatalog,
    communityCCTypes,
    communityTranslations,
    hasCommunity,
    customShare,
    useTools,
    channelRedirects,
    showDate,
    noIndex,
    customScripts,
    isDemo,
    skipTimePreRoll,
    footerType,
    footerLogoSide,
    footerTranslations,
    footerSocialPosition,
    footerMargin,
    communityRequiresApproval,
    communityPreferences,
    communityEmail,
    communityCanDeleteMaterials,
    useLicenses,
    usePlaylists,
    hasExtendRegistration,
    extendRegistration,
    useBrandButton,
    editableFor,
    purpose,
    navbarContentCreatorTypeId,
    navbarSearchType,
    mobileNavbarType,
    useSidebarDesktop,
    useFloatingContactButton,
    floatingButtonImageId,
    footerSocialWithLogo
    })
  ))
    .then(response => response.json())
    .then(response => response.data)
}