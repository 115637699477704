import React from "react";
import EventManager, {events} from "../../../utils/EventManager";
import {ClickAwayListener, Tooltip} from "@material-ui/core";
import HomeElementItemState from "./HomeElementItemStateX4";
import {makeImageCarousel} from "./HomeElementCarouselItem";
import {
  channelCanAddToFavorites, channelPathGenerator,  generateLink,
  getActiveChannel,
  getActiveClient, getUseTools, getStoredUser, getStoredChannel,
  homePathGenerator, slugify, translateTypes, getMainTranslation
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import ShareDialog from "../../../components/Letflow/ShareDialog";
import {Link} from "react-router-dom";
import st from "../HomeNew.module.css";
import styleX1 from "./Styles/Carousel/x1.module.css"
import styleX2 from "./Styles/Carousel/courses.module.css"
import styleX3 from "./Styles/Carousel/x3.module.css"
import styleX4 from "./Styles/Carousel/premiere.module.css"
import movies from "./Styles/Carousel/x1movies.module.css"
import moviesAlternative from "./Styles/Carousel/x1moviesRectangular.module.css"
import styleInvertedX1 from "./Styles/Carousel/x1Inverted.module.css"
import styleInvertedX2 from "./Styles/Carousel/coursesInverted.module.css"
import styleInvertedX4 from "./Styles/Carousel/premiereInverted.module.css"
import styleEditorialX1 from "./Styles/Carousel/x1Editorial.module.css"
import styleEditorialX2 from "./Styles/Carousel/coursesEditorial.module.css"
import styleEditorialX4 from "./Styles/Carousel/premiereEditorial.module.css"
import styleEditorialInvertedX1 from "./Styles/Carousel/x1EditorialInverted.module.css"
import styleEditorialInvertedX2 from "./Styles/Carousel/coursesEditorialInverted.module.css"
import styleEditorialInvertedX4 from "./Styles/Carousel/premiereEditorialInverted.module.css"
import styleNewsX4 from "./Styles/Carousel/newsX4.module.css"
import styleNewsInvertedX4 from "./Styles/Carousel/newsInvertedX4.module.css"
import styleNewsX3 from "./Styles/Carousel/newsX3.module.css"
import styleNewsInvertedX3 from "./Styles/Carousel/newsInvertedX3.module.css"
import styleFeaturedNewsX4 from "./Styles/Carousel/newsFeaturedX4.module.css"
import styleFeaturedNewsInvertedX4 from "./Styles/Carousel/newsInvertedFeaturedX4.module.css"
import styleNewsLeft from "./Styles/Carousel/newsLeft.module.css"
import styleNewsRight from "./Styles/Carousel/newsRight.module.css"
import {ReactComponent as Star} from "../../../components/Letflow/SvgIcons/star.svg"
import {ReactComponent as List} from "../../../components/Letflow/SvgIcons/list.svg"
import {ReactComponent as Heart} from "../../../components/Letflow/SvgIcons/heart.svg"
import {ReactComponent as Share} from "../../../components/Letflow/SvgIcons/share.svg"
import {ReactComponent as Person} from "../../../components/Letflow/SvgIcons/person.svg"
import {ReactComponent as Group} from "../../../components/Letflow/SvgIcons/groups.svg";
import font from "./Styles/Fonts/fonts.module.css";
import {convertFromRaw, EditorState} from "draft-js";
import {getNoteDate, makeCustomClick, makeCustomLink} from "./utils";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import {request} from "../../../api-client";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import EventDatesInfo from "../../../components/Letflow/EventDatesInfo";
import {Cancel} from "@material-ui/icons";
import analyticsInstance from "../../../analytics/analyticsInstance";
import { YouTubeEmbed } from 'react-social-media-embed';
import {get} from "lodash"
import NoImage from "../../../assets/img/Letflow/no-image.jpg";


class HomeElementFeaturedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeElement: this.props.homeElement,
      showShareDialog: false,
      playlistToShareId: null,
      limitReached: false,
      favorite: !!props.data.favorite,
      shareUrl: null,
      catchedClick: false
    };
  }

  componentDidMount = () => {
    // EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    // EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
    this.checkViewsLimit()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data.favorite !== this.props.data.favorite) {
      this.setState({favorite: this.props.data.favorite})
    }
  }

  componentWillUnmount = () => {
    // EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    // EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
  }

  checkViewsLimit = () => {
    if (this.props.data.view_limit > 0 && this.props.data.views >= this.props.data.view_limit) {
      this.setState({limitReached: true})
    }
  }

  handleFavorite = ({id, type}) => {
    if (this.props.data.id === id && this.props.data.kind === type) {
      !!getStoredUser() && this.setState({favorite: !this.state.favorite},
        () => {
          EventManager.getInstance().dispatch(this.state.favorite ? events.ADD_TO_FAVORITE_BUTTON_PRESS: events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, {id, type});

          const trackingName = getMainTranslation(this.props.data, "name")
          analyticsInstance.favorite({
            name: trackingName || getMainTranslation(this.props.data, "title"),
            type: this.props.data.kind === "content_creator" ? "Content" : this.props.data.kind === "video_content_creation" ? "Material" : this.props.data.kind,
            content: this.state.favorite ? "Add" : "Remove"
          })
      })
    }
  };

  renderShareDialog = () => {
    if (this.state.shareUrl) {
      return (<ShareOnSocials
        show={this.state.showDialog}
        handleClose={() => this.setState({showDialog: false, shareUrl: null})}
        url={this.state.shareUrl}
        darkMode={true}
      />);
    }
    return (<ShareDialog
      show={this.state.showShareDialog}
      handleClose={() => this.setState({showShareDialog: false})}
      entity={'playlist'}
      id={this.state.playlistToShareId}
    />);
  }

  sharePlaylist = (playlistId) => {
    if (getUseTools()) {
      return request.playlist.shareByChannel(playlistId)
        .then(rest => this.setState({showDialog: true, shareUrl: rest.url}))
        .catch(GlobalSnackbar.showGenericError)
    }else {
      request.playlist.share(playlistId, null, getActiveClient())
        .then(response => this.setState({
          showDialog: true, 
          shareUrl: `${window.location.protocol}//${window.location.hostname}/public/playlists/${response.share_token}`
        }))
        .catch(GlobalSnackbar.showGenericError)
    }
  }

  toggleModalFavorite = (data) => {
    let items = this.state.homeElement.items.data.map(item => {
      if (item.id === data.id) {
        item.favorite = !item.favorite;
      }
      return item;
    });

    this.setState({
      homeElement: {...this.state.homeElement, items: {data: items}}
    });
  }

  openContentCreatorModal = () => {
    EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
      id: this.props.data.kind === 'video_content_creation' ? this.props.data.content_creator_id : this.props.data.id,
      dataType: "contentCreator",
      dataName: this.props.data.kind === 'video_content_creation' ? '' : this.props.data.name,
      onTagChange: this.props.handleNewSearch || false,
      origin: this.props.home ? (this.props.homeElement ? getMainTranslation(this.props.homeElement, "title", true): "") : this.props.search ? 'Search' : this.props.realated ? 'Related' : '',
    });
  }

  openPlaylistModal = () => {
    EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
      id: this.props.data.id,
      dataType: "playlist",
      dataName: this.props.data.name || this.props.data.title,
    });
  }

  onContentCreatorClick = (e, data) => {
    if (getActiveChannel() && getStoredChannel().use_canonicals == "1") {
      return;
    }
    e.preventDefault()
    if (this.state.limitReached) {
      EventManager.getInstance().dispatch(events.OPEN_VIEWS_LIMIT_MODAL);
    } else {
      if (e.ctrlKey || (getActiveChannel() && getStoredChannel().use_canonicals == "1")) {
        window.open(getActiveChannel() ? channelPathGenerator(`${translateTypes(data.kind)}/${data.id}-${slugify(data.name || data.title)}`) : homePathGenerator(`${data.kind}/${data.id}`))
      } else {
        this.openContentCreatorModal()
      }
    }
  }

  makeFavoriteButton = (data, favorite, icon) => {
    if (!this.state.limitReached) {
      let onClick = ''
      if (favorite) {
        onClick = (e) => {
          e.preventDefault();
          e.stopPropagation()
          this.handleFavorite({id: data.id, type: this.props.data.kind})
        }
      } else {
        onClick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          // GAevent('Contenido', "Favorite", data.name || data.title)
          this.handleFavorite({id: data.id, type: this.props.data.kind})
        }
      }

      switch (icon) {
        case 'star':
          return <Star onClick={onClick}/>
        case 'heart':
          return <Heart onClick={onClick}/>
        case 'list':
          return <List onClick={onClick}/>
      }
    }
  }

  makeEditorialDescription = (data) => {
    let description = EditorState.createWithContent(convertFromRaw(JSON.parse(data.shortDescription)))
    return description.getCurrentContent().getPlainText()
  }

  getStyles = () => {
    let style = styleX4

    switch (this.props.shape) {
      case 'x1':
        if (this.props.inverted) { style = styleInvertedX1 }
        else if (this.props.editorial) { style = styleEditorialX1 }
        else if (this.props.editorialInverted) { style = styleEditorialInvertedX1 }
        else if (this.props.movie) {
          style = (this.props.homeElement.alternative||this.props.alternative) ? moviesAlternative : movies;
        }
        else if (this.props.news) {
          style = this.props.textPosition === 'right' ? styleNewsRight : styleNewsLeft;
        }
        else { style = styleX1 }
        break;
      case 'x3': 
      if (this.props.news) {
        style = (this.props.textPosition === 'inverted') ? styleNewsInvertedX3 : styleNewsX3;
      } else { style = styleX3 }
      break;
      case 'x2':
        if (this.props.inverted) {
          style = styleInvertedX2
        } else if (this.props.editorial) {
          style = styleEditorialX2
        } else if (this.props.editorialInverted) {
          style = styleEditorialInvertedX2
        }else if (this.props.news) {
          style = (this.props.textPosition === 'inverted') ? styleNewsInvertedX4 : styleNewsX4;
        } else {
          style = styleX2
        }
        break;
      case 'x4':
        if (this.props.inverted) {
          style = styleInvertedX4
        } else if (this.props.editorial) {
          style = styleEditorialX4
        } else if (this.props.editorialInverted) {
          style = styleEditorialInvertedX4
        } else if (this.props.news) {
          if (this.props.textPosition === 'inverted') {
            style = this.props.useFeaturedFormat ? styleFeaturedNewsInvertedX4 : styleNewsInvertedX4;
          } else {
            style = this.props.useFeaturedFormat ? styleFeaturedNewsX4 : styleNewsX4;
          }
        }else {
          style = styleX4
        }
        break;
    }
    return style
  }

  getBorders = () => {
    let  border = "0px"
    if (this.props.rounded) {
      switch (this.props.shape) {
        case 'x1':
          border = (this.props.movie && window.innerWidth < 650) ? "25px" : this.props.alternative ? "20px" : "75px";
          break;
        case 'x3':
        case 'x2':
          border = "75px";
          break;
        case 'x4':
          border = "50px";
          break;
      }
    }if (this.props.rounded) {
      switch (this.props.shape) {
        case 'square':
          border = "50px"
          break;
        case 'rectangle':
          border = "50px"
          break;
        case '16:9':
          border = "20px"
          break;
        case 'round':
          border = "50%"
          break;
      }
    } else if (this.props.shape === "round") {
      border = "50%"
    }

    return border
  }

  mustCatchClick = (data) => {
    if (window.innerWidth >= 650) return false;
    if (this.props.movie) return false;
    if (data && data.kind == 'content_creator') {
      // return !this.props.editorialInverted;
      return true;
    }
    return false;
  } 

  getStoredChannelContainerTitleWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_title) {
      switch(channel.preferences.container_title.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default-text";
  }

  getStoredChannelContainerDescriptionWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_description) {
      switch(channel.preferences.container_description.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default_text";
  }

  mustRemoveHoverBackground = (data) => {
    if (!this.props.inverted && !this.props.editorial && !this.props.editorialInverted && !this.props.movie && !this.props.news) {
      let trimmedTitle = data.title ? data.title.trim() : '';
      let trimmedName = data.name ? data.name.trim() : '';
      return (trimmedTitle.length == 0) && (trimmedName.length == 0);
    }
    return false;
  } 

  increseSizePx(stringPx, multiplier) {
    const valorNumerico = parseFloat(stringPx);
  
    if (!isNaN(valorNumerico)) {
      const nuevoValor = valorNumerico * multiplier;
  
      return nuevoValor + "px";
    } else {
      return stringPx;
    }
  }

  render = () => {
    const {data, handleElementClick} = this.props;
    const mustCatchClick = this.mustCatchClick(data);

    let type;

    if (data.client_type) {
      type = "speaker";
    } else if (["external", "internal"].includes(data.type)) {
      type = "artist";
    } else if (data.kind === "playlist") {
      type = "playlist";
    } else if (data.kind === "favorites") {
      type = "favorites";
    } else if (data.kind === "recents") {
      type = "recents";
    } else if (data.kind === "savedQuery") {
      type = "savedQuery";
    } else if (data.kind === "album") {
      type = "album";
    } else if (data.kind === "production") {
      type = "production";
    } else {
      type = "other";
    }

    const style = this.getStyles();
    const border = this.getBorders();

    const imgSize = this.props.shape === 'x4'  ?
      ((this.props.square || (this.props.news && !this.props.useFeaturedFormat)) ? {width: 450, height: 450, fit: false}
        : (this.props.news && this.props.useFeaturedFormat) ? {width: 450, height: 250, fit: false} : {width: 350, height: 450, fit: false}) :
      (this.props.shape === 'x2' ?
          {width:
              window.innerWidth > 1440 ? 800 :
                (window.innerWidth > 1300 ? 650 :
                  window.innerWidth > 1050 ? 570 :
                    window.innerWidth > 1000  ? 450 : 320), height: 400, fit: false} :
        (this.props.shape === 'x3' ?
          {width:
            window.innerWidth > 1300 ? 650 :
              (window.innerWidth > 1050 ? 450 : 320), height: 400, fit: false} :
        this.props.movie ?  {width: 350, height: 250, fit: false} : this.props.news ? {width: 800, height: 450, fit: false} : {width: 1500, height: 500, fit: false}
        )
      )

    const halfHeight = this.props.shape === 'x1' && (window.innerWidth > 600 ? !!this.props.halfHeight : !!this.props.mobileHalfHeight);
    const useMobileImg = this.props.shape === 'x1' && window.innerWidth <= 600;
    const useFeaturedImg = data.kind == 'content_creator' && !this.props.movie && ((this.props.news && !(this.props.shape === 'x4' && !this.props.useFeaturedFormat)) || (window.innerWidth <= 600 ? !!this.props.mobileHalfHeight : (['x1', 'x2'].includes(this.props.shape))));

    const eventDates = data.eventDates ? data.eventDates.filter(ed => ed.preferences && !(ed.preferences instanceof Array && ed.preferences.length == 0)) : null;

    if(this.props.data.data_source == 'embed') {
       return (
        <div
            className={`${(this.props.shape === 'x2' || this.props.shape === 'x4' || this.props.shape === 'x3') && this.props.hasMargin && style['has-margin']} ${style['items-content', 'embed-container']}`}
          >
            <YouTubeEmbed
              placeholderImageUrl={makeImageCarousel(data, imgSize)}
              url={get(this.props.data, "extra.link", "")}
              width={"100%"}
            />
        </div>
       );
    }

    const titleSize = ["x1"].includes(this.props.shape) ? this.increseSizePx(getStoredChannel().preferences.container_title.size, 1.5) : this.increseSizePx(getStoredChannel().preferences.container_title.size, 1.2);
    return (
      <ClickAwayListener onClickAway={() => this.setState({catchedClick: false})}>
        <Link
          rel={(data.type === 'custom' && data.data_source == 'link') ? "nofollow" : "canonical"}
          to={data.type === 'custom' ? makeCustomLink (data) : generateLink(data.kind, data.id, data.title || data.name, false)}
          className={`${(this.props.shape === 'x2' || this.props.shape === 'x4' || this.props.shape === 'x3') && this.props.hasMargin && style['has-margin']} ${style['items-content']} ${(this.props.editorial || this.props.editorialInverted || this.props.inverted) && style['inverted']}`}
          onClick={(e) => {
            if (!this.state.catchedClick && mustCatchClick) {
              this.setState({catchedClick: true})
              e.preventDefault();
              e.stopPropagation();
              return false;
            }else {
              this.setState({catchedClick: false})
            }

            if (data.type === 'custom') {
              makeCustomClick(e, data)
            }else if (!['artist', 'production', 'content_creator', 'playlist'].includes(data.kind)) {
              e.preventDefault()
              handleElementClick()
            } else if (data.kind === 'content_creator') {
              if (this.state.limitReached) {
                e.preventDefault();
                EventManager.getInstance().dispatch(events.OPEN_VIEWS_LIMIT_MODAL);
              } else if (!getActiveChannel() || getStoredChannel().use_canonicals != "1"){
                e.preventDefault();
                this.openContentCreatorModal();
              }
            } else if (data.kind === 'playlist') {
              e.preventDefault();
              this.openPlaylistModal();
            }
          }}
          style={this.mustRemoveHoverBackground(data) ? 
            (halfHeight ? {maxHeight: 200, borderRadius: border, "--card-hover": "transparent"} : {borderRadius: border, "--card-hover": "transparent"})
            :
            (halfHeight ? {maxHeight: 200, borderRadius: border} : {borderRadius: border})
          }
        >
          {this.props.onRemoveFavorites &&
            <Cancel
              style={{
                position: "absolute",
                fontSize: "30px",
                top: 0,
                right: 0,
                zIndex: 10000,
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%"
              }}
              onClick={e => this.props.onRemoveFavorites(e, data.id)}
            />
          }
          {this.state.limitReached &&
          <Tooltip placement={"top"} title={localization.get('content.views_limit_reached')}>
            <Group className={`${style['limit-reached']} `}/>
          </Tooltip>
          }
          <div className={style['photo-container']} style={this.props.shape === 'x4' && this.props.square ? {padding: "50% 0px"} : {}}>
            <div
              className={style['photo']}
              style={{backgroundImage: `url(${makeImageCarousel(data, imgSize, useMobileImg, useFeaturedImg)})`}}
            >
              {(((type === "playlist" && (!data.created_by_admin || !data.image)) || type === "savedQuery" || type === "production")) ||
                ((this.props.news && this.props.showAuthor && this.props.shape !== 'x1' && data.additions !== ";") && !!(data.additions && data.additions.length > 0 && data.additions.split(';')[0] && data.additions.split(';')[0].length > 0)) &&
                <div
                  className={`featured-image-title ${style['featured-image-title']} ${style[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={getActiveChannel() ? {
                    fontSize: window.innerWidth > 650 ? titleSize : "1.15rem",
                    color: getStoredChannel().preferences.container_title.color,
                    fontFamily: getStoredChannel().preferences.text_font
                  } : {
                    color: data.featuredImage
                      ? data.featuredImage.color
                      : type === 'production' ? "rgb(128, 144, 180)" : "rgb(252, 205, 47)"
                  }}
                >
                  {(this.props.news && this.props.showAuthor) && ((data.additions !== ";" && !!data.additions.split(';')[0]) ? data.additions.split(';')[0] : data.title.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))}
                </div>
              }
            </div>
            <HomeElementItemState
              recentlyCreated={data.recentlyCreated}
              recentlyUpdated={data.recentlyUpdated}
              fullOpacity={this.state.over}
            />
          </div>

          {(this.props.shape === 'x4' || (this.props.news && this.props.shape !== 'x1')) && 
            <>
            <div
             style={{
              bottom: !this.props.numbered && !this.props.news && 15,
              fontSize: titleSize, 
              backgroundColor: (this.props.news && getActiveChannel()) ? getStoredChannel().preferences.element_background : 'transparent',
              padding: this.props.news && 5
            }}
                 className={`${style["premiere-title-start"]} ${style[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
            >
              {!this.props.news && !this.props.editorial && !this.props.editorialInverted && data.additions && data.additions !== ";" && !!data.additions.split(';')[0] && 
                <div
                  className={`${(this.props.editorial || this.props.editorialInverted || this.props.news|| this.props.numbered) ? style["author"] : style["section"]} ${st[this.getStoredChannelContainerDescriptionWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={getActiveChannel() ? {
                    fontSize: window.innerWidth > 600 ? Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 1.05) : 12,
                  } : {}}
                >
                  {data.additions.split(';')[0]}
                </div>
              }
              {data.title || data.name}
            </div>
            </>
          }
          {(['album', 'content_creator', 'artist', 'video_content_creation', 'playlist', 'custom'].includes(data.kind) || data.client_type) && (!this.props.news || this.props.shape === 'x1') &&
            <div
              className={style['info']}
              style={this.props.news ? {backgroundColor: getActiveChannel() ? getStoredChannel().preferences.element_background : "transparent"} : {}}
              onClick={(e) => {
                if (!this.state.catchedClick && mustCatchClick) {
                  this.setState({catchedClick: true})
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }else {
                  this.setState({catchedClick: false})
                }
                
                if (data.type === 'custom') {
                  makeCustomClick(e, data)
                }else if (!['artist', 'production', 'content_creator', 'playlist'].includes(data.kind)) {
                  e.preventDefault()
                  handleElementClick()
                } else if (data.kind === 'content_creator') {
                  this.onContentCreatorClick(e, data)
                }else if (data.kind === 'playlist') {
                  e.preventDefault();
                  this.openPlaylistModal();
                }
              }}
              >
              {this.props.movie &&
                <div className={`${style["data"]}`}>
                  <div
                    className={`${style["title"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                    style={{fontWeight: this.props.useRegular ? "normal" : "bold"}}                    
                  >
                    {data.title || data.name}
                  </div>
                  {data.additions && data.additions !== ";" && !!data.additions.split(';')[1] &&
                    <div
                      className={`${style["section"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                    >
                      {data.additions.split(';')[1]}
                    </div>
                  }
                  {data.additions && data.additions !== ";" && !!data.additions.split(';')[0] &&
                    <div
                      className={`${style["author"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                    >
                      {data.additions.split(';')[0]}
                    </div>
                  }
                </div>
              }
              {!this.props.movie && (this.props.editorial || this.props.inverted || this.props.editorialInverted || this.props.numbered) && data.additions && data.additions.split(';')[1] &&
              <div
                className={`${style["section"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
              >
                {data.additions.split(';')[1]}
              </div>
              }
              {!this.props.movie && (this.props.shape === 'x1' && this.props.news) && (data.additions && data.additions.split(';')[1]) &&
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div
                    className={`${style["section"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  >
                    {data.additions.split(';')[1]}
                  </div>
                  {this.props.news && !!data.activity_start && !!data.show_date_on_home && 
                    <div
                      className={`${style["date"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']} ${st[this.getStoredChannelContainerDescriptionWeight()]}`}
                    >
                      {getNoteDate(data.activity_start)}
                    </div>
                  }
                </div>
              }
              {!this.props.numbered && !this.props.movie && !this.props.editorial && !this.props.editorialInverted && !this.props.inverted && this.props.shape === 'x4' && data.additions && data.additions !== ";" && !!data.additions.split(';')[0] &&
              <div
                className={`${style["author"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']} ${st[this.getStoredChannelContainerDescriptionWeight()]}`}
                style={getActiveChannel() ? {
                  fontSize: window.innerWidth > 600 ? Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 1.05) : 12,
                } : {}}
              >
                {data.additions.split(';')[0]}
              </div>
              }
              {!this.props.movie &&
                <div
                  className={`${style["title"]} ${st[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={getActiveChannel() ? {
                    fontWeight: this.props.useRegular ? "normal" : "bold",
                    fontSize: Math.round(parseInt(getStoredChannel().preferences.container_title.size) * ((window.innerWidth > 800 && ["x2", "x1"].includes(this.props.shape)) ? 1.6 : 1.2 )),
                  } : {}}
                >
                  {data.title || data.name}
                </div>
              }
              {!this.props.movie && !this.props.editorial && !this.props.editorialInverted && !this.props.inverted && this.props.shape === 'x4' && data.additions && data.additions !== ";" && data.additions.split(';')[1] &&
              <div
                className={`${style[ this.props.numbered ? "author" : "section"] } ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
              >
                {data.additions.split(';')[this.props.numbered ? 0 : 1]}
              </div>
              }
              {!this.props.movie && this.props.shape !== "x4" && (!this.props.editorial && !this.props.inverted && !(this.props.shape === 'x1' && this.props.news)) && (data.additions && data.additions !== ";" && data.additions.split(';')[0]) &&
                <div
                  className={`${style["author"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={getActiveChannel() ? {
                    fontSize: Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 1.05),
                  } : {}}
                >
                  {data.additions.split(';')[0]}
                </div>
              }
              {!this.props.inverted && 
              <div
                className={`${style["text"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']} ${st[this.getStoredChannelContainerDescriptionWeight()]}`}
                style={getActiveChannel() ? {
                  fontWeight: this.props.useRegular ? "normal" : "bold",
                  fontSize: Math.round(parseInt(getStoredChannel().preferences.container_description.size)),
                } : {}}
              >
                {(data.artist || data.subtitle) && <p>{data.subtitle || localization.get('compiled')}</p>}
                {(!!data.shortDescription || data.synopsis) &&
                  <div style={{whiteSpace: "pre-line"}}
                       dangerouslySetInnerHTML={{ __html:
                           (data.shortDescription || data.synopsis) ?
                             ((data.kind === "content_creator" || data.synopsis) ? (data.synopsis || "") :
                               data.shortDescription && data.shortDescription.replace(/<[^>]*>|&nbsp;/g, ' ')) : ""
                  }}
                  />
                }
              </div>
              }

              {!this.props.movie && this.props.shape === "x1" && this.props.news && (data.additions && data.additions !== ";" && data.additions.split(';')[0]) &&
                <div
                  className={`${style["author"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={getActiveChannel() ? {
                    fontSize: Math.round(parseInt(getStoredChannel().preferences.container_description.size) * (window.innerWidth > 800 ? 1.2 : 1)),
                  } : {}}
                >
                  {data.additions.split(';')[0]}
                </div>
              }

              {(this.props.editorial || this.props.inverted) && (data.additions && data.additions !== ";" || data.show_date_on_home && !!data.date) &&
                <div
                  className={`${(this.props.editorial || this.props.editorialInverted) ? style["author"] : style["section"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']} ${st[this.getStoredChannelContainerDescriptionWeight()]}`}
                  style={getActiveChannel() ? {
                    fontSize: window.innerWidth > 600 ? Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 1.05) : 12,
                  } : {}}
                >
                  {data.show_date_on_home && data.date ? this.getReleaseDate(data.date) : data.additions.split(';')[0]}
                </div>
              }
              
              {this.props.movie && eventDates && eventDates.length > 0 &&
                <div className={`${style["event-dates"]}`}>
                  <EventDatesInfo eventDates={eventDates} open={this.state.catchedClick}/>
                </div>
              }
            </div>
          }
         
          {(this.props.editorial || this.props.editorialInverted) && <div className={`${style["editorial"]}`}/>}
          {this.props.numbered && <div className={style["line"]}></div>}
          {this.props.numbered && <div
            className={`${style["number"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}>{("0" + parseInt(this.props.number + 1)).slice(-2)}</div>}

          {!this.props.onRemoveFavorites && ['album', 'speaker', 'content_creator', 'artist', 'playlist', `${getActiveChannel() ? "" : 'video_content_creation'}`].includes(this.props.data.kind) &&
            data.type !== 'custom' &&
            <div className={style["icons"]} style={((this.props.news && this.props.shape !== 'x1')) ? {fontSize: getActiveChannel() && Math.round(parseInt(getStoredChannel().preferences.container_title.size) * 1.2)} : {}}>
               {!this.props.movie && eventDates && eventDates.length > 0 &&
                  <div className={`${style["event-dates"]}`} >
                    <EventDatesInfo eventDates={eventDates} chipColor={"white"} chipFontColor={"black"} open={this.state.catchedClick}/>
                  </div>
                }
              {(!getActiveChannel() || getUseTools()) || (getActiveChannel() && channelCanAddToFavorites()) ?
                <>
                  <div className={`${style['star']} ${this.state.favorite && style['active']}`}>
                    {this.makeFavoriteButton(data, this.state.favorite,!getActiveChannel() ? 'star' : getStoredChannel().preferences.fav_icon)}
                  </div>
                  {(!getActiveChannel() || getUseTools()) &&
                  <>
                    {data.kind === "content_creator" &&
                      <AddToPlaylist
                        clientId={getActiveClient()}
                        content='contentCreators'
                        item={data}
                        contentCreatorType={data.type.slug}
                        extraClass={style['list']}
                        tooltipTitle={localization.get("add_to_playlist")}
                      />
                    }
                    {data.kind === "video_content_creation" &&
                      <>
                        {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                          <AddToPlaylist 
                            clientId={getActiveClient()}
                            content='contentCreations'
                            item={data}
                            extraClass={style['list']}
                            tooltipTitle={localization.get("ranking_config.playlist_add")}
                          />
                        }
                        <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                          <div className={style['list']}>
                            <Person onClick={(e) => {
                              e.stopPropagation();
                              this.openContentCreatorModal()
                            }}/>
                          </div>
                        </Tooltip>
                      </>
                    }
                  </>
                  }

                  {data.kind === "playlist" &&
                  <Tooltip placement="bottom" title={localization.get("share")}>
                    <div className={style['list']}>
                      <Share onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.sharePlaylist(data.id);
                      }}/>
                    </div>
                  </Tooltip>
                  }

                </>
                :
                <>

                  {data.kind === "video_content_creation" &&
                  <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                    <div className={style['list']}>
                      <Person onClick={(e) => {
                        e.stopPropagation();
                        this.openContentCreatorModal()
                      }}/>
                    </div>
                  </Tooltip>
                  }

                </>
              }
            </div>
          }
        </Link>
        {this.renderShareDialog()}
      </ClickAwayListener>
    );
  };
}

export default HomeElementFeaturedItem;
