import React, {Component} from "react";
import { EditorState,  DefaultDraftBlockRenderMap } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {request} from "../../../api-client";
import Immutable from 'immutable'
import styled from "styled-components";

export default class RichEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {editorState: EditorState.createEmpty()};
    this.onChange = (editorState) => this.props.onChange(editorState)
    this.setEditor = (editor) => {
      this.editor = editor;
    };

    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
  }

  getBlockRenderMap = (prop) => {
    return Immutable.Map({
      'EVENT_CALENDAR': {
        element: 'section',
        wrapper: (this.props.eventScheduleGen && this.props.eventScheduleGen('center')) || <div></div>
      },
      'EVENT_CALENDAR_LEFT': {
        element: 'section',
        wrapper: (this.props.eventScheduleGen && this.props.eventScheduleGen('left')) || <div></div>
      },
      'EVENT_CALENDAR_RIGHT': {
        element: 'section',
        wrapper: (this.props.eventScheduleGen && this.props.eventScheduleGen('right')) || <div></div>
      },
    })
  }

  uploadImage = async (data) => {
    let res = await request.file.image.upload(data, true)

    return new Promise(resolve => {
 
      const url = process.env.REACT_APP_CDN_HOST + `/files/images/${res.id}/fit/1024x1024/${res.original_name}`
      console.log(url, res)
      resolve({ data: { link: url}})
    })
  }

  render() {
    const { editorState, toolbarOptions, bgColor=null, readOnly } = this.props
    const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(this.getBlockRenderMap());
    const props = {
      ...(!readOnly && { blockRendererFn: myBlockRenderer }),
    };

    return (
      <div style={this.props.customStyles ? this.props.customStyles: this.props.bloomCloudsView ? {margin: '6px 0', minHeight: '250px'} : {margin: '2rem 0'}} onClick={this.focusEditor}>
        <EditorContainer bgColor={bgColor}>
          <div class={this.props.noColors ? "RichEditor-no-colors" : ""}>
            {/*Component docs https://jpuri.github.io/react-draft-wysiwyg/#/docs*/}
            <Editor
              {...props}
              readOnly={readOnly || false}
              toolbarHidden={readOnly || false}
              editorRef={this.setEditor}
              editorState={editorState}
              onEditorStateChange={this.onChange}
              wrapperClassName="wrapper-class"
              editorClassName={this.props.scrollable ? "scrollable-editor" : "editor-class"}
              toolbarClassName="toolbar-class"
              // stripPastedStyles={true}
              handlePastedText={() => false}
              blockRenderMap={extendedBlockRenderMap}
              toolbar={{
                options: toolbarOptions || ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                image: {
                  uploadEnabled: true,
                  uploadCallback: this.uploadImage,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                },
                link: {
                  defaultTargetOption: '_blank',
                },
                inline: toolbarOptions && {
                  options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                },
                colorPicker: {
                  colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                    'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                    'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                    'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                    'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                    'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                },
              }}
              {...(readOnly && { blockRenderMap: extendedBlockRenderMap })}
            />
          </div>
        </EditorContainer>
      </div>
    );
  }
}


function myBlockRenderer(contentBlock) {
  const type = contentBlock.getType();

  // Convert image type to mediaComponent
  if (type === 'atomic') {
    return {
      component: MediaComponent,
      editable: false,
      props: {
        foo: 'bar',
      },
    };
  }
}

class MediaComponent extends React.Component {
  render() {
    const { block, contentState } = this.props;
    const { foo } = this.props.blockProps;
    const entity = contentState.getEntity(block.getEntityAt(0));
    const data = entity.getData();
    const type = entity.getType();
    let media;

    
    const emptyHtml = ' ';


    if (type === 'IMAGE') {
      media = (
        <img
          src={data.src}
          alt={data.alt || '  '}
          style={{ height: data.height || 'auto', width: data.width || 'auto' }}
        />
      );
    } else if (type === 'VIDEO') {
      media = (
        <video
          controls
          src={data.src}
          style={{ height: data.height || 'auto', width: data.width || 'auto' }}
        />
      );
    } else if (type === 'EMBEDDED_LINK') {
      media = (
        <iframe
          src={data.src}
          height={data.height || 'auto'}
          width={data.width || 'auto'}
         />
      );
    }

    return (
      <div>
        {emptyHtml}
        {media}
      </div>
    );
  }
}

const EditorContainer = styled.div`
  background-color: ${props => props.bgColor || 'transparent'}!important;
`;